import {Box, SwipeableDrawer} from "@mui/material";
import {FC} from "react";
import {styled} from "@mui/material/styles";
import {grey} from "@mui/material/colors";
import { Global } from '@emotion/react';

interface IProps {
    children: any;
    open: boolean;
    onClose: () => void;
}

const drawerBleeding = 30;

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

export const SheetsSide: FC<IProps> = ({open, onClose, children}) => (
    <SwipeableDrawer
        anchor={window.innerWidth < 600 ? 'bottom' : 'right'}
        open={open}
        onClose={onClose}
        onOpen={() => {}}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
    >
        <Global
            styles={{
                '@media (min-width: 0px)' : {
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(100% - ${drawerBleeding + 60}px)`,
                        overflow: 'visible',
                    },
                },
                '@media (min-width: 600px)' : {
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `100%`,
                        overflow: 'visible',
                    },
                },
            }}
        />

        <StyledBox
            sx={{
                position: 'absolute',
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                right: 0,
                left: 0,
                height: drawerBleeding,
                display: {xs: 'block', sm: 'none'}
            }}
        >
            <Puller />
        </StyledBox>

        <Box sx={{
            width: {
                xs: 1,
                sm: 600,
            },
            minWidth: 300,
            padding: 2,
            height: '100%',
            overflow: 'auto',
        }}>
            {open && children}
        </Box>
    </SwipeableDrawer>
)