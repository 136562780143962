import React, {FC, useLayoutEffect} from "react";
import CustomSelectField from "../../components/CustomSelectField";
import {Company} from "../../redux/service/serviceTypes";

interface IProps {
    selectCompanyId: number;
    disabled?: boolean;
    companies: Company[];
    onSelect: (companyId: number) => void
}
const ReportCompanySelect: FC<IProps> = ({selectCompanyId, disabled, companies, onSelect}) => {

    // Добавление только НЕ транзитных компаний (тип "Компания ЦН, начисляемая")
    const reportCompanies = companies.filter(company => company.type == 'CN');

    useLayoutEffect(() => {
        if (reportCompanies.length > 0) {
            onSelect(reportCompanies[0].id)
        }
    }, [])

    return (
        <CustomSelectField
            label={'Компания'}
            value={selectCompanyId}
            items={reportCompanies.map(((company) => ({
                    value: company.id, description: company.name}
            )))}
            disabled={disabled}
            onChange={onSelect} />
    )
}

export default ReportCompanySelect;