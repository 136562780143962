import React, {FC, useEffect, useState} from "react";
import {CustomTextField} from "../../components/CustomTextField";
import {useGetUserProfileEmailQuery} from "../../core/api";

interface IProps {
    label?: string;
    onChange: (email: string) => void
}

export const UserEmailField: FC<IProps> = ({label, onChange}) => {

    const [email, setEmail] = useState<string>('');

    const { data, isLoading, isSuccess } = useGetUserProfileEmailQuery()

    useEffect(() => {
        if (isSuccess) {
            onChangeHandler(data || '')
        }
    }, [isSuccess])

    const onChangeHandler = (value: string) => {
        setEmail(value);
        onChange(value);
    }

    return (
        <CustomTextField
            label={label || 'E-Mail'}
            value={email}
            disabled={isLoading}
            onChange={onChangeHandler} />
    )
}