import React, {useState} from "react";
import {Alert, Card, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {formatRUB} from "../../core/utils";
import {useGetReceiptListQuery} from "../../core/api";
import {LoadingPanel} from "../LoadingPanel";
import {SheetsSide} from "../../components/SheetsSide";
import {PaymentData} from "./PaymentData";
import {Receipt} from "../../redux/receipt/receiptTypes";

export const PaymentsPage = () => {
    const { data, error, isLoading } = useGetReceiptListQuery([])
    const [open, setOpen] = useState(false);
    const [receipt, setReceipt] = useState<Receipt | undefined>();
    const closeSheetsSide = () => setOpen(!open);

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    const openReceipt = (receipt: Receipt) => {
        setReceipt(receipt);
        setOpen(true);
    }

    return (
        <Card>
            <Table size="small" aria-label="payment list">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: 700}}>Дата оплаты</TableCell>
                        <TableCell style={{fontWeight: 700}} align="right">Сумма</TableCell>
                        <TableCell style={{fontWeight: 700}} sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Агент</TableCell>
                        <TableCell align="right" width={40}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((receipt, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {receipt.date}
                            </TableCell>
                            <TableCell align="right">
                                {formatRUB(receipt.total)}
                            </TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                {receipt.agent}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={openReceipt.bind(null, receipt)} style={{color: "-webkit-link"}} aria-label="Показать детали платежа">
                                    <ReceiptIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <SheetsSide open={open} onClose={closeSheetsSide}>
                <PaymentData receipt={receipt} />
            </SheetsSide>
        </Card>
    )
}