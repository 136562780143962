import React, {useEffect} from "react";
import styles from './StatusPanel.module.scss'
import PropTypes from "prop-types";
import axios from "../../../client";
import {Alert, FormControl, IconButton, InputLabel, MenuItem, Paper, Select} from "@mui/material";
import {Save} from "@mui/icons-material";

const StatusPanel = (props) => {

    const [status, setStatus] = React.useState(-1);
    const [isSend, setIsSend] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    useEffect(() => {
        if (status === -1 && props.data.length) {
            setStatus(props.data[props.data.length - 1].status);
        }
    });

    const handleChange = event => setStatus(event.target.value);

    const handlerSaveButton = () => {
        setIsSend(true);

        axios.post('/api/admin/message/chat/status', {idLkUser: props.id, status: status, maxId: getMaxId()})
            .then((data) => {
                setIsSend(false);
                setError('');
                setSuccess(true);
                setTimeout(() => setSuccess(false), 3000);
            })
            .catch((error) => {
                setIsSend(false);
                setError(error.message);
                hideError();
            });
    };

    const getMaxId = () => {
        let maxId = 0;

        // Получение максимального ID сообщения
        props.data.forEach(message => maxId = Math.max(maxId, message.id));

        return maxId;
    };

    const hideError = () => setTimeout(() => setError(''), 3000);

    return (
        <div className={styles.root}>
            {error && <Alert severity="error" style={{marginBottom: "10px"}} >{error}</Alert>}
            {success && <Alert severity="success" style={{marginBottom: "10px"}} >Статус успешно сохранён</Alert>}

            <Paper className={styles.content}>
                <FormControl variant="outlined" className={styles.formControl}>
                    <InputLabel id="status-label">Статус</InputLabel>
                    <Select
                        labelId="status-label"
                        id="status-select-outlined"
                        value={status}
                        onChange={handleChange}
                        label="Статус"
                        disabled={isSend}
                    >
                        <MenuItem value={0}>Новое</MenuItem>
                        <MenuItem value={1}>В работе</MenuItem>
                        <MenuItem value={2}>Обработано</MenuItem>
                    </Select>
                </FormControl>
                <IconButton color="primary"
                            className={styles.iconButton}
                            aria-label="send message"
                            onClick={handlerSaveButton}
                            disabled={isSend}
                >
                    <Save />
                </IconButton>
            </Paper>
        </div>
    )
};

StatusPanel.propTypes = {
    id: PropTypes.any.isRequired,
    data: PropTypes.array.isRequired
};

export default StatusPanel;