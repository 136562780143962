import React, {FC} from "react";
import {Switch} from "@mui/material";
import {useAppDispatch} from "../../core/hooks";
import {UserCard} from "../../redux/service/serviceTypes";
import {toggleCards} from "../../redux/service/serviceSlice";

interface IProps {
    card: UserCard;
}

export const CardSwitch: FC<IProps> = ({card}) => {
    const dispatch = useAppDispatch();

    const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(toggleCards({
            reduxIndex: card.reduxIndex,
            value: event.target.checked
        }));
    }

    return (
        <Switch onChange={switchHandler} checked={card.select} />
    )
}