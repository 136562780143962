import {Alert, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Stack, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import {useUpdateReportEmailMutation} from "../../core/api";

import {Company} from "../../redux/service/serviceTypes";
import ReportCompanySelect from "./ReportCompanySelect";
import {isBlank} from "../../core/utils";
import {ReportEmailGroup} from "./ReportEmailGroup";
import ReportAccountSelect from "./ReportAccountSelect";

interface IProps {
    idGid: number;
    idSchema: number;
    companies: Company[];
    onSuccess: () => void;
}

export const ReportConnectSendingByEmailView: FC<IProps> = ({idGid, idSchema, companies, onSuccess}) => {

    const [updateReportEmailMutation, {error: updateReportEmailError}] = useUpdateReportEmailMutation();

    const [success, setSuccess] = useState(false);
    const [companyId, setCompanyId] = useState<number>(-1);
    const [accountId, setAccountId] = useState<number>(-1);
    const [email, setEmail] = useState<string>('');
    const [activated, setActivated] = useState<number>(0);
    const [responseStatus, setResponseStatus] = useState<number>(0);
    const [sending, setSending] = useState<boolean>(true);
    const [closeReceiptPrinting, setCloseReceiptPrinting] = useState<boolean>(true);

    const [loadSetting, setLoadSetting] = useState<boolean>(true);

    const updateReportEmail = async () => {
        const result = await updateReportEmailMutation({
            idSchema: idSchema,
            idCompany: companyId,
            idAccount: accountId,
            email: email,
            activated: activated == 1,
            sending: !sending,
            closeReceiptPrinting: closeReceiptPrinting
        });

        // @ts-ignore
        if (!result.error) {
            // @ts-ignore
            setActivated(result.data.activated)
            // @ts-ignore
            setSending(result.data.sending)
            // @ts-ignore
            setCloseReceiptPrinting(result.data.closeReceiptPrinting)
            // @ts-ignore
            setResponseStatus(result.data.status)

            setSuccess(true);
            setTimeout(onSuccess, 10000);
        }
    }

    const findCompanyAccounts = (companies: Company[]) => {
        return companies.find(company => company.id === companyId)?.accounts || [];
    }

    const handleChangeCloseReceiptPrinting = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCloseReceiptPrinting(event.target.checked);
    };

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Подключить электронную квитанцию
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <ReportCompanySelect
                    selectCompanyId={companyId}
                    onSelect={setCompanyId}
                    disabled={sending}
                    companies={companies} />

                <ReportAccountSelect
                    selectAccountId={accountId}
                    onSelect={setAccountId}
                    disabled={sending}
                    accounts={findCompanyAccounts(companies)} />

                <ReportEmailGroup idSchema={idSchema}
                                  idCompany={companyId}
                                  idAccount={accountId}
                                  onChangeEmail={setEmail}
                                  onChangeActivated={setActivated}
                                  onChangeSending={setSending}
                                  onChangeLoadSetting={setLoadSetting}
                                  onChangeCloseReceiptPrinting={setCloseReceiptPrinting} />

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox checked={closeReceiptPrinting} onChange={handleChangeCloseReceiptPrinting} />
                        }
                        disabled={loadSetting}
                        label="Бумажную квитанцию не доставлять" />
                </FormGroup>

                {updateReportEmailError ? (
                    <Alert severity="error">
                        {updateReportEmailError.message ? updateReportEmailError.message : 'Ошибка подключения электронной квитанции'}
                    </Alert>
                ) : null}

                {success && responseStatus == 1 && (
                    <Alert severity="info">
                        На указанный адрес было отправлено письмо для подтверждения
                    </Alert>
                )}
                {success && responseStatus == 2 && (
                    <Alert severity="info">
                        Настройки успешно сохранены
                    </Alert>
                )}
                {success && responseStatus == 3 && (
                    <Alert severity="info">
                        Электронная квитанция успешно отключена
                    </Alert>
                )}

                {
                    !success && (
                        <Button variant="contained"
                                color="success"
                                onClick={updateReportEmail}
                                disabled={(isBlank(email) && !sending) || companyId === -1 || accountId === -1 || loadSetting}
                        >
                            { loadSetting
                                ? (<>Загрузка...</>)
                                : (sending
                                    ? (<>Отключить</>)
                                    : (<>Подключить</>)
                                )
                            }
                        </Button>
                    )
                }
            </Stack>
        </Box>
    )
}