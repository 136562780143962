import React, {useState} from "react";
import {useGetLkCardDataQuery, useRegisterPaymentMutation} from "../../core/api";
import {LoadingPanel} from "../LoadingPanel";
import {Alert, Box} from "@mui/material";
import {useParams} from "react-router-dom";
import {CardDetailsService} from "./details/CardDetailsService";
import {getCommission} from "../../core/utils";
import {useAppSelector} from "../../core/hooks";
import {selectServices} from "../../redux/service/serviceSlice";
import {CartItem, CartRequest, Service} from "../../redux/service/serviceTypes";
import {PayButton} from "./PayButton";
import {SheetsSide} from "../../components/SheetsSide";
import {RegisterPaymentView} from "./RegisterPaymentView";

const MODE_TOTAL = 0;
const MODE_FULL_TOTAL = 1;
const MODE_CUSTOM = 2;

export const CardDetailsPage = () => {
    const params = useParams();
    // @ts-ignore
    const id = params.id;
    const idx = id === 'all' ? [] : [id];

    const [selectPaymentMode, setSelectPaymentMode] = useState(0)
    const [showRegisterPaymentView, setShowRegisterPaymentView] = useState(false);
    const [registerPaymentMutation, {error: registerPaymentError}] = useRegisterPaymentMutation();

    const openRegisterPaymentView = (mode: number) => {
        setSelectPaymentMode(mode);
        setShowRegisterPaymentView(true);
    }

    const closeRegisterPaymentView = () => setShowRegisterPaymentView(false);

    const { error, isLoading } = useGetLkCardDataQuery(idx);
    const data = useAppSelector(selectServices);

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    const calcAmount = (mode: number) => {
        let paidWithCommission = 0;

        data?.gidDataList.forEach(gidData => {
            gidData.companies?.forEach(company => {
                company.accounts.forEach(account => {
                    account.services?.forEach(service => {
                        if (service.select) {
                            let serviceAmount = getServiceAmountByMode(service, mode);

                            if (serviceAmount > 0.00) {
                                paidWithCommission += serviceAmount;
                                paidWithCommission += getCommission(serviceAmount, service.commissionPercent);
                            }
                        }
                    });
                })
            });
        });

        return paidWithCommission;
    }

    const createCart = (mode: number, email: string) => {
        let items: CartItem[] = [];

        data?.gidDataList.forEach(gidData => {
            gidData.companies?.forEach(company => {
                company.accounts.forEach(account => {
                    account.services?.forEach(service => {
                        if (service.select) {
                            let serviceAmount = getServiceAmountByMode(service, mode);

                            if (serviceAmount > 0.00) {
                                items.push({
                                    type: gidData.type,
                                    idSchema: gidData.idSchema,
                                    idGid: gidData.idGid,
                                    idCompany: company.id,
                                    idAccount: account.id,
                                    codeAccount: account.code,
                                    idService: service.id,
                                    idSupplier: service.idSupplier,
                                    name: service.name,
                                    amount: serviceAmount,
                                    commission: getCommission(serviceAmount, service.commissionPercent),
                                    supplierName: service.supplierName,
                                    supplierInn: service.supplierInn
                                });
                            }
                        }
                    });
                })
            });
        });

        return {
            amount: calcAmount(mode),
            items: items,
            email: email
        } as CartRequest;
    }

    const getServiceAmountByMode = (service: Service, mode: number) => {
        let serviceAmount = 0;

        if (mode === MODE_CUSTOM) {
            serviceAmount = service.paid;
        } else if (mode === MODE_TOTAL) {
            serviceAmount = service.total;
        } else if (mode === MODE_FULL_TOTAL) {
            serviceAmount = service.fullTotal;
        }

        return serviceAmount;
    }

    const registerPayment = async (email: string) => {
        const result = await registerPaymentMutation({
            paySystem: 'SberWeb',
            cart: createCart(selectPaymentMode, email)
        })

        // @ts-ignore
        if (result.error) {
            console.log('Ошибка регистрации заказа');
        } else {
            // @ts-ignore
            window.location.assign(result.data.url);
        }
    }

    const amountTotal = calcAmount(MODE_TOTAL)
    const amountFullTotal = calcAmount(MODE_FULL_TOTAL)
    const amountCustom = calcAmount(MODE_CUSTOM)

    const showTotalButtons = amountTotal != amountFullTotal
    const showCustomButton = amountCustom != amountFullTotal

    return (
        <Box>
            {data?.gidDataList.map((data, index) =>
                <CardDetailsService key={index} data={data} />
            )}

            {registerPaymentError && (
                <Alert severity="error" style={{marginBottom: "10px"}}>
                    Ошибка регистрации заказа
                </Alert>
            )}

            <Alert severity="info" style={{marginBottom: "10px"}}>
                <span style={{color: 'red'}}>*</span> Сумма платежа за прошлый расчетный период.
            </Alert>
            <Alert severity="info" style={{marginBottom: "10px"}}>
                <span style={{color: 'red'}}>**</span> Сумма платежа за текущий расчетный период, рассчитанная по среднемесячному или нормативному потреблению (в зависимости от наличия прибора учёта и переданных актуальных показаний). Данная сумма не обязательна к оплате в текущем расчетном периоде.
            </Alert>
            <Alert severity="info" style={{marginBottom: "10px"}}>
                <span style={{color: 'red'}}>***</span> Итоговая сумма платежа, являющаяся рекомендованной к оплате. При необходимости из неё можно исключить начисление за текущий расчетный период, оставив только задолженность за прошлый расчетный период.
            </Alert>


            {showCustomButton && (
                // Отображение дополнительной кнопки, если пользователь корректировал суммы для оплаты
                <PayButton amount={amountCustom}
                           onClick={openRegisterPaymentView.bind(null, MODE_CUSTOM)}
                           text='указанная сумма' />
            )}

            {showTotalButtons ? (
                // Отображение раздельных кнопок, если есть долг и начисление
                <>
                    <PayButton amount={amountFullTotal}
                               onClick={openRegisterPaymentView.bind(null, MODE_FULL_TOTAL)}
                               text='долг + начисление' />
                    <PayButton amount={amountTotal}
                               onClick={openRegisterPaymentView.bind(null, MODE_TOTAL)}
                               text='только долг' />
                </>
            ) : (
                // Отображение дополнительного текста на кнопке, только в том случае, если пользователь корректировал суммы для оплаты
                <PayButton amount={amountFullTotal}
                           onClick={openRegisterPaymentView.bind(null, MODE_FULL_TOTAL)}
                           text={showCustomButton ? 'полная сумма' : undefined} />
            )}

            <SheetsSide open={showRegisterPaymentView} onClose={closeRegisterPaymentView}>
                <RegisterPaymentView
                    amount={
                        selectPaymentMode === MODE_FULL_TOTAL
                            ? amountFullTotal
                            : selectPaymentMode === MODE_TOTAL
                                ? amountTotal : amountCustom
                    }
                    onRegister={registerPayment} />
            </SheetsSide>
        </Box>
    )
}