import React from "react";
import {Typography} from "@mui/material";

export const InfoContactPage = () => (
    <>
        <Typography variant="h6" marginTop={2}>
            Контакты
        </Typography>
        <Typography variant="body2">
            E-Mail: support@odnookno.info
        </Typography>
        <Typography variant="body2">
            Skype: odnookno.info
        </Typography>

        {/* Блокировка контактов по требованию Шахт */}
        {/*<Typography variant="h6" marginTop={2}>*/}
        {/*    Реквизиты*/}
        {/*</Typography>*/}
        {/*<Typography variant="body2">*/}
        {/*    ООО «Единый платежный агент»*/}
        {/*</Typography>*/}
        {/*<Typography variant="body2">*/}
        {/*    ИНН/КПП 2312169260/231201001*/}
        {/*</Typography>*/}
        {/*<Typography variant="body2">*/}
        {/*    ОГРН 1102312001747*/}
        {/*</Typography>*/}
        {/*<Typography variant="body2">*/}
        {/*    Юридический адрес: 350072, г. Краснодар ул. Солнечная 10*/}
        {/*</Typography>*/}

        {/*<Typography variant="body2">*/}
        {/*        По вопросам, связанным с работой Личного*/}
        {/*        кабинета, либо по каким-либо другим вопросам в рамках лицевого счета следует обращаться*/}
        {/*        через форму обратной связи*/}
        {/*</Typography>*/}

        {/*<Button variant="contained" color={'success'} onClick={() => {}}>Обратная связь</Button>*/}
    </>
)