import axios from 'axios';
import qs from "qs";
import firebase from 'firebase/compat/app';

export class Client {

    client = axios.create({
        headers : {
            /*
                Установка признака, что запросы идут от web версии приложения.
                Нужно для возможности установки разных платежных агентов по типам клиентов (web, mobile)
             */
            'X-Client-Type': 'web'
        }
    });

    setFirebaseToken(token) {
        this.client.defaults.headers.common['X-Firebase-Auth'] = token;

        if (this.client.defaults.headers.common.hasOwnProperty('X-Jwt-Auth')) {
            delete this.client.defaults.headers.common['X-Jwt-Auth'];
        }
    }

    setJWT(token) {
        this.client.defaults.headers.common['X-Jwt-Auth'] = token;

        if (this.client.defaults.headers.common.hasOwnProperty('X-Firebase-Auth')) {
            delete this.client.defaults.headers.common['X-Firebase-Auth'];
        }
    }

    get(url) {
        return new Promise((resolve, reject) => {
            this.client.get(url)
                .then(response => Client.thenHandler(resolve, reject, response))
                .catch(error => Client.catchHandler(resolve, reject, error));
        });
    }

    post(url, data) {
        return new Promise((resolve, reject) => {
            this.client.post(url, this.stringify(data))
                .then(response => Client.thenHandler(resolve, reject, response))
                .catch(error => Client.catchHandler(resolve, reject, error));
        });
    }

    stringify = (data) => {
        /*
            https://github.com/ljharb/qs

            qs.stringify({ a: ['b', 'c'] }, { arrayFormat: 'indices' })
            => 'a[0]=b&a[1]=c'

            qs.stringify({ a: ['b', 'c'] }, { arrayFormat: 'brackets' })
            => 'a[]=b&a[]=c'

            qs.stringify({ a: ['b', 'c'] }, { arrayFormat: 'repeat' })
            => 'a=b&a=c'

            qs.stringify({ a: ['b', 'c'] }, { arrayFormat: 'comma' })
            => 'a=b,c'
         */

        // Меняем формат массива с data[0]=value на data[]=value
        // const options = {arrayFormat: 'brackets'};

        // Меняем формат массива с data[0]=value на data=value
        const options = {arrayFormat: 'repeat'};

        return qs.stringify(data, options);
    }

    json(url, data) {
        return new Promise((resolve, reject) => {
            this.client.post(url, data)
                .then(response => Client.thenHandler(resolve, reject, response))
                .catch(error => Client.catchHandler(resolve, reject, error));
        });
    }

    static thenHandler(resolve, reject, response) {
        if (response.data.response != null) {
            resolve(response.data);
        } else if (response.data.error) {
            throw new Error(response.data.error);
        } else if (response.request.responseURL.indexOf("/login") > -1) {
            // Нет авторизации
            throw new Error("Пользователь не авторизован");
            // TODO: Подумать что здесь делать вместо ошибки
            // window.location = response.request.responseURL;
        } else {
            throw new Error("Сервер вернул некорректный ответ");
        }
    }

    static catchHandler(resolve, reject, error) {
        reject(error);
    }

}

let client = new Client();

// Setup interceptor for refresh auth token
client.client.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const authType = localStorage.getItem('AuthType');

        // Возобновление токена делается только для Firebase. Обычный JWT токен имеет срок жизни 1 год
        if (authType === 'JWT') {
            const authToken = localStorage.getItem('authToken');

            originalRequest.headers['X-Jwt-Auth'] = authToken;
        } else {
            let idToken = await firebase.auth().currentUser.getIdToken(false);
            if (idToken) {
                client.setFirebaseToken(idToken);
                originalRequest.headers['X-Firebase-Auth'] = idToken;
            } else {
                console.log('Ошибка получения токена');
            }
        }

        return client.client(originalRequest);
    }

    return Promise.reject(error);
})

export default client;