import {Button, Menu, MenuItem} from "@mui/material";
import React, {FC, useState} from "react";
import {randomId} from "../../core/utils";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {SheetsSide} from "../../components/SheetsSide";
import {ReportB3View} from "./ReportB3View";
import {ReportPrintView} from "./ReportPrintView";
import {ReportF49ExView} from "./ReportF49ExView";
import {ReportConnectSendingByEmailView} from "./ReportConnectSendingByEmailView";
import {Company} from "../../redux/service/serviceTypes";

interface IProps {
    idGid: number;
    idSchema: number;
    companies: Company[];
}

const ReportMenu: FC<IProps> = ({idGid, idSchema, companies}) => {
    const [showReportB3View, setShowReportB3View] = useState(false);
    const openReportB3View = () => {
        setShowReportB3View(true);
        closeMenu();
    }
    const closeReportB3View = () => setShowReportB3View(false);

    const [showReportPrintView, setShowReportPrintView] = useState(false);
    const openReportPrintView = () => {
        setShowReportPrintView(true);
        closeMenu();
    }
    const closeReportPrintView = () => setShowReportPrintView(false);

    const [showReportF49ExView, setShowReportF49ExView] = useState(false);
    const openReportF49ExView = () => {
        setShowReportF49ExView(true);
        closeMenu();
    }
    const closeReportF49ExView = () => setShowReportF49ExView(false);

    const [showReportConnectSendingByEmailView, setShowReportConnectSendingByEmailView] = useState(false);
    const openReportConnectSendingByEmailView = () => {
        setShowReportConnectSendingByEmailView(true);
        closeMenu();
    }
    const closeReportConnectSendingByEmailView = () => setShowReportConnectSendingByEmailView(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const reportButtonId = `report-button-${randomId()}`;
    const reportMenuId = `report-menu-${randomId()}`;

    return (
        <>
            <Button
                id={reportButtonId}
                aria-controls={openMenu ? reportMenuId : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                variant="text"
                onClick={handleClick}
                disableElevation
                startIcon={<PictureAsPdfIcon />}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Квитанции и документы
            </Button>
            <Menu
                id={reportMenuId}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={closeMenu}
                MenuListProps={{
                    'aria-labelledby': reportButtonId,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={openReportF49ExView}>Заказать квитанцию</MenuItem>
                <MenuItem onClick={openReportB3View}>Заказать справку о начислениях</MenuItem>
                <MenuItem onClick={openReportConnectSendingByEmailView}>Подключить электронную квитанцию</MenuItem>
                {/*<MenuItem onClick={openReportPrintView}>Распечатать квитанцию</MenuItem>*/}
            </Menu>

            <SheetsSide open={showReportF49ExView} onClose={closeReportF49ExView}>
                <ReportF49ExView idGid={idGid} idSchema={idSchema} companies={companies} onSuccess={closeReportF49ExView} />
            </SheetsSide>
            <SheetsSide open={showReportB3View} onClose={closeReportB3View}>
                <ReportB3View idGid={idGid} idSchema={idSchema} companies={companies} onSuccess={closeReportB3View} />
            </SheetsSide>
            <SheetsSide open={showReportConnectSendingByEmailView} onClose={closeReportConnectSendingByEmailView}>
                <ReportConnectSendingByEmailView idGid={idGid} idSchema={idSchema} companies={companies} onSuccess={closeReportConnectSendingByEmailView} />
            </SheetsSide>
            <SheetsSide open={showReportPrintView} onClose={closeReportPrintView}>
                <ReportPrintView />
            </SheetsSide>
        </>
    )
}

export default ReportMenu;