import React, {FC} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme} from "@mui/material";
import {ServiceRowView} from "./ServiceRowView";
import {SxProps} from "@mui/system";
import {Service} from "../../../redux/service/serviceTypes";

export interface IProps {
    services: Service[];
    sx?: SxProps<Theme>;
    onEditService: (service: Service) => void;
}

export const ServiceTableView: FC<IProps> = ({services, onEditService, sx}) => {
    return (
        <TableContainer sx={sx} style={{padding: 24}}>
            <Table size="small" aria-label="service list">
                <TableHead>
                    <TableRow>
                        <TableCell style={{paddingLeft: 0, fontWeight: 700}}>Услуга</TableCell>
                        <TableCell style={{fontWeight: 700}} align="right">Долг<span style={{color: 'red'}}>*</span></TableCell>
                        <TableCell style={{fontWeight: 700}} align="right">Начислено<span style={{color: 'red'}}>**</span></TableCell>
                        <TableCell style={{fontWeight: 700}} align="right">К оплате<span style={{color: 'red'}}>***</span></TableCell>
                        <TableCell align="right" width={40}></TableCell>
                        <TableCell align="right" width={60} style={{paddingRight: 0}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {services?.map(service => <ServiceRowView key={service.id} service={service} onEditService={onEditService} />)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}