import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {FC} from "react";
import {makeStyles} from "@mui/styles";
import {LkPaymentItem} from "../../../../redux/payment/paymentTypes";
import PaymentSchemaDescription from "../PaymentSchemaDescription";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    }
});

interface IProps {
    data: LkPaymentItem[] | undefined
}

const PaymentInfoServiceList: FC<IProps> = ({data}) => {
    const classes = useStyles();

    const styleForDuplicates = (duplicates: any, item: any) => {
        const key = item.idGlobalAccount + "_" + item.idReceiptGroup + "_" + item.idReceipt
            + "_" + item.idCompany + "_" + item.codeAccount + "_" + item.idService + "_" + item.idSupplier;

        return (duplicates.get(key) || 0) > 1 ? {background: "red"} : {};
    }

    if (data === undefined) return null

    // TODO: Поиск косячных записей, которые схлопнулись по уникальному ключу
    const duplicates = new Map();
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const key = item.idGlobalAccount + "_" + item.idReceiptGroup + "_" + item.idReceipt
            + "_" + item.idCompany + "_" + item.codeAccount + "_" + item.idService + "_" + item.idSupplier;
        duplicates.set(key, duplicates.get(key) + 1 || 1);
    }

    return (
        <TableContainer component={Paper} style={{marginTop: "10px"}}>
            <Table className={classes.table} size="small" aria-label="payment items">
                <TableHead>
                    <TableRow>
                        <TableCell>Схема</TableCell>
                        <TableCell>ГИД</TableCell>
                        <TableCell>ЛС</TableCell>
                        <TableCell>IdReceiptGroup</TableCell>
                        <TableCell>IdReceipt</TableCell>
                        <TableCell>Сумма</TableCell>
                        <TableCell>Комиссия</TableCell>
                        <TableCell>Итого</TableCell>
                        <TableCell>Услуга</TableCell>
                        <TableCell>Поставщик</TableCell>
                        <TableCell>ИНН поставщика </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(row => (
                        <TableRow hover key={row.id} style={styleForDuplicates(duplicates, row)}>
                            <TableCell><PaymentSchemaDescription value={row.idSchema} /></TableCell>
                            <TableCell>{row.idGlobalAccount}</TableCell>
                            <TableCell>{row.codeAccount}</TableCell>
                            <TableCell>{row.idReceiptGroup}</TableCell>
                            <TableCell>{row.idReceipt}</TableCell>
                            <TableCell align="right">{row.amount}</TableCell>
                            <TableCell align="right">{row.commission}</TableCell>
                            <TableCell align="right">{(row.amount + row.commission).toFixed(2)}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.supplierName}</TableCell>
                            <TableCell>{row.supplierInn}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PaymentInfoServiceList