import React from "react";
import styles from './InfoShoppingPage.module.scss'
import {Typography} from "@mui/material";

export const InfoShoppingPage = () => (
    <div className={styles.content}>
        <Typography variant="body1">
            <p>
                Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:
            </p>
            <p>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <img src={"/images/pay_system/mir.png"} alt={'МИР'} />
                        </td>
                        <td>МИР</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={"/images/pay_system/visa.png"} alt={'VISA'} />
                        </td>
                        <td>VISA International</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={"/images/pay_system/mastercard.png"} alt={'Mastercard'} />
                        </td>
                        <td>Mastercard Worldwide</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={"/images/pay_system/jcb.png"} alt={'JCB'} />
                        </td>
                        <td>JCB</td>
                    </tr>
                    </tbody>
                </table>
            </p>
            <p>
                Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК.
                Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с
                использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного
                проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для
                проведения платежа также может потребоваться ввод специального пароля.
            </p>
            <p>
                Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной
                информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам
                за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским
                картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int.,
                MasterCard Europe Sprl, JCB.
            </p>
        </Typography>
    </div>
)