import React from "react";
import {Container, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    footer: {
        // @ts-ignore
        padding: theme.spacing(2, 1),
        marginTop: 'auto',
        textAlign: 'center',
    }
}));

export const AppFooter = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="md">
                <Typography variant="body2" color="textSecondary">
                    {`© ${new Date().getFullYear()} `}
                    <Link color="inherit" href="https://lk.odnookno.info/">
                        Одно окно. ЖКХ
                    </Link>
                </Typography>
            </Container>
        </footer>
    )
}