import React from "react";
import {CardItem} from "./CardItem";
import {LoadingPanel} from "../LoadingPanel";
import {useGetUserCardListQuery} from "../../core/api";
import {Alert, Box, Button, Grid} from "@mui/material";
import {CardConnectByGid} from "./connect/CardConnectByGid";
import {CardConnectByAccount} from "./connect/CardConnectByAccount";
import {useHistory} from "react-router";
import {PayButton} from "./PayButton";
import {useAppSelector} from "../../core/hooks";
import {selectCards} from "../../redux/service/serviceSlice";
import RemoveFromQueueIcon from "@mui/icons-material/RemoveFromQueue";

export const CardPage = () => {
    const history = useHistory();
    const { error, isLoading } = useGetUserCardListQuery();
    const data = useAppSelector(selectCards);

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    const calcAmount = () => {
        return data!
            .filter(card => card.select)
            .reduce(function(sum, current) {
                return sum + current.total;
            }, 0);
    }

    const cardSX = {
        width: {
            xs: 1,
            md: 350,
        },
    };

    const openCards = () => {
        if (data) {
            const idx = data.filter(card => card.select).map(card => card.id);

            if (idx.length === data.length) {
                history.push(`/cards/all`);
            } else {
                history.push(`/cards/${idx.join(',')}`);
            }
        }
    }

    return (
        <Box>
            <Grid style={{ marginBottom: '20px' }} sx={{ flexGrow: 1 }} container spacing={2}>
                {data?.map(card => (
                    <Grid key={card.id} item sx={cardSX}>
                        <CardItem card={card} />
                    </Grid>
                ))}
            </Grid>

            <Grid style={{ marginBottom: '20px' }} sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item sx={cardSX}>
                    <CardConnectByGid />
                </Grid>
                <Grid item sx={cardSX}>
                    <CardConnectByAccount />
                </Grid>
            </Grid>

            {data && <PayButton amount={calcAmount()} onClick={openCards} />}

            <Box style={{textAlign: "center", marginTop: 30, marginBottom: 20}}>
                <Button variant="contained"
                        color="success"
                        style={{fontWeight: 700}}
                        onClick={() => history.push(`/device`)}
                        startIcon={<RemoveFromQueueIcon/>}
                >
                    Передать показания
                </Button>
            </Box>
        </Box>
    )
}