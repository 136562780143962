import React, {Fragment} from 'react'
import {Link} from "react-router-dom";
import styles from "./Pade404.module.scss";

export default () => (
    <Fragment>
        <div className={styles.img}>
            <img src={"/images/404.jpg"} alt={"Страница не найдена"}/>
        </div>
        <div className={styles.link}>
            <Link to={"/"}>Перейти на главную</Link>
        </div>
    </Fragment>
)