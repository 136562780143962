import IconButton from "@mui/material/IconButton";
import {Badge} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {useGetAdminCountNewMessageQuery} from "../../core/api";

export const AdminMessageIconButton = () => {
    const history = useHistory();

    const handleMenuMessages = () => history.push("/admin/messages");

    const { data, isLoading } = useGetAdminCountNewMessageQuery()

    if (isLoading) {
        return null
    }

    return (
        <IconButton
            onClick={handleMenuMessages}
            aria-label="Показать новые сообщения"
            color="inherit"
        >
            <Badge badgeContent={data} color="secondary">
                <MailIcon />
            </Badge>
        </IconButton>
    )
}