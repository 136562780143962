import * as React from 'react';
import {FC} from 'react';
import {AppBar} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import {useAppSelector} from "../../core/hooks";
import {selectIsAdmin, selectIsSignedIn} from "../../redux/user/userSlice";
import {UserMessageIconButton} from "./UserMessageIconButton";
import {AdminMessageIconButton} from "./AdminMessageIconButton";

interface IProps {
    handleDrawerToggle?: () => void;
    sx?: SxProps<Theme>;
}

export const BaseAppBar: FC<IProps> = ({handleDrawerToggle, sx}) => {
    const isAdmin = useAppSelector(selectIsAdmin)
    const isSignedIn = useAppSelector(selectIsSignedIn)

    return (
        <AppBar position="fixed" sx={sx}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>

                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    Одно окно. ЖКХ
                </Typography>

                {isSignedIn && isAdmin && <AdminMessageIconButton />}

                {isSignedIn && <UserMessageIconButton />}
            </Toolbar>
        </AppBar>
    )
}