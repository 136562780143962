import React, {useLayoutEffect, useState} from "react";

import {Alert, Box, Button, CircularProgress, Link, Stack, Typography} from "@mui/material";
import {useQuery} from "../../core/hooks";
import {useCompletePaymentMutation} from "../../core/api";

export const PaymentWebSuccessPage = () => {
    const query = useQuery();
    const orderId = query.get('orderId'); // Удалить после перехода на SberEcom
    const mdOrder = query.get('mdOrder'); // Для SberEcom
    const [completePaymentMutation, {error: completePaymentError}] = useCompletePaymentMutation();

    const [complete, setComplete] = useState(false);

    useLayoutEffect(() => {
        completePayment();
    }, []);

    const completePayment = async () => {
        let order = orderId || mdOrder;

        if (order) {
            const result = await completePaymentMutation({
                paySystem: 'SberWeb',
                orderId: order
            })

            // @ts-ignore
            if (result.error) {
                console.log('Ошибка оплаты заказа');
            } else {
                setTimeout(() => {
                    window.location.assign('/');
                }, 3000)
            }

            setComplete(true);
        }
    }

    return (
        <Box sx={{display: 'flex'}}>
            <Stack sx={{width: '100%', padding: 4}}
                   spacing={2}
                   direction="column"
                   alignItems="center"
                   justifyContent="space-between">

                {completePaymentError && (
                    <Alert severity="error" style={{marginBottom: "10px"}}>
                        Ошибка проведения платежа
                    </Alert>
                )}

                {complete && !completePaymentError && (
                    <Alert severity="success" style={{marginBottom: "10px"}}>
                        Платеж успешно проведен
                    </Alert>
                )}

                {!complete && (
                    <CircularProgress color="success"/>
                )}

                {!complete && (
                    <Typography style={{fontWeight: 700}}>
                        Обработка платежа...
                    </Typography>
                )}

                {complete && (
                    <Link href={`/`} underline="none">
                        <Button variant="contained" color="primary" size="small">
                            Закрыть
                        </Button>
                    </Link>
                )}
            </Stack>
        </Box>
    )
}