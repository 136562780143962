import {FC} from 'react';
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";

import UserIcon from "@mui/icons-material/AccountCircle";

export interface IProps {
    user: any;
}

const Contact: FC<IProps> = ({user}) => {
    return (
        <>
            {user.phoneNumber}

            {user.email && user.phoneNumber && <br/>}
            {user.email}
        </>
    )
}

export const DrawerMenuUserCard: FC<IProps> = ({user}) => {
    return (
        <List>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <UserIcon fontSize={'large'}/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.name} secondary={<Contact user={user}/>} />
            </ListItem>
        </List>
    )
}