import React, {FC, Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    Alert,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {Receipt} from "@mui/icons-material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {makeStyles} from "@mui/styles";
import PaymentSourceSelect from "./PaymentSourceSelect";
import PaymentSchemaSelect from "./PaymentSchemaSelect";
import PaymentStatusSelect from "./PaymentStatusSelect";
import {printSource, printStatus} from "./payment_utils";
import CustomDateField from "../../../components/CustomDateField";
import PaymentExportForm from "./PaymentExportForm";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

interface IProps {
    isFetch: boolean;
    data: any[];
    error: any;
    total: number;
    onLoad: (
        limit: number,
        offset: number,
        source: number,
        schema: number,
        status: number,
        dateBegin: string | null,
        dateEnd: string | null
    ) => void
}

const PaymentList: FC<IProps> = ({isFetch, data, error, total, onLoad}) => {
    const classes = useStyles();

    const [dateBegin, setDateBegin] = useState<string | null>(null)
    const [dateEnd, setDateEnd] = useState<string | null>(null)
    const [status, setStatus] = useState(2)
    const [source, setSource] = useState(-1)
    const [schema, setSchema] = useState(-1)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    useEffect(() => {
        onLoad(rowsPerPage, page * rowsPerPage, source, schema, status, dateBegin, dateEnd);
    }, [page, rowsPerPage, schema, source, status, dateBegin, dateEnd]);

    const handleChangePage = (event: any, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = (event: any) => {
        let rowsPerPage = parseInt(event.target.value, 10);

        setPage(0);
        setRowsPerPage(rowsPerPage);
    };

    let isAll = status === -1;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="payments list">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={12} align={"right"}>
                            <Stack direction="row" spacing={1} paddingTop={2} paddingBottom={1}>
                                <PaymentSchemaSelect value={schema} onChange={setSchema} />
                                <PaymentSourceSelect value={source} onChange={setSource} />
                                <PaymentStatusSelect value={status} onChange={setStatus} />
                                <PaymentExportForm schema={schema} source={source} status={status} dateBegin={dateBegin} dateEnd={dateEnd} />
                            </Stack>
                            <Stack direction="row" spacing={1} paddingTop={2} paddingBottom={1}>
                                <CustomDateField defaultValue={dateBegin} label={"Дата начала"} onChange={setDateBegin} />
                                <CustomDateField defaultValue={dateEnd} label={"Дата окончания"} onChange={setDateEnd} />
                            </Stack>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ИД</TableCell>
                        <TableCell>ИД пользователя</TableCell>
                        <TableCell>Источник</TableCell>
                        <TableCell>Ордер</TableCell>
                        <TableCell>Сумма</TableCell>
                        <TableCell>Дата создания</TableCell>
                        <TableCell>Дата оплаты</TableCell>
                        {isAll && <TableCell>Статус</TableCell>}
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isFetch ? (
                        <TableRow>
                            <TableCell colSpan={isAll ? 8 : 7} align={'center'}>
                                <LinearProgress />
                            </TableCell>
                        </TableRow>
                    ) : (
                        <Fragment>
                            {error && (
                                <TableCell colSpan={isAll ? 8 : 7} align={'center'}>
                                    <Alert severity="error">{error}</Alert>
                                </TableCell>
                            )}

                            {data.map(row => (
                                <TableRow hover key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell>{row.idLkUser}</TableCell>
                                    <TableCell>{printSource(row.orderSource)}</TableCell>
                                    <TableCell>{row.orderId}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.dateAdd}</TableCell>
                                    <TableCell>{row.datePayment}</TableCell>
                                    {isAll && <TableCell>{printStatus(row.orderStatus)}</TableCell>}
                                    <TableCell>
                                        <Link to={`/admin/payment/${row.id}`} target={'_blank'}>
                                            <IconButton aria-label="Показать детали платежа"
                                                        color="inherit">
                                                <Receipt />
                                            </IconButton>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Fragment>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 30, 50, { label: 'Все', value: total }]}
                            colSpan={5}
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'Записей на странице' },
                                native: true,
                            }}
                            labelRowsPerPage={'Записей на странице'}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default PaymentList;