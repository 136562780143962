import React, {FC} from "react";
import {Service} from "../../../redux/service/serviceTypes";
import {Switch} from "@mui/material";
import {useAppDispatch} from "../../../core/hooks";
import {toggleService} from "../../../redux/service/serviceSlice";

interface IProps {
    service: Service;
}

export const ServiceSwitch: FC<IProps> = ({service}) => {
    const dispatch = useAppDispatch();

    const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(toggleService({
            reduxIndex: service.reduxIndex,
            value: event.target.checked
        }));
    }

    return (
        <Switch onChange={switchHandler} checked={service.select} />
    )
}