import React, {FC} from "react";
import {printStatus} from "./payment_utils";
import CustomSelectField from "../../../components/CustomSelectField";

interface IProps {
    value: number;
    onChange: (value: number) => void;
}

const PaymentStatusSelect: FC<IProps> = ({value, onChange}) => {
    return (
        <CustomSelectField
            label={'Статус'}
            value={value}
            items={[
                {value: -1, description: printStatus(-1)},
                {value: 0, description: printStatus(0)},
                {value: 1, description: printStatus(1)},
                {value: 2, description: printStatus(2)},
                {value: 3, description: printStatus(3)},
                {value: 4, description: printStatus(4)},
                {value: 6, description: printStatus(6)},
                {value: 999, description: printStatus(999)},
            ]}
            onChange={onChange} />
    )
}

export default PaymentStatusSelect;