// Import FirebaseAuth and firebase.
import React, {useEffect, useState} from 'react';
import FirebaseUIAuth from "react-firebaseui-localized";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Config = firebase.auth.Config;

// Configure FirebaseUI.
const uiConfig: Config = {
    // Popup signin flow rather than redirect flow.
    // @ts-ignore
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            defaultCountry: 'RU'
        }
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => {
            localStorage.setItem('AuthType', 'Firebase')
            return false;
        }
    },
    // Terms of service url/callback.
    tosUrl: 'https://lk.odnookno.info/info/terms',
    // Privacy policy url/callback.
    privacyPolicyUrl: function() {
        window.location.assign('https://lk.odnookno.info/info/privacy');
    }
};

export const FirebaseSignIn = () => {
    const [widget, setWidget] = useState(null);

    useEffect(() => {
        // @ts-ignore
        setWidget(<FirebaseUIAuth
            lang="ru"
            rtl={false}
            version="6.0.0"
            config={uiConfig}
            auth={firebase.auth()}
            firebase={firebase}
        />)
    }, [])

    return <>{widget}</>
}