import React, {FC, useState} from "react";
import {Service} from "../../../redux/service/serviceTypes";
import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import {formatPositiveRUB} from "../../../core/utils";
import {useAppDispatch} from "../../../core/hooks";
import {savePaid} from "../../../redux/service/serviceSlice";
import {CustomTextField} from "../../../components/CustomTextField";

interface IProps {
    service?: Service;
    onSave: () => void;
}

export const EditServicePaidView: FC<IProps> = ({service, onSave}) => {
    const dispatch = useAppDispatch();

    const [value, setValue] = useState<string>(service?.paid ? service.paid.toString() : '');
    const handleChange = (newValue: string) => {
        setValue(newValue ? parseFloat(newValue).toString() : '');
    };

    const saveAmount = () => {
        if (service) {
            dispatch(savePaid({
                reduxIndex: service.reduxIndex,
                value: parseFloat(value)
            }));
        }

        onSave();
    }

    return service ? (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Редактирование суммы
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            {service.name}

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <Button variant="outlined" onClick={() => setValue(service.fullTotal.toString())} >
                    Долг + начисление: {formatPositiveRUB(service.fullTotal)}
                </Button>
                <Button variant="outlined" onClick={() => setValue(service.total.toString())} >
                    Только долг: {formatPositiveRUB(service.total)}
                </Button>

                <CustomTextField
                    label='Сумма'
                    type="number"
                    value={value}
                    onChange={handleChange}
                    placeholder='0'/>

                <Button variant="contained" color="success" onClick={saveAmount} >
                    Сохранить
                </Button>
            </Stack>
        </Box>
    ) : null;
}