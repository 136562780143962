import React, {FC} from 'react';
import {useHistory} from "react-router";
import {Button, Card, CardActions, Divider, Stack, Typography} from "@mui/material";
import {UserCard} from "../../redux/service/serviceTypes";
import {CardItemMenu} from "./CardItemMenu";
import {CardSwitch} from "./CardSwitch";

interface IProps {
    card: UserCard;
}

export const CardItem: FC<IProps> = ({card}) => {
    const history = useHistory();

    return (
        <Card>
            <Stack spacing={1.5} sx={{ paddingLeft: 3, paddingRight: 1, py: 1, bgcolor: 'background.default' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography fontWeight={700}>
                        {card.name}
                    </Typography>

                    <CardItemMenu card={card} />
                </Stack>

                <Typography variant="body2" color="text.secondary">
                    Услуг: {card.countService}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    К оплате: {card.total}
                </Typography>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
            >
                <CardActions>
                    <Button size="small" onClick={() => history.push(`/cards/${card.id}`)}>Подробнее</Button>
                </CardActions>
                <CardSwitch card={card}/>
            </Stack>
        </Card>
    );
}