import React from "react";

import styles from './InfoTermsPage.module.scss'
import {Typography} from "@mui/material";

export const InfoTermsPage = () => (
    <div className={styles.content}>
        <Typography variant="h5">
            Пользовательское соглашение (оферта) об условиях использования Личного кабинета «Одно окно. ЖКХ»
        </Typography>

        <Typography variant="body2">
            Настоящее Пользовательское соглашение (далее по тексту – «Соглашение») регулирует отношения по использованию
            Личного кабинета «Одно окно. ЖКХ» ООО «Единый платежный агент» (далее по тексту – «Владелец Личного
            кабинета»), с одной стороны, и физическим лицом, принявшим условия настоящего Соглашения путём присоединения
            к настоящему Соглашению в целом и безоговорочно (далее по тексту – «Пользователь»), с другой стороны, далее
            при совместном упоминании именуемые «Стороны», а по отдельности – «Сторона».
        </Typography>

        <Typography variant="h6" marginTop={2}>
            1. Термины и определения
        </Typography>

        <Typography variant="body2">
            Авторизация – идентификация Пользователя с помощью его логина и пароля при предоставлении доступа к Личному
            кабинету.
        </Typography>
        <Typography variant="body2">
            Акцепт – в соответствии со статьей 438 Гражданского кодекса РФ, безусловным принятием (Акцептом) условий
            настоящей Политики конфиденциальности считается выбор пункта, подтверждающего согласие с условиями Политики
            конфиденциальности при первом обращении Пользователя в Личный кабинет. После совершения Акцепта Политики
            конфиденциальности, Пользователь считается принявшим безоговорочно и в полном объеме условия настоящей
            Политики конфиденциальности.
        </Typography>
        <Typography variant="body2">
            Банк-эквайер – кредитная организация, обеспечивающая переводы денежных средств в пользу поставщиков услуг с
            использованием банковских карт Пользователя, посредством Личного кабинета.
        </Typography>
        <Typography variant="body2">
            Банк-эмитент – кредитная организация, осуществляющая выпуск банковских карт, предназначенных для совершения
            держателем карт операций с денежными средствами, находящимися у эмитента, и расчетное обслуживание счетов
            держателей карт.
        </Typography>
        <Typography variant="body2">
            Интерфейс Личного кабинета – совокупность экранных форм, появляющихся на экране Устройства Пользователя
            после входа в Личный кабинет и позволяющих работать с Личным кабинетом: создавать, отправлять и получать
            электронные документы и сообщения, а также просматривать информацию, размещенную в Личном кабинете.
        </Typography>
        <Typography variant="body2">
            Логин – регистрационное имя, используемое Пользователем в качестве идентификатора для входа в Личный
            кабинет, для регистрации.
        </Typography>
        <Typography variant="body2">
            Пароль – секретная последовательность символов, используемая Пользователем в качестве идентификатора для
            входа в Личный кабинет.
        </Typography>
        <Typography variant="body2">
            Пользователь – физическое лицо, обладающее полной дееспособностью в соответствии с законодательством РФ,
            использующее Личный кабинет на Устройстве и акцептовавшее Публичную оферту по предоставлению права
            использования Личного кабинета.
        </Typography>
        <Typography variant="body2">
            ГИД – глобальный идентификатор (глобальный идентификационный номер) Пользователя.
        </Typography>
        <Typography variant="body2">
            Личный кабинет – веб-ресурс (веб-сайт) «Одно окно. ЖКХ», предоставляющий доступ Пользователю к информации по
            его Лицевому счету (ГИДу), а также доступ к Услугам.
        </Typography>

        <Typography variant="body2">
            Профиль – раздел Личного кабинета, доступный Пользователю после прохождения процедуры Регистрации,
            позволяющий Пользователю управлять своей учетной записью и осуществлять действия, направленные на использование
            функциональных возможностей Личного кабинета.
        </Typography>
        <Typography variant="body2">
            Услуги – услуги по предоставлению доступа Пользователю к Личному кабинету, просмотру информации,
            содержащейся в Личном кабинете, а также оплаты в пользу поставщиков услуг с использованием банковских карт
            посредством Личного кабинета.
        </Typography>
        <Typography variant="body2">
            Устройство – техническое средство, предоставляющее доступ к Личному кабинету и имеющее техническую возможность
            доступа к сети Интернет, посредством которого Пользователь регистрируется в Личном кабинете.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            2. Предмет Соглашения
        </Typography>

        <Typography variant="body2">
            2.1. В соответствии с условиями настоящего Соглашения Владелец Личного кабинета предоставляет Пользователю
            права на воспроизведение Личного кабинета на своем Устройстве в пределах и на условиях, установленных
            настоящим Соглашением.
        </Typography>
        <Typography variant="body2">
            2.2. Для использования Личного кабинета Пользователь обязан, в порядке, установленном настоящим Соглашением,
            подтвердить, что прочитал, понял и согласен соблюдать условия настоящего Соглашения и присоединиться к
            настоящему Соглашению в целом, путём совершения Акцепта Соглашения (проставление «галочки») в момент
            Регистрации. С момента Акцепта Соглашения Пользователем, настоящее Соглашение считается заключенным с
            Пользователем, и Пользователь приобретает права и обязанности, предусмотренные настоящим Соглашением.
        </Typography>
        <Typography variant="body2">
            2.3. Настоящее Соглашение и отношения Сторон, возникшие из настоящего Соглашения, регулируются
            законодательством Российской Федерации.
            Настоящее Соглашение в соответствии со ст.435 Гражданского кодекса Российской Федерации является офертой -
            адресованной любым физическим лицам предложением Владельца Личного кабинета заключить настоящее Соглашение
            на указанных в нём условиях. Владелец Личного кабинета считает себя заключившим настоящее Соглашение с
            физическими лицами, которые примут указанную оферту. Указанная оферта может быть принята физическими лицами
            не иначе как путем присоединения к настоящему Соглашению в целом.
            В отношении порядка и условий заключения настоящего Соглашения применяются нормы Гражданского кодекса
            Российской Федерации, регулирующие порядок и условия заключения публичного договора (ст. 426 ГК РФ),
            договора присоединения (ст. 428 ГК РФ), направления оферты и акцепта (ст. 435–444 ГК РФ).
        </Typography>
        <Typography variant="body2">
            2.4. Владелец Личного кабинета оставляет за собой право в одностороннем порядке изменять условия настоящего
            Соглашения, без какого-либо специального уведомления Пользователя. Пользователь обязуется самостоятельно
            отслеживать актуальность настоящего Соглашения. Новая редакция Соглашения вступает в силу с момента
            размещения Владельцем Личного кабинета, либо доведения до сведения Пользователя в иной удобной форме, если
            иное не предусмотрено новой редакцией Соглашения. Продолжение использования Личного кабинета после внесения
            изменений в настоящее Соглашение означает согласие Пользователя с такими изменениями.
        </Typography>
        <Typography variant="body2">
            2.5. В случае несогласия Пользователя с какими-либо положениями настоящего Соглашения, а также с внесенными
            изменениями в Соглашение, Пользователь не вправе пользоваться Личным кабинетом.
        </Typography>
        <Typography variant="body2">
            2.6. Соглашаясь с условиями настоящего Соглашения, Пользователь подтверждает свои право- и дееспособность,
            подтверждает достоверность предоставляемых им данных в рамках настоящего Соглашения и принимает на себя всю
            ответственность за их точность, полноту и достоверность.
        </Typography>
        <Typography variant="body2">
            2.7. Плата за воспроизведение Личного кабинета с Пользователя не взимается. Все платежи, которые могут
            взиматься Банками-эмитентами, Владельцем Личного кабинета, не являются платой за воспроизведение Личного
            кабинета (указанные платежи могут являться платой за выполнение соответствующего распоряжения (указания)
            Пользователя, предоставляемого с помощью Личного кабинета, порядок и размер взимания которых
            предусматривается условиями настоящего Соглашения или отдельными офертами Эмитентов.
        </Typography>
        <Typography variant="body2">
            2.8. Все операции по платёжным картам выполняются в защищённом режиме. Для совершения электронного платежа
            Пользователь перенаправляется на защищенную страницу Банка-эквайера, где необходимо будет ввести реквизиты
            карты. Для обработки платежа Банком-эквайером используются специальные технологии безопасности карточных
            онлайн-платежей 3D-Secure.
        </Typography>
        <Typography variant="body2">
            2.9. В Личном кабинете используется московское время, для фиксации даты и времени проведения платежа (время
            часовой зоны, в которой расположена столица Российской Федерации город Москва).
        </Typography>
        <Typography variant="body2">
            2.10. Все объекты, доступные при помощи Личного кабинета (в том числе элементы дизайна, текст, графические
            изображения, иллюстрации, скрипты, программы, базы данных, и другие объекты), а также сам Личный кабинет
            являются объектами исключительных прав Владельца Личного кабинета.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            3. Правила Регистрации
        </Typography>

        <Typography variant="body2">
            3.1. Личный кабинет доступен для использования по открытой ссылке в сети Интернет.
        </Typography>
        <Typography variant="body2">
            3.2. Первичная регистрация Пользователя в Личном кабинете осуществляется посредством заполнения
            регистрационной формы. При желании, Пользователь может сменить Пароль.
        </Typography>
        <Typography variant="body2">
            3.3. Пользователь с момента Регистрации получает возможность Авторизации в Личном кабинете. На экране
            авторизации от Пользователя требуется ввести Логин и Пароль, при этом предусмотрен функционал сохранения
            введенного ранее Пароля. При корректном вводе данных Пользователь попадает на главную страницу Личного
            кабинета.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            4. Права и обязанности Пользователя
        </Typography>

        <Typography variant="body2">
            4.1. Пользователь обязуется ознакомиться с условиями настоящего Соглашения и соблюдать их надлежащим
            образом.
        </Typography>
        <Typography variant="body2">
            4.2. Пользователь вправе использовать функционал Личного кабинета исключительно с целью личного
            некоммерческого использования в соответствии с условиями настоящего Соглашения.
        </Typography>
        <Typography variant="body2">
            4.3. Пользователь обязуется принимать надлежащие меры для обеспечения защиты и сохранности его Устройства и
            несет личную ответственность в случае доступа к его Устройству третьих лиц. При утрате Устройства,
            Пользователь обязан незамедлительно сообщить о данном факте Владельцу Личного кабинета с целью блокирования
            Профиля.
        </Typography>
        <Typography variant="body2">
            4.4. Если Пользователю становится известно о любом несанкционированном использовании его Профиля, а также в
            случае несанкционированного доступа к его Устройству. Пользователь обязуется принять необходимые меры
            защиты, в том числе произвести самостоятельно смену пароля, который использовался для идентификации в Личном
            кабинете.
        </Typography>
        <Typography variant="body2">
            4.5. Пользователь обязан хранить, не допускать возможности использования неуполномоченными лицами и не
            передавать третьим лицам данные, используемые для идентификации в Личном кабинете. В случае если к
            указанному Пользователем ящику электронной почты, был получен доступ третьими лицами, Пользователь обязуется
            незамедлительно внести изменения в регистрационные данные с целью невозможности отправки данных,
            используемых для идентификации в Личном кабинете, на такой скомпрометированный адрес электронной почты.
        </Typography>
        <Typography variant="body2">
            4.6. Пользователь обязуется своевременно обновлять свои персональные данные, если они изменились или
            оказались неточными.
        </Typography>
        <Typography variant="body2">
            4.7. Пользователю запрещено самостоятельно или с привлечением третьих лиц осуществлять декомпилирование
            Личного кабинета, а также распространять, доводить до всеобщего сведения и предоставлять иной доступ к
            Личному кабинету, осуществлять реверс-инжиниринг Личного кабинета или его отдельных элементов.
        </Typography>
        <Typography variant="body2">
            4.8. Пользователь несет ответственность за использование Личного кабинета и его функциональные возможности
            любыми способами, прямо непредусмотренными настоящим Соглашением.
        </Typography>
        <Typography variant="body2">
            4.9. Пользователь вправе в любой момент отказаться от условий настоящего Соглашения путем обращения к
            Владельцу Личного кабинета на отключение от Личного кабинета.
        </Typography>
        <Typography variant="body2">
            4.10. Пользователь вправе направлять обращения, предложения и претензии по поводу работы Личного кабинета по
            адресу электронной почты: support@odnookno.info.
        </Typography>
        <Typography variant="body2">
            4.11. В случае осуществления перевода денежных средств с использованием Личного кабинета Пользователем,
            находящимся на территории иностранного государства, все условия предоставления (оказания) товаров, (работ,
            услуг), а также условия осуществления перевода или возврата денежных средств, Пользователь обязуется
            выяснять самостоятельно до момента совершения перевода денежных средств.
        </Typography>


        <Typography variant="h6" marginTop={2}>
            5. Права и обязанности Владельца Личного кабинета
        </Typography>

        <Typography variant="body2">
            5.1. Владелец Личного кабинета имеет право определять состав Услуг Личного кабинета, его структуру и внешний
            вид, разрешать и ограничивать доступ Пользователя к Личному кабинету в соответствии с настоящим Соглашением
            и законодательством Российской Федерации.
        </Typography>
        <Typography variant="body2">
            5.2. Владелец Личного кабинета вправе блокировать работу Пользователя в Личном кабинете при наличии
            подозрений в несанкционированном доступе к Личному кабинету от имени Пользователя без предварительного
            уведомления последнего.
        </Typography>
        <Typography variant="body2">
            5.3. Владелец Личного кабинета вправе ограничить доступ к функциональным возможностям Личного кабинета по
            организационным или техническим причинам в одностороннем порядке до момента устранения таких причин.
            Владелец Личного кабинета обязуется в кратчайшие сроки разрешать возникшие проблемы в функционировании
            Личного кабинета, а в случае отсутствия такой возможности уведомить об этом Пользователя, любым возможным
            способом.
        </Typography>
        <Typography variant="body2">
            5.4. При выявлении случаев несанкционированного и(или) коммерческого использования Личного кабинета,
            Владелец Личного кабинета оставляет за собой право блокировать доступ Пользователю к Личному кабинету,
            осуществившего указанное нарушение
        </Typography>
        <Typography variant="body2">
            5.5. В целях улучшения и повышения стабильности работы Личного кабинета Владелец Личного кабинета вправе
            собирать, хранить и обрабатывать статистическую информацию об использовании Пользователем Личного кабинета.
        </Typography>
        <Typography variant="body2">
            5.6. Владелец Личного кабинета вправе взимать с Пользователей комиссию за осуществление перевода платежей.
            Взимание комиссии зависит от политики Поставщика услуг и указывается в Личном кабинете отдельно.
        </Typography>
        <Typography variant="body2">
            5.7. Владелец Личного кабинета вправе передавать права и обязанности по настоящему Соглашению третьим лицам
            только в целях исполнения настоящего Соглашения, без дополнительного согласия Пользователя. Пользователь
            подтверждает и соглашается с тем, что указанным третьим лицам предоставляются такие же права, как Владельцу
            Личного кабинета, в том числе в отношении персональных данных и сведений о Пользователе.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            6. Ответственность Сторон
        </Typography>

        <Typography variant="body2">
            6.1. Пользователь несет ответственность перед Владельцем Личного кабинета и третьими лицами за все действия,
            совершенные в Личном кабинете под его Профилем.
        </Typography>
        <Typography variant="body2">
            6.2. В случае нарушения условий использования Личного кабинета, указанных в настоящем Соглашении,
            Пользователь обязуется возместить Владельцу Личного кабинета убытки, причиненные такими действиями, в полном
            размере.
        </Typography>
        <Typography variant="body2">
            6.3. Владелец Личного кабинета не несет ответственности за возможный ущерб, порчу или утрату данных, которые
            могут возникнуть в результате нарушения Пользователем пункта 4.3. - 4.5.
        </Typography>
        <Typography variant="body2">
            6.4. Владелец Личного кабинета не несет ответственности за любую информацию, размещенную на порталах и
            сайтах третьих лиц, к которым Пользователи получили доступ через Личный кабинет, включая, в том числе, любые
            мнения или утверждения, выраженные на порталах и сайтах третьих лиц или в их материалах.
        </Typography>
        <Typography variant="body2">
            6.5. Владелец Личного кабинета не несет ответственности за неисполнение либо ненадлежащее исполнение своих
            обязательств вследствие сбоев в энергетических сетях, действий вредоносных программ, нарушений
            функционирования операторов, предоставляющих услуги доступа в Интернет, а также недобросовестных действий
            третьих лиц, направленных на несанкционированный доступ и (или) выведение из строя программного и (или)
            аппаратного комплекса Личного кабинета.
        </Typography>
        <Typography variant="body2">
            6.6. Владелец Личного кабинета не несёт ответственности за некорректное функционирование Личного кабинета,
            сбои и перерывы в работе Личного кабинета, повреждение или утерю данных, содержащихся в Личном кабинете,
            если они были вызваны обстоятельствами непреодолимой силы, в том числе в случае пожаров, эпидемий,
            чрезвычайных погодных условий, перебоев подачи электроэнергии, распространения вирусных компьютерных
            программ, а также если такие обстоятельства были вызваны умышленными незаконными действиями третьих лиц.
        </Typography>
        <Typography variant="body2">
            6.7. В целях исполнения обязательств по настоящему Соглашению любые действия, совершенные с использованием
            Устройства Пользователя, считаются совершенными соответствующим Пользователем, если Пользователем не
            доказано обратное.
        </Typography>
        <Typography variant="body2">
            6.8. Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или
            с использованием Личного кабинета под Профилем Пользователя, включая случаи добровольной передачи
            Пользователем данных для доступа к Личному кабинету Пользователя третьим лицам на любых условиях (в том
            числе по договорам или соглашениям). При этом все действия в рамках или с использованием Личного кабинета
            под учетной записью Пользователя считаются произведенными самим Пользователем.
        </Typography>
        <Typography variant="body2">
            6.9. Стороны обязаны обеспечить соблюдение мер по защите персональных данных, принципов и правил обработки
            персональных данных, конфиденциальность персональных данных и безопасность персональных данных при их
            обработке, в соответствии с Федеральным Законом «О персональных данных» No 152-ФЗ от 27.07.2006г.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            7. Заключительные положения
        </Typography>

        <Typography variant="body2">
            7.1. В случае возникновения любых споров или разногласий, связанных с исполнением настоящего Соглашения,
            Пользователь и Владелец Личного кабинета приложат все усилия для их разрешения путем проведения переговоров.
            В случае, если споры не будут разрешены путем переговоров, споры подлежат разрешению в соответствующем суде,
            в порядке, установленном действующим законодательством Российской Федерации.
        </Typography>
        <Typography variant="body2">
            7.2. Настоящее Соглашение вступает в силу для Пользователя, в соответствии с пунктом 2.2. настоящего
            Соглашения, с момента его Акцепта и действует в течение всего периода использования Пользователем Личного
            кабинета, и до тех пор, пока не будет изменено или расторгнуто в порядке, установленном действующим
            законодательством или настоящим Соглашением.
        </Typography>
        <Typography variant="body2">
            7.3. Если какое-либо из положений настоящего Соглашения будет признано недействительным, это не оказывает
            влияния на действительность или применимость остальных положений настоящего Соглашения.
        </Typography>
        <Typography variant="body2">
            7.4. Все обращения, направленные с электронного адреса Пользователя, зарегистрированного в Личном кабинете,
            принимаются к рассмотрению Владельцем Личного кабинета. Ответ на обращение Пользователя направляется по
            электронному адресу Пользователя, зарегистрированному в Личном кабинете и с которого поступило обращение.
            Все обращения, так или иначе связанные с функциональностью банковских карт (например, неисполнение
            распоряжения Пользователя об оплате товаров, работ, услуг третьих лиц с банковской карты, некорректное
            отражение сумм, списанных с банковской карты и т.п.), должны направляться Пользователем в адрес
            Банка-эмитента в соответствии с условиями договоров, заключаемых между Пользователем и Банком-эмитентом.
        </Typography>
    </div>
)