// @ts-ignore
import roundHalfEven from "round-half-even";
import moment from "moment/moment";

export const formatRUB = (value: number) => `${value.toFixed(2)} \u20BD`;

export const formatPositiveRUB = (value: number) => value > 0 ? formatRUB(value) : formatRUB(0);

export const getCommission = (value: number, commissionPercent: number) => roundHalfEven(value * commissionPercent, 2);

/** Комиссия в формате: (+0.50%) 0.00 ₽ */
export const formatPaidCommission = (value: number, commissionPercent: number) => {
    const commission = commissionPercent * 100;
    const commissionForValue = getCommission(value > 0 ? value : 0, commissionPercent);

    return `(+${commission.toFixed(2)}%) ${formatRUB(commissionForValue)}`;
}

const months = ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"];
export const formatMonth = (value: number) => {
    if (value > 0 && value <= 12) {
        return months[value - 1];
    }

    return value;
}

export const isBlank = (value: string | null) => value == null || value.trim() === '';

export const notBlank = (value: string | null) => !isBlank(value);

export const notBlankAny = (values: string[]) => {
    for (let value of values) {
        if (isBlank(value)) {
            return false;
        }
    }

    return true;
}

const regexPhone = /^[+ ]?[\- ()\d]*\d$/gi;
const regexPhoneClean = /\D*/gi;

export const isValidPhone = (phone: string) => {
    // Получение чистого номера
    const cleanNum = phone.replace(regexPhoneClean, '');

    // Проверка, что чистый номер не более 11 символов и что исходный номер телефона содержит только допустимые символы
    return cleanNum.length <= 11 && phone.trim().match(regexPhone);
}

export const randomId = () => {
    return (Math.random() + 1).toString(36).substring(7);
}

export const parseDay = (dayBeginPeriod?: string) => {
    if (dayBeginPeriod) {
        return moment(dayBeginPeriod).date()
    }

    return undefined
}