import React, {FC, Fragment} from "react";
import {LoadingPanel} from "../../LoadingPanel";
import {Alert, Paper} from "@mui/material";
import {useGetAdminChatUserInfoQuery} from "../../../core/api";

export interface IProps {
    chat: number;
}

export const AdminChatUserInfo: FC<IProps> = ({chat}) => {
    const { data, error, isLoading } = useGetAdminChatUserInfoQuery(chat)

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Paper style={{padding: "15px"}}>
            <Fragment>
                {data?.name && <div>Имя: {data.name}</div>}
                {data?.email && <div>E-Mail (OAuth): {data.email}</div>}
                {data?.profileEmail && <div>E-Mail (Профиль): {data.profileEmail}</div>}
                {data?.phone && <div>Телефон (OAuth): {data.phone}</div>}
                {data?.profilePhone && <div>Телефон (Профиль): {data.profilePhone}</div>}
            </Fragment>
        </Paper>
    )
}