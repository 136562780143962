import React, {FC, useState} from "react";
import {Stack, Typography} from "@mui/material";
import {ServiceListView} from "./ServiceListView";
import {ServiceTableView} from "./ServiceTableView";
import {Account, Service} from "../../../redux/service/serviceTypes";
import {SheetsSide} from "../../../components/SheetsSide";
import {EditServicePaidView} from "./EditServicePaidView";

export interface IProps {
    account: Account;
}

export const AccountView: FC<IProps> = ({account}) => {
    const [service, setService] = useState<Service | undefined>();
    const [showEditServicePaidView, setShowEditServicePaidView] = useState(false);

    const openEditServicePaidView = (service: Service) => {
        setService(service);
        setShowEditServicePaidView(true);
    }

    const closeEditServicePaidView = () => setShowEditServicePaidView(false);

    return (
        <Stack direction="column">
            <Typography variant="body2" style={{paddingLeft: 24, paddingRight: 24}}>
                <span style={{fontWeight: 700}}>№ ЛС: </span>{account.code}
            </Typography>
            {(account.lname || account.fname || account.mname) && (
                <Typography variant="body2" style={{paddingLeft: 24, paddingRight: 24}}>
                    <span style={{fontWeight: 700}}>Владелец ЛС: </span>{account.lname} {account.fname} {account.mname}
                </Typography>
            )}
            <Typography variant="body2" style={{paddingLeft: 24, paddingRight: 24}}>
                <span style={{fontWeight: 700}}>Адрес: </span>{account.address}
            </Typography>

            <ServiceTableView sx={{ display: { xs: 'none', md: 'block' } }} services={account.services!} onEditService={openEditServicePaidView} />

            <ServiceListView sx={{ display: { xs: 'block', md: 'none' } }} services={account.services!} onEditService={openEditServicePaidView} />

            <SheetsSide open={showEditServicePaidView} onClose={closeEditServicePaidView}>
                <EditServicePaidView service={service} onSave={closeEditServicePaidView} />
            </SheetsSide>
        </Stack>
    )
}