import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import client from "../../client";
import {useAppDispatch} from "../../core/hooks";
import {actionRefreshUserInfo} from "../../core/api";
import {setUserChecked} from "../../redux/user/userSlice";
import {LoadingPanel} from "../LoadingPanel";

export const VkIdCallbackAuth = () => {
    const history = useHistory();
    const routeUseLocation = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const search = routeUseLocation.search;
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const payload = JSON.parse(params.payload);

        const { token: silent_token, uuid } = payload;

        client.post('/auth/vk/token', {silent_token, uuid})
            .then((data) => {
                localStorage.setItem('AuthType', 'JWT');
                localStorage.setItem('AuthToken', data.response);

                client.setJWT(data.response);

                dispatch(actionRefreshUserInfo);
                dispatch(setUserChecked(true));

                history.push('/');
            })
            .catch((error) => {
                console.error(error.message)
            })

    }, []);

    return <LoadingPanel />
}