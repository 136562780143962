import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, {FC, useState} from "react";
import {SheetsSide} from "../../components/SheetsSide";
import {CardRenameView} from "./CardRenameView";
import {CardRemoveView} from "./CardRemoveView";
import {UserCard} from "../../redux/service/serviceTypes";

interface IProps {
    card: UserCard;
}

export const CardItemMenu: FC<IProps> = ({card}) => {
    const [showRemoveView, setShowRemoveView] = useState(false);
    const [showRenameView, setShowRenameView] = useState(false);

    const openRemoveView = () => {
        setShowRemoveView(true);
        handleClose();
    }
    const closeRemoveView = () => setShowRemoveView(false);

    const openRenameView = () => {
        setShowRenameView(true);
        handleClose();
    }
    const closeRenameView = () => setShowRenameView(false);


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton id="menu-button"
                        aria-controls={open ? 'card-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={openRenameView}>Переименовать</MenuItem>
                <MenuItem onClick={openRemoveView}>Удалить</MenuItem>
            </Menu>

            <SheetsSide open={showRenameView} onClose={closeRenameView}>
                <CardRenameView card={card} onRename={closeRenameView} />
            </SheetsSide>
            <SheetsSide open={showRemoveView} onClose={closeRemoveView}>
                <CardRemoveView card={card} onRemove={closeRenameView} />
            </SheetsSide>
        </>
    )
}