import {Action, configureStore, Reducer, ThunkAction} from '@reduxjs/toolkit';
import {createBrowserHistory} from "history";
import {connectRouter, routerMiddleware} from 'connected-react-router'
import userReducer from '../redux/user/userSlice'
import serviceReducer from '../redux/service/serviceSlice'
import {api} from "./api";

export const history = createBrowserHistory();

export const store = configureStore({
    reducer: {
        router: connectRouter(history) as Reducer,
        [api.reducerPath]: api.reducer,
        user: userReducer,
        service: serviceReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(api.middleware)
        .concat(routerMiddleware(history)),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;