import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {api} from "../../core/api";
import {ServiceState} from "./serviceTypes";
import {RootState} from "../../core/store";

const initialState: ServiceState = {}

const findServiceByReduxIndex = (state: ServiceState, reduxIndex: number) => {
    if (state.data && state.data.gidDataList) {
        for (let gidData of state.data.gidDataList) {
            if (gidData.companies) {
                for (let company of gidData.companies) {
                    if (company.accounts) {
                        for (let account of company.accounts) {
                            if (account.services) {
                                for (let service of account.services) {
                                    if (service.reduxIndex === reduxIndex) {
                                        return service;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        toggleService(state, action: PayloadAction<{reduxIndex: number, value: boolean}>) {
            const service = findServiceByReduxIndex(state, action.payload.reduxIndex);

            if (service) {
                service.select = action.payload.value;
            }
        },
        toggleCards(state, action: PayloadAction<{reduxIndex: number, value: boolean}>) {
            if (state.cards) {
                const card = state.cards.find(card => card.reduxIndex === action.payload.reduxIndex);

                if (card) {
                    card.select = action.payload.value;
                }
            }
        },
        savePaid(state, action: PayloadAction<{reduxIndex: number, value: number}>) {
            const service = findServiceByReduxIndex(state, action.payload.reduxIndex);

            if (service) {
                service.paid = action.payload.value;
            }
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            api.endpoints.getLkCardData.matchFulfilled,
            (state, { payload }) => {
                state.data = payload;
            }
        );
        builder.addMatcher(
            api.endpoints.getUserCardList.matchFulfilled,
            (state, { payload }) => {
                state.cards = payload;
            }
        );
    }
});

export const selectCards = (state: RootState) => state.service.cards;
export const selectServices = (state: RootState) => state.service.data;

export const {toggleService, toggleCards, savePaid} = serviceSlice.actions;

export default serviceSlice.reducer;