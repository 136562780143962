import React, {FC} from "react";
import {Counter, CounterAccount} from "../../redux/counter/counterTypes";
import {Alert, Box, Divider, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {useGetCounterHistoryQuery} from "../../core/api";
import {CounterInfoBlock} from "./CounterInfoBlock";

interface IProps {
    account?: CounterAccount;
    counter?: Counter;
}

export const CounterHistoryView: FC<IProps> = ({account, counter}) => {
    const {data, error, isLoading} = useGetCounterHistoryQuery({
        idCounter: counter?.id,
        idSchema: account?.idSchema,
        idGlobalAccount: account?.idGlobalAccount,
        idCompany: account?.idCompany,
        idAccount: account?.idAccount,
    });

    return account && counter && data ? (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                История показаний
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <CounterInfoBlock account={account} counter={counter} />

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Table size="small" aria-label="payment list">
                <TableBody>
                    {data.map((history, index) => (
                        <TableRow key={index}>
                            <TableCell style={{paddingLeft: 0}}>
                                {history.dateInd}
                            </TableCell>
                            <TableCell align="right" style={{paddingRight: 0, minWidth: 100}}>
                                {history.consume > 0 && (
                                    <span style={{color: 'gray', marginRight: 10}}>{`(+${history.consume})`}</span>
                                )}

                                {history.value}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {counter.transit > 0 && (
                <Alert severity="warning" style={{fontWeight: 700}}>
                    Заказ документов по данному лицевому счету доступен только
                    в абонентском отделе поставщика жилищно-коммунальной услуги
                </Alert>
            )}
        </Box>
    ) : null
}