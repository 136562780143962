import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react'
import {AxiosError, AxiosRequestConfig} from 'axios'
import client from "../client";
import {
    AdminUserListQuery,
    UserInfoResponse,
    AdminUserListResponse,
    AdminUserInfoResponse
} from "../redux/user/userTypes";
import {
    AdminChatMessageListResponse,
    AdminChatUserInfoResponse,
    AdminMessageListQuery,
    MessageListResponse,
    UserChatMessageListResponse
} from "../redux/user/messageTypes";
import {AdminPaymentListQuery, PaymentInfoResponse, PaymentListResponse} from "../redux/payment/paymentTypes";
import {
    ReceiptDetailsQuery,
    ReceiptDetailsResponse,
    ReceiptListQuery,
    ReceiptListResponse
} from "../redux/receipt/receiptTypes";
import {
    LkCardDataQuery,
    LkCardDataResponse,
    PayCompleteQuery,
    PayRegisterQuery,
    PayRegisterResponse,
    UserCardListResponse
} from "../redux/service/serviceTypes";
import {
    CounterHistoryValueQuery,
    CounterHistoryValueResponse,
    CounterResponse,
    RemoveCounterValueQuery,
    SaveCounterValueQuery
} from "../redux/counter/counterTypes";
import {
    CompanyListResponse,
    ConnectAccountQuery,
    ConnectGidQuery, LkCard,
    RemoveCardQuery,
    RenameCardQuery,
    RequestPinQuery
} from "../redux/card/cardTypes";
import {SchemaListResponse} from "../redux/schema/schemaTypes";
import {
    GetReportEmailQuery, GetReportEmailResponse,
    ReportOperationMonthListQuery,
    ReportOperationMonthListResponse, SendReportB3Query,
    SendReportF49ExQuery, UpdateReportEmailQuery
} from "../redux/report/reportTypes";

export interface ApiError {
    status: number | undefined;
    message: string | undefined;
    data: string | unknown;
}

const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }):
    BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
        }, unknown, ApiError
    > =>
        async ({ url, method, data, params }) => {
            try {
                let result;

                if (method === 'get') {
                    result = await client.get(url)

                    return {
                        data: result.response
                    }
                } else if (method === 'post') {
                    result = await client.post(url, data)

                    return {
                        data: result.response
                    }
                } else if (method === 'json') {
                    result = await client.json(url, data)

                    return {
                        data: result.response
                    }
                } else {
                    result = await client.client({ url: baseUrl + url, method, data, params })

                    return {
                        data: result.data.response
                    }
                }
            } catch (axiosError) {
                let err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        message: err.message,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }

export const api = createApi({
    reducerPath: 'api',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['UserInfo', 'UserChatMessage', 'AdminChatMessage', 'CounterValue', 'Service', 'Card', 'UserProfileEmail'],
    endpoints: (builder) => ({
        getUserInfo: builder.query<UserInfoResponse, void>({
            query: () => ({
                url: `/api/auth/info`,
                method: 'post'
            }),
            providesTags: ['UserInfo'],
            keepUnusedDataFor: 0.0001
        }),
        getUserProfileEmail: builder.query<string, void>({
            query: () => ({
                url: `/api/profile/email`,
                method: 'get'
            }),
            providesTags: ['UserProfileEmail'],
            keepUnusedDataFor: 0.0001
        }),
        getAdminMessageList: builder.query<MessageListResponse, AdminMessageListQuery>({
            query: (query) => ({
                url: `/api/admin/message/list`,
                method: 'post',
                data: {
                    limit: query.limit,
                    offset: query.offset,
                    auto: query.auto ? 1 : 0,
                    status: query.status
                }
            }),
            keepUnusedDataFor: 0.0001
        }),
        getAdminPaymentList: builder.query<PaymentListResponse, AdminPaymentListQuery>({
            query: (query) => ({
                url: `/api/admin/payment/list`,
                method: 'post',
                data: {
                    limit: query.limit,
                    offset: query.offset,
                    source: query.source,
                    schema: query.schema,
                    status: query.status,
                    dateBegin: query.dateBegin,
                    dateEnd: query.dateEnd,
                }
            }),
            keepUnusedDataFor: 0.0001
        }),
        getAdminPaymentInfo: builder.query<PaymentInfoResponse, number>({
            query: (query) => ({
                url: `/api/admin/payment/info`,
                method: 'post',
                data: {
                    id: query
                }
            })
        }),
        getAdminSchemaList: builder.query<SchemaListResponse, void>({
            query: () => ({
                url: `/api/admin/schema/list`,
                method: 'get'
            }),
        }),
        getAdminUserList: builder.query<AdminUserListResponse, AdminUserListQuery>({
            query: (query) => ({
                url: `/api/admin/user/list`,
                method: 'post',
                data: {
                    limit: query.limit,
                    offset: query.offset
                }
            }),
            keepUnusedDataFor: 0.0001
        }),
        getAdminUserInfo: builder.query<AdminUserInfoResponse, number>({
            query: (id) => ({
                url: `/api/admin/user/info`,
                method: 'post',
                data: { id }
            })
        }),
        getAdminUserCardList: builder.query<LkCard[], number>({
            query: (idUser) => ({
                url: `/api/admin/user/card/list`,
                method: 'post',
                data: { idUser }
            })
        }),
        getAdminChatUserInfo: builder.query<AdminChatUserInfoResponse, number>({
            query: (id) => ({
                url: `/api/admin/message/chat/user`,
                method: 'post',
                data: { id }
            })
        }),
        getAdminChatMessageList: builder.query<AdminChatMessageListResponse, number>({
            query: (id) => ({
                url: `/api/admin/message/chat`,
                method: 'post',
                data: { id }
            }),
            providesTags: ['AdminChatMessage'],
            keepUnusedDataFor: 0.0001
        }),
        getAdminCountNewMessage: builder.query<number, void>({
            query: () => ({
                url: `/api/admin/message/count_unread`,
                method: 'get'
            }),
            providesTags: ['AdminChatMessage'],
            keepUnusedDataFor: 0.0001
        }),
        saveReadAdminChatMessage: builder.mutation<void, {idChat: number, idMessage: number}>({
            query: (data) => ({
                url: `/api/admin/message/chat/save_read`,
                method: 'post',
                data
            }),
            invalidatesTags: ['AdminChatMessage']
        }),
        getUserChatMessageList: builder.query<UserChatMessageListResponse, void>({
            query: () => ({
                url: `/api/message/list`,
                method: 'post'
            }),
            providesTags: ['UserChatMessage'],
            keepUnusedDataFor: 0.0001
        }),
        getUserCountNewMessage: builder.query<number, void>({
            query: () => ({
                url: `/api/message/count_unread`,
                method: 'get'
            }),
            providesTags: ['UserChatMessage'],
            keepUnusedDataFor: 0.0001
        }),
        saveReadUserChatMessage: builder.mutation<void, number>({
            query: (maxId) => ({
                url: `/api/message/save_read`,
                method: 'post',
                data: {id: maxId}
            }),
            invalidatesTags: ['UserChatMessage']
        }),
        getUserCardList: builder.query<UserCardListResponse, void>({
            query: () => ({
                url: `/api/card/list`,
                method: 'post'
            }),
            transformResponse: (response: UserCardListResponse) => {
                let reduxIndex = 0;

                response.forEach(card => {
                    card.reduxIndex = reduxIndex++;
                    card.select = true;
                });

                return response;
            },
            providesTags: ['Service', 'Card'],
            keepUnusedDataFor: 0.0001
        }),
        getLkCardData: builder.query<LkCardDataResponse, LkCardDataQuery>({
            query: (idCards) => ({
                url: `/api/card/data`,
                method: 'post',
                data: {idCards}
            }),
            transformResponse: (response: LkCardDataResponse) => {
                let reduxIndex = 0;

                response.gidDataList.forEach(gidData => {
                    gidData.companies?.forEach(company => {
                        company.accounts?.forEach(account => {
                            account.services?.forEach(service => {
                                service.reduxIndex = reduxIndex++;
                                service.paid = service.fullTotal > 0 ? service.fullTotal : 0;
                                service.select = true;
                            });
                        });
                    });
                });

                return response;
            },
            providesTags: ['Service'],
            keepUnusedDataFor: 0.0001
        }),
        getReceiptList: builder.query<ReceiptListResponse, ReceiptListQuery>({
            query: (idCards) => ({
                url: `/api/receipt/list`,
                method: 'post',
                data: {idCards}
            }),
            providesTags: ['Service'],
            keepUnusedDataFor: 0.0001
        }),
        getReceiptDetails: builder.query<ReceiptDetailsResponse, ReceiptDetailsQuery>({
            query: (data) => ({
                url: `/api/receipt/data`,
                method: 'post',
                data: data
            })
        }),
        getReportOperationMonthList: builder.query<ReportOperationMonthListResponse, ReportOperationMonthListQuery>({
            query: (data) => ({
                url: `/api/report/months`,
                method: 'post',
                data: data
            })
        }),
        sendReportF49Ex: builder.mutation<boolean, SendReportF49ExQuery>({
            query: (data) => ({
                url: `/api/report/send/f49ex`,
                method: 'post',
                data: data
            })
        }),
        getReportEmail: builder.query<GetReportEmailResponse, GetReportEmailQuery>({
            query: (data) => ({
                url: `/api/report/email/data`,
                method: 'post',
                data: data
            }),
            keepUnusedDataFor: 0.0001,
        }),
        updateReportEmail: builder.mutation<GetReportEmailResponse, UpdateReportEmailQuery>({
            query: (data) => ({
                url: `/api/report/email/update`,
                method: 'json',
                data: data
            })
        }),
        sendReportB3: builder.mutation<boolean, SendReportB3Query>({
            query: (data) => ({
                url: `/api/report/send/b3`,
                method: 'post',
                data: data
            })
        }),
        getCounterList: builder.query<CounterResponse, number[]>({
            query: (idCards) => ({
                url: `/api/counter/list`,
                method: 'post',
                data: {idCards}
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['CounterValue']
        }),
        getCounterHistory: builder.query<CounterHistoryValueResponse, CounterHistoryValueQuery>({
            query: (data) => ({
                url: `/api/counter/history`,
                method: 'post',
                data
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['CounterValue']
        }),
        saveCounterValue: builder.mutation<void, SaveCounterValueQuery>({
            query: (data) => ({
                url: `/api/counter/append`,
                method: 'post',
                data
            }),
            invalidatesTags: ['CounterValue']
        }),
        removeCounterValue: builder.mutation<void, RemoveCounterValueQuery>({
            query: (data) => ({
                url: `/api/counter/remove`,
                method: 'post',
                data
            }),
            invalidatesTags: ['CounterValue']
        }),
        registerPayment: builder.mutation<PayRegisterResponse, PayRegisterQuery>({
            query: (data) => ({
                url: `/api/payment/register/${data.paySystem}`,
                method: 'json',
                data: data.cart
            })
        }),
        completePayment: builder.mutation<boolean, PayCompleteQuery>({
            query: (data) => ({
                url: `/api/payment/complete/${data.paySystem}`,
                method: 'post',
                data: {
                    id: data.orderId
                }
            }),
            invalidatesTags: ['Service']
        }),
        connectGid: builder.mutation<boolean, ConnectGidQuery>({
            query: (data) => ({
                url: `/api/card/connect/gid`,
                method: 'post',
                data
            }),
            invalidatesTags: ['Card']
        }),
        connectAccount: builder.mutation<boolean, ConnectAccountQuery>({
            query: (data) => ({
                url: `/api/card/connect/account`,
                method: 'post',
                data
            }),
            invalidatesTags: ['Card']
        }),
        getCompanyList: builder.query<CompanyListResponse, void>({
            query: () => ({
                url: `/api/account/companies`,
                method: 'post',
            })
        }),
        removeCard: builder.mutation<boolean, RemoveCardQuery>({
            query: (data) => ({
                url: `/api/card/remove`,
                method: 'post',
                data
            }),
            invalidatesTags: ['Card']
        }),
        renameCard: builder.mutation<boolean, RenameCardQuery>({
            query: (data) => ({
                url: `/api/card/rename`,
                method: 'post',
                data
            }),
            invalidatesTags: ['Card']
        }),
        requestPin: builder.mutation<boolean, RequestPinQuery>({
            query: (data) => ({
                url: `/api/message/request_pin`,
                method: 'post',
                data
            }),
            invalidatesTags: ['Card']
        }),
    }),
})

export const {
    useGetUserInfoQuery,
    useGetAdminMessageListQuery,
    useGetAdminPaymentListQuery,
    useGetAdminPaymentInfoQuery,
    useGetAdminSchemaListQuery,
    useGetAdminUserListQuery,
    useGetAdminUserInfoQuery,
    useGetAdminUserCardListQuery,
    useGetAdminChatUserInfoQuery,
    useGetAdminChatMessageListQuery,
    useGetAdminCountNewMessageQuery,
    useGetUserCountNewMessageQuery,
    useGetUserChatMessageListQuery,
    useGetUserCardListQuery,
    useGetLkCardDataQuery,
    useSaveReadUserChatMessageMutation,
    useSaveReadAdminChatMessageMutation,
    useGetReceiptListQuery,
    useGetReceiptDetailsQuery,
    useGetCounterListQuery,
    useGetCounterHistoryQuery,
    useSaveCounterValueMutation,
    useRemoveCounterValueMutation,
    useRegisterPaymentMutation,
    useCompletePaymentMutation,
    useConnectGidMutation,
    useConnectAccountMutation,
    useGetCompanyListQuery,
    useRemoveCardMutation,
    useRenameCardMutation,
    useRequestPinMutation,
    useGetReportOperationMonthListQuery,
    useSendReportF49ExMutation,
    useGetReportEmailQuery,
    useUpdateReportEmailMutation,
    useSendReportB3Mutation,
    useGetUserProfileEmailQuery
} = api

export const actionRefreshUserInfo = api.util.invalidateTags(['UserInfo']);
export const actionRefreshAdminChatMessageList = api.util.invalidateTags(['AdminChatMessage']);