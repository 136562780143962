import React, {FC, useState} from "react";
import {Counter, CounterAccount} from "../../redux/counter/counterTypes";
import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import {CounterInfoBlock} from "./CounterInfoBlock";
import {useSaveCounterValueMutation} from "../../core/api";
import {CustomTextField} from "../../components/CustomTextField";
import {parseDay} from "../../core/utils";

interface IProps {
    account?: CounterAccount;
    counter?: Counter;
    onAppend: () => void;
}

export const CounterAppendValueView: FC<IProps> = ({account, counter, onAppend}) => {
    const [success, setSuccess] = useState(false);
    const [saveCounterValueMutation, {error: saveError}] = useSaveCounterValueMutation();

    const [value, setValue] = useState<string>('');
    const [errorText, setErrorText] = useState<string>('');

    const handleChange = (event: string) => {
        if (event) {
            const newValue = parseInt(event);
            setValue(newValue.toString());

            if (newValue < parseInt(counter!.prevValue)) {
                setErrorText('Новое значение не может быть меньше текущего');
            } else {
                setErrorText('');
            }
        } else {
            setValue('');
            setErrorText('Не указано значение');
        }
    };

    const saveCounterValue = async () => {
        const result = await saveCounterValueMutation({
            idSchema: account?.idSchema,
            idGlobalAccount: account?.idGlobalAccount,
            idCompany: account?.idCompany,
            idAccount: account?.idAccount,
            idReceipt: account?.idReceipt,
            idCounter: counter?.id,
            transit: counter?.transit,
            value: value,
        });

        // @ts-ignore
        if (!result.error) {
            setSuccess(true);
            setTimeout(onAppend, 3000);
        }
    }

    const consume = () => {
        if (counter && value && counter.prevValue) {
            const curValue = parseInt(value);
            const prevValue = parseInt(counter.prevValue);

            if (curValue > prevValue) {
                return curValue - prevValue;
            }
        }

        return 0;
    }

    return account && counter ? (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Передача показаний
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <CounterInfoBlock account={account} counter={counter} />

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            {counter.activePeriod ? (
                <Stack spacing={3}>
                    <CustomTextField
                        label='Последнее показание'
                        value={parseInt(counter.prevValue).toString()} />


                    <CustomTextField
                        label='Новое показание'
                        type='number'
                        value={value}
                        onChange={handleChange}
                        error={errorText}
                    />

                    <Alert severity="info" style={{fontWeight: 700}}>
                        Расход: {consume()}
                    </Alert>

                    {saveError ? (
                        <Alert severity="error">
                            {saveError.message ? saveError.message : 'Ошибка сохранения данных'}
                        </Alert>
                    ) : null}

                    {success ? (
                        <Alert severity="success">
                            Показания успешно переданы
                        </Alert>
                    ) : (
                        <Button variant="contained"
                                color="success"
                                onClick={saveCounterValue}
                                disabled={value.length === 0 || errorText.length > 0}>
                            Передать показания
                        </Button>
                    )}
                </Stack>
            ) : (
                <Alert severity="warning" style={{fontWeight: 700}}>
                    Компания-поставщик коммунальной услуги принимает текущие показания индивидуальных приборов
                    учёта строго в период с {parseDay(counter.dayBeginPeriod)} по {parseDay(counter.dayEndPeriod)} число месяца
                    (в соответствии с п. 33 постановления Правительства от 06.05.2011 № 354).
                </Alert>
            )}

            <Alert severity="warning" style={{fontWeight: 700, marginTop: 20}}>
                В случае несвоевременного предоставления показаний индивидуального прибора учёта расчёт
                объёма коммунального ресурса будет произведён исходя из среднемесячного потребления или
                по нормативу (в соответствии с п. 59 постановления Правительства от 06.05.2011 № 354).
            </Alert>
        </Box>
    ) : null
}