import React from "react";
import {Box, Card, Divider, Grid, Link, Typography} from "@mui/material";

export const InfoMobilePage = () => (
    <Box>
        <Card style={{padding: 20, marginBottom: 20}}>
            <Typography variant="body2">
                Чтобы Личный кабинет был всегда под рукой установите на своё устройство мобильное приложение «Одно окно. ЖКХ». В нём, как и в web-версии Личного кабинета, можно в режиме онлайн:
            </Typography>
            <Typography variant="body2">
                •	находить и оплачивать услуги по номеру ЛС (ГИДу);
            </Typography>
            <Typography variant="body2">
                •	привязывать к одному аккаунту несколько адресов/ЛС (ГИДов), создавая группы, например: "Дом", "Родители" и т.д.;
            </Typography>
            <Typography variant="body2">
                •	передавать показания приборов учета и просматривать историю их изменений;
            </Typography>
            <Typography variant="body2">
                •	просматривать задолженность и начисления по услугам с учетом переданных показаний приборов учета;
            </Typography>
            <Typography variant="body2">
                •	производить оплаты по выбранным группам/ЛС/услугам, используя любые карты, выпущенные банками РФ;
            </Typography>
            <Typography variant="body2">
                •	просматривать историю операций;
            </Typography>
            <Typography variant="body2">
                •	настраивать рассылку на e-mail электронных копий фискальных чеков об оплате;
            </Typography>
            <Typography variant="body2">
                •	обращаться в техническую поддержку по возникающим вопросам через обратную связь.
            </Typography>
            <Typography variant="body2">
                Пользователям, зарегистрированным в Личном кабинете, для входа в приложение достаточно ввести ГИД и уже имеющийся пароль.
            </Typography>
            <Typography variant="body2">
                Чтобы начать пользоваться приложением «Одно окно. ЖКХ» на Android-устройстве достаточно:
            </Typography>
            <Typography variant="body2">
                1.	Скачать приложение из <Link href={'https://play.google.com/store/apps/details?id=info.odnookno.lk'} target={'_blank'}>Google Play</Link> и установить его на устройство;
            </Typography>
            <Typography variant="body2">
                2.	Зарегистрироваться по номеру моб. телефона через СМС-подтверждение, либо через адрес электронный почты, либо через аккаунт Google.
            </Typography>
            <Typography variant="body2">
                Также установить приложение на Android-устройство можно, скачав apk-файл на сайте Личного кабинета.
            </Typography>
            <Typography variant="body2">
                В данный момент приложение «Одно окно. ЖКХ» недоступно для установки на iOS-устройства. Воспользуйтесь web-версией Личного кабинета.
            </Typography>
        </Card>

        <Grid style={{ marginBottom: '20px' }}
              justifyContent="center"
              alignItems="center"
              container
              spacing={6}>
            <Grid item>
                <Card style={{maxWidth: 290, textAlign: 'center'}}>
                    <Typography variant='h6' style={{margin: 20}} fontWeight={700}>
                        Мобильное приложение для Android
                        <br/>
                        <br/>
                        Google Play
                    </Typography>
                    <Divider/>
                    <img style={{width: '246px'}} src={"/images/qr-android.gif"} alt={"Google Play"}/>
                </Card>
            </Grid>
            <Grid item>
                <Card style={{maxWidth: 290, textAlign: 'center'}}>
                    <Typography variant='h6' style={{margin: 20}} fontWeight={700}>
                        Мобильное приложение для Android
                        <br/>
                        <br/>
                        RuStore
                    </Typography>
                    <Divider/>
                    <img style={{width: '246px'}} src={"/images/qr-android-rustore.gif"} alt={"RuStore"}/>
                </Card>
            </Grid>
            <Grid item>
                <Card style={{maxWidth: 290, textAlign: 'center'}}>
                    <Typography variant='h6' style={{margin: 20}} fontWeight={700}>
                        Мобильная версия сайта для iOS
                        <br/>
                        <br/>
                        lk.odnookno.info
                    </Typography>
                    <Divider/>
                    <img style={{width: '246px'}} src={"/images/qr-site.gif"} alt={"https://lk.odnookno.info"}/>
                </Card>
            </Grid>
        </Grid>
    </Box>
)