import React, {useState} from "react";
import UserList from "./UserList";
import {useGetAdminUserListQuery} from "../../../core/api";

export const AdminUsersPage = () => {
    const [limit, setLimit] = useState(15)
    const [offset, setOffset] = useState(0)

    const { data, error, isLoading } = useGetAdminUserListQuery({limit, offset})

    return (
        <UserList isFetch={isLoading}
                  data={data?.items || []}
                  total={data?.total || 0}
                  error={error}
                  onLoad={(limit: number, offset: number) => {
                      setLimit(limit);
                      setOffset(offset);
                  }}/>
    )
}