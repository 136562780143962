import React, {FC, useEffect} from "react";
import CustomSelectField from "../../components/CustomSelectField";
import {useGetReportOperationMonthListQuery} from "../../core/api";
import {formatMonth} from "../../core/utils";

interface IProps {
    idSchema: number;
    idCompany: number;
    selectMonthId: number;
    onSelect: (monthId: number) => void
}
const ReportOperationMonthSelect: FC<IProps> = ({idSchema, idCompany, selectMonthId, onSelect}) => {

    const { data, error, isLoading } = useGetReportOperationMonthListQuery({idSchema, idCompany});

    useEffect(() => {
        if (data && data.length > 0) {
            onSelect(data[0].id);
        }
    }, [data])

    return (
        <CustomSelectField
            label={'Месяц'}
            value={selectMonthId}
            items={data?.map(((record) => ({
                    value: record.id, description: `${formatMonth(record.month)} ${record.year}`}
            ))) || []}
            onChange={onSelect} />
    )
}

export default ReportOperationMonthSelect;