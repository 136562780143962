import React, {FC, Fragment, useLayoutEffect} from "react";
import {useGetAdminChatMessageListQuery, useSaveReadAdminChatMessageMutation} from "../../../core/api";
import {LoadingPanel} from "../../LoadingPanel";
import {Alert} from "@mui/material";
import ChatItems from "../../common/messages/ChatItems";
import StatusPanel from "./StatusPanel";
import {UserChatMessage} from "../../../redux/user/messageTypes";

export interface IProps {
    chat: number;
}

export const AdminChatMessageList: FC<IProps> = ({chat}) => {
    const [saveReadAdminChatMessage, {error: saveReadError}] = useSaveReadAdminChatMessageMutation()

    useLayoutEffect(() => {
        const checkAndUpdateTimeout = setTimeout(() => checkAndUpdateNewMessages(data?.items || []), 3000)

        return () => clearTimeout(checkAndUpdateTimeout);
    })

    const checkAndUpdateNewMessages = async (messages: UserChatMessage[]) => {
        let maxId = 0;
        const idLastReadMessage = data?.idLastReadMessage || 0;

        messages
            // Сообщения от пользователя без даты прочтения
            .filter(message => message.sender === 0 && message.id > idLastReadMessage)
            // Получение максимального ID сообщения
            .forEach(message => maxId = Math.max(maxId, message.id));

        if (maxId > 0) {
            await saveReadAdminChatMessage({idChat: chat, idMessage: maxId});

            if (saveReadError) {
                console.log('Ошибка сохранения статуса прочтения новых сообщений');
            }
        }
    };

    const { data, error, isLoading } = useGetAdminChatMessageListQuery(chat)

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Fragment>
            <div style={{paddingLeft: "10px", paddingRight: "15px", paddingTop: "10px"}}>
                <ChatItems isAdmin
                           isFetch={isLoading}
                           data={data?.items || []}
                           total={data?.total || 0}
                           idLastReadMessage={data?.idLastReadMessage || 0} />
            </div>
            <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
                {/* @ts-ignore */}
                <StatusPanel id={chat} data={data?.items} />
            </div>
        </Fragment>
    )
}