import React from 'react';
import {Route} from 'react-router-dom';
import {BaseLayout} from "./BaseLayout";
import {UserDrawer} from "./menu/UserDrawer";

// @ts-ignore
export const BaseLayoutRoute = ({component: Component, ...rest}) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleCloseDrawer = () => {
        setMobileOpen(false);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Route {...rest} render={matchProps => (
            <BaseLayout mobileOpen={mobileOpen}
                        handleCloseDrawer={handleCloseDrawer}
                        handleDrawerToggle={handleDrawerToggle}
                        drawer={<UserDrawer handleCloseDrawer={handleCloseDrawer} />}>

                <Component {...matchProps} />
            </BaseLayout>
        )} />
    )
}