import React, {FC} from "react";
import {useGetReceiptDetailsQuery} from "../../core/api";
import {LoadingPanel} from "../LoadingPanel";
import {Alert, Divider, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {formatRUB} from "../../core/utils";
import {Receipt} from "../../redux/receipt/receiptTypes";

interface IProps {
    receipt?: Receipt;
}

export const PaymentData: FC<IProps> = ({receipt}) => {
    const { data, error, isLoading } = useGetReceiptDetailsQuery({
        idSchema: receipt?.idSchema,
        idReceipt: receipt?.idReceipt,
        idReceiptGroup: receipt?.idReceiptGroup
    });

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    return data ? (
        <>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Детализация чека
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Typography variant="body2">
                <span style={{fontWeight: 700}}>Номер: </span>{data.idReceiptGroup}
            </Typography>
            <Typography variant="body2">
                <span style={{fontWeight: 700}}>Дата: </span>{data.date}
            </Typography>
            <Typography variant="body2">
                <span style={{fontWeight: 700}}>Касса: </span>{data.agentDepName}
            </Typography>
            <Typography variant="body2">
                <span style={{fontWeight: 700}}>Общая сумма: </span>{formatRUB(data.paid)}
            </Typography>

            {data.companies.map((company, companyIndex) => (
                <div key={companyIndex}>
                    <Divider style={{marginTop: 10, marginBottom: 10}} />

                    <Typography variant="body2">
                        <span style={{fontWeight: 700}}>Компания: </span>{company.name}
                    </Typography>

                    {company.accounts.map((account, accountIndex) => (
                        <div key={accountIndex}>
                            <Divider style={{marginTop: 10, marginBottom: 10}} />

                            <Typography variant="body2">
                                <span style={{fontWeight: 700}}>№ ЛС: </span>{account.code}
                            </Typography>
                            {(account.payerLName || account.payerFName || account.payerMName) && (
                                <Typography variant="body2">
                                    <span style={{fontWeight: 700}}>Владелец ЛС: </span>{`${account.payerLName} ${account.payerFName} ${account.payerMName}`}
                                </Typography>
                            )}
                            <Typography variant="body2">
                                <span style={{fontWeight: 700}}>Адрес: </span>{account.address}
                            </Typography>

                            <Divider style={{marginTop: 10, marginBottom: 10}} />

                            <Table size="small" aria-label="payment list">
                                <TableBody>
                                    {account.services.map((service, serviceIndex) => (
                                        <TableRow key={serviceIndex}>
                                            <TableCell style={{paddingLeft: 0}}>
                                                {service.name}
                                            </TableCell>
                                            <TableCell align="right" style={{paddingRight: 0, minWidth: 100}}>
                                                {formatRUB(service.paid)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    ))}
                </div>
            ))}

        </>
    ) : null
}
