import React from "react";
import styles from '../../admin/messages/AdminChatSendPanel.module.scss'
import PropTypes from "prop-types";
import axios from "../../../client";
import {Alert, IconButton, Paper, TextField} from "@mui/material";
import {Send} from "@mui/icons-material";

class ChatSendPanel extends React.Component {
    state = {
        text: '',
        error: false,
        isSend: false
    };

    handlerChangeText = (e) => {
        this.setState({text: e.target.value});
    };

    handlerSendButton = () => {
        if (this.state.text.trim()) {
            this.setState({isSend: true}, this.send);
        } else {
            this.setState({error: "Не указан текст сообщения!"}, this.hideError);
        }
    };

    send = () => {
        const url = this.props.isAdmin ? '/api/admin/message/chat/answer' : '/api/message/create';
        const request = this.props.isAdmin ? {id: this.props.id, message: this.state.text} : {message: this.state.text};

        axios.post(url, request)
            .then((data) => {
                this.setState({text: '', isSend: false, error: false});
                this.props.onSend();
            })
            .catch((error) => {
                this.setState({error: error.message, isSend: false}, this.hideError);
            })
    };

    hideError = () => {
        setTimeout(() => {
            this.setState({error: false});
        }, 3000);
    };

    render() {
        return (
            <div style={{marginTop: "10px"}} >
                {this.state.error && <Alert severity="error" style={{marginBottom: "10px"}} >{this.state.error}</Alert>}
                <Paper style={{padding: "10px"}} className={styles.root}>
                    <TextField
                        id="outlined-multiline-static"
                        className={styles.input}
                        label="Новое сообщение"
                        value={this.state.text}
                        onChange={this.handlerChangeText}
                        multiline
                        fullWidth
                        rows="6"
                        variant="outlined"
                        disabled={this.state.isSend}
                    />
                    <IconButton color="primary"
                                className={styles.iconButton}
                                aria-label="send message"
                                onClick={this.handlerSendButton}
                                disabled={this.state.isSend}
                    >
                        <Send />
                    </IconButton>
                </Paper>
            </div>
        )
    }
}

ChatSendPanel.defaultProps = {
    id: null,
    isAdmin: false
};

ChatSendPanel.propTypes = {
    isAdmin: PropTypes.bool,
    id: PropTypes.any,
    onSend: PropTypes.func.isRequired
};

export default ChatSendPanel;