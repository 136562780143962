import React, {FC} from 'react';
import {useGetAdminUserCardListQuery} from "../../../../core/api";
import {LoadingPanel} from "../../../LoadingPanel";
import {Alert, Box} from "@mui/material";
import {AdminUserCardInfo} from "./AdminUserCardInfo";
import {CustomAccordion} from "../../../../components/CustomAccordion";

interface IProps {
    idUser: number;
}

export const AdminUserCardList: FC<IProps> = ({idUser}) => {

    const {data, error, isLoading} = useGetAdminUserCardListQuery(idUser);

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box>
            {data?.map(card => (
                <CustomAccordion key={card.id} id={card.id} label={card.name}>
                    <AdminUserCardInfo idCard={card.id} />
                </CustomAccordion>
            ))}
        </Box>
    )
}