import React, {useLayoutEffect} from "react";
import {LoadingPanel} from "../LoadingPanel";
import ChatItems from "../common/messages/ChatItems";
import ChatSendPanel from "../common/messages/ChatSendPanel";
import {Alert, Paper} from "@mui/material";
import {useGetUserChatMessageListQuery, useSaveReadUserChatMessageMutation} from "../../core/api";
import {UserChatMessage} from "../../redux/user/messageTypes";

export const FeedbackPage = () => {
    const [saveReadUserChatMessage, {error: saveReadError}] = useSaveReadUserChatMessageMutation();

    useLayoutEffect(() => {
        const checkAndUpdateTimeout = setTimeout(() => checkAndUpdateNewMessages(data?.messages || []), 3000);

        return () => clearTimeout(checkAndUpdateTimeout);
    })

    const checkAndUpdateNewMessages = async (messages: UserChatMessage[]) => {
        let maxId = 0;

        messages
            // Сообщения от ТП без даты прочтения
            .filter(message => message.sender === 1 && !message.dateRead)
            // Получение максимального ID сообщения
            .forEach(message => maxId = Math.max(maxId, message.id));

        if (maxId > 0) {
            await saveReadUserChatMessage(maxId);

            if (saveReadError) {
                console.log('Ошибка сохранения статуса прочтения новых сообщений');
            }
        }
    };

    const { data, error, isLoading, refetch} = useGetUserChatMessageListQuery()

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Paper style={{background: "lightgray"}}>
            <div style={{padding: "10px"}}>
                <ChatItems isFetch={isLoading}
                           data={data?.messages.slice().reverse() || []}
                           total={data?.messages.length || 0} />
                <ChatSendPanel onSend={refetch} />
            </div>
        </Paper>
    )
}