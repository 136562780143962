import React from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {Breadcrumbs, Chip, emphasize} from "@mui/material";
import {styled} from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";


const breadcrumbNameMap: { [key: string]: string; } = {
    '/': 'Главная',
    '/login': 'Авторизация',
    '/cards': 'Группы',
    '/cards/all': 'Все',
    '/profile': 'Профиль',
    '/payments': 'История платежей',
    '/feedback': 'Обратная связь',
    '/device': 'Приборы учета',
    '/info': 'Информация',
    '/info/contact': 'Контакты',
    '/info/pay_method': 'Способы оплаты',
    '/info/terms': 'Условия использования',
    '/info/privacy': 'Политика конфиденциальности',
    '/info/mobile': 'Мобильное приложение',
    '/admin': 'Панель администратора',
    '/admin/users': 'Пользователи',
    '/admin/payments': 'Платежи',
    '/admin/payment': 'Детализация платежа',
    '/admin/messages': 'Сообщения',
    '/admin/messages//chat': 'Сообщения',
};

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


export const RouterBreadcrumbs = () => {
    const routeUseLocation = useLocation();
    const pathNameList = routeUseLocation.pathname.split('/').filter(x => x);

    return pathNameList.length ? (
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} style={{marginBottom: "20px"}}>
            <StyledBreadcrumb
                component={RouterLink}
                to="/"
                label={breadcrumbNameMap['/']}
                icon={<HomeIcon fontSize="small" />}
            />

            {pathNameList.map((value, index) => {
                const last = index === pathNameList.length - 1;
                const to = `/${pathNameList.slice(0, index + 1).join('/')}`;

                return last ? (
                    <StyledBreadcrumb
                        component={RouterLink}
                        to={to}
                        key={to}
                        disabled={true}
                        label={breadcrumbNameMap[to] || value}
                    />
                ) : (
                    <StyledBreadcrumb
                        component={RouterLink}
                        to={to}
                        key={to}
                        label={breadcrumbNameMap[to] || value}
                    />
                );
            })}
        </Breadcrumbs>
    ) : null
}