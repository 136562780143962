import React, {FC} from "react";
import {IconButton, Stack, TableCell, TableRow, Typography} from "@mui/material";
import {formatPaidCommission, formatPositiveRUB, getCommission} from "../../../core/utils";
import EditIcon from "@mui/icons-material/Edit";
import {Service} from "../../../redux/service/serviceTypes";
import {ServiceSwitch} from "./ServiceSwitch";

export interface IProps {
    service: Service;
    onEditService: (service: Service) => void;
}

export const ServiceRowView: FC<IProps> = ({service, onEditService}) => {
    return (
        <TableRow>
            <TableCell style={{paddingLeft: 0}}>
                {service.name}
            </TableCell>
            <TableCell align="right">
                {formatPositiveRUB(service.total)}
            </TableCell>
            <TableCell align="right">
                {formatPositiveRUB(service.fullTotal - service.total)}
            </TableCell>
            <TableCell align="right" style={{width: 260, minWidth: 260}}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                        сумма,
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {formatPositiveRUB(service.select ? (service.paid + getCommission(service.paid, service.commissionPercent)) : 0)}
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                        в т.ч. комиссия
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {formatPaidCommission(service.select ? service.paid : 0, service.commissionPercent)}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="right" style={{paddingRight: 0, maxWidth: 70}}>
                <IconButton onClick={onEditService.bind(null, service)}>
                    <EditIcon />
                </IconButton>
            </TableCell>
            <TableCell align="right" style={{paddingRight: 0, maxWidth: 70}}>
                <ServiceSwitch service={service} />
            </TableCell>
        </TableRow>
    )
}