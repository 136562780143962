import React, {FC} from "react";
import {Box, Card, Divider, Stack, Typography} from "@mui/material";
import {CompanyView} from "./CompanyView";
import {formatRUB} from "../../../core/utils";
import {GidData} from "../../../redux/service/serviceTypes";
import ReportMenu from "../../docs/ReportMenu";

export interface IProps {
    data: GidData;
}

export const CardDetailsService: FC<IProps> = ({data}) => {
    const calcAmount = () => {
        let total = 0;
        let fullTotal = 0;

        data.companies?.forEach(company => {
            company.accounts.forEach(account => {
                account.services?.forEach(service => {
                    total += service.total;
                    fullTotal += service.fullTotal;
                });
            })
        });

        return { total, fullTotal };
    }

    const existCnCompany = () => {
        if (data.companies) {
            for (let i = 0; i < data.companies.length; i++) {
                if (data.companies[i].type === 'CN') {
                    return true
                }
            }
        }

        return false
    }

    const amount = calcAmount();
    const isGID = data.type === 0;

    // Отображать кнопку документы, только если есть ЦН компания (не транзит)
    const showReportButton = existCnCompany()

    return (
        <Card style={{marginBottom: 20}}>
            <Stack
                spacing={1.5}
                sx={{ paddingLeft: 3, paddingRight: 3, py: 2, bgcolor: 'background.default' }}
            >
                {isGID && (
                    <Typography fontWeight={700}>ГИД: {data.idGid}</Typography>
                )}

                <Typography variant="body2" color="text.secondary">
                    Долг (+/-): {formatRUB(amount.total)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Долг + текущее начисление: {formatRUB(amount.fullTotal)}
                </Typography>

                <Divider />

                {showReportButton && (
                    <>
                        <Box>
                            <ReportMenu idGid={data.idGid} idSchema={data.idSchema} companies={data.companies || []} />
                        </Box>
                        <Divider />
                    </>
                )}
            </Stack>

            {data.companies?.map(company => <CompanyView key={company.id} company={company}/>)}
        </Card>
    )
}