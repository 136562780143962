import React from "react";
import ChatSendPanel from "../../common/messages/ChatSendPanel";
import {AdminChatUserInfo} from "./AdminChatUserInfo";
import {Paper} from "@mui/material";
import {useParams} from "react-router-dom";
import {AdminChatMessageList} from "./AdminChatMessageList";
import {useAppDispatch} from "../../../core/hooks";
import {actionRefreshAdminChatMessageList} from "../../../core/api";

export const AdminChat = () => {
    const dispatch = useAppDispatch()

    const params = useParams();
    // @ts-ignore
    const chat = params.id;

    return (
        <Paper style={{background: "lightgray"}}>
            <AdminChatUserInfo chat={chat} />

            <div style={{minHeight: "300px"}}>
                <AdminChatMessageList chat={chat} />
            </div>

            <div style={{paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px"}}>
                <ChatSendPanel isAdmin id={chat} onSend={() => dispatch(actionRefreshAdminChatMessageList)} />
            </div>
        </Paper>
    )
}