import React, {FC} from "react";
import CustomSelectField from "../../../components/CustomSelectField";
import {useGetAdminSchemaListQuery} from "../../../core/api";

interface IProps {
    value: number;
    onChange: (value: number) => void;
}

const PaymentSchemaSelect: FC<IProps> = ({value, onChange}) => {
    const {data, error, isLoading} = useGetAdminSchemaListQuery();

    const items = [{value: -1, description: 'все'}];

    data?.map(schema => {
        items.push({value: schema.id, description: schema.name})
    });

    return (
        <CustomSelectField
            label={'Схема'}
            value={value}
            disabled={isLoading}
            items={items}
            onChange={onChange} />
    )
}

export default PaymentSchemaSelect;