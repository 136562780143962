import React, {FC} from "react";
import {printSource} from "./payment_utils";
import CustomSelectField from "../../../components/CustomSelectField";

interface IProps {
    value: number;
    onChange: (value: number) => void;
}

const PaymentSourceSelect: FC<IProps> = ({value, onChange}) => {
    return (
        <CustomSelectField
            label={'Источник'}
            value={value}
            items={[
                {value: -1, description: printSource(-1)},
                {value: 0, description: printSource(0)},
                {value: 1, description: printSource(1)},
            ]}
            onChange={onChange} />
    )
}

export default PaymentSourceSelect;