import React, {useState} from "react";
import {Alert, Card, Typography} from "@mui/material";
import {useGetCounterListQuery} from "../../core/api";
import {LoadingPanel} from "../LoadingPanel";
import {CounterView} from "./CounterView";
import {SheetsSide} from "../../components/SheetsSide";
import {Counter, CounterAccount} from "../../redux/counter/counterTypes";
import {CounterAppendValueView} from "./CounterAppendValueView";
import {CounterRemoveValueView} from "./CounterRemoveValueView";
import {CounterHistoryView} from "./CounterHistoryView";

export const DevicePage = () => {
    const [account, setAccount] = useState<CounterAccount | undefined>();
    const [counter, setCounter] = useState<Counter | undefined>();
    const [showCounterHistory, setShowCounterHistory] = useState(false);
    const [showAppendView, setShowAppendView] = useState(false);
    const [showRemoveView, setShowRemoveView] = useState(false);

    const openCounterHistoryView = (account: CounterAccount, counter: Counter) => {
        setAccount(account);
        setCounter(counter);
        setShowCounterHistory(true);
    }

    const closeCounterHistoryView = () => setShowCounterHistory(false);

    const openAppendValueView = (account: CounterAccount, counter: Counter) => {
        setAccount(account);
        setCounter(counter);
        setShowAppendView(true);
    }

    const closeAppendValueView = () => setShowAppendView(false);

    const openRemoveValueView = (account: CounterAccount, counter: Counter) => {
        setAccount(account);
        setCounter(counter);
        setShowRemoveView(true);
    }

    const closeRemoveValueView= () => setShowRemoveView(false);

    const {data, error, isLoading} = useGetCounterListQuery([]);

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Card style={{paddingBottom: 20}}>
            {data?.map((account, index) => (
                <div key={index}>
                    <div style={{padding: 20}}>
                        <Typography variant="body2">
                            <span style={{fontWeight: 700}}>№ ЛС: </span>{account.code}
                        </Typography>
                        {account.fio && (
                            <Typography variant="body2">
                                <span style={{fontWeight: 700}}>Владелец ЛС: </span>{account.fio}
                            </Typography>
                        )}
                        <Typography variant="body2">
                            <span style={{fontWeight: 700}}>Адрес: </span>{account.address}
                        </Typography>
                    </div>

                    {account.counters.map((counter, counterIndex) => (
                        <CounterView key={counterIndex}
                                     counter={counter}
                                     onShowHistory={openCounterHistoryView.bind(null, account, counter)}
                                     onAppendValue={openAppendValueView.bind(null, account, counter)}
                                     onRemoveValue={openRemoveValueView.bind(null, account, counter)} />
                    ))}
                </div>
            ))}

            <SheetsSide open={showCounterHistory} onClose={closeCounterHistoryView}>
                <CounterHistoryView account={account} counter={counter} />
            </SheetsSide>

            <SheetsSide open={showAppendView} onClose={closeAppendValueView}>
                <CounterAppendValueView account={account} counter={counter} onAppend={closeAppendValueView} />
            </SheetsSide>

            <SheetsSide open={showRemoveView} onClose={closeRemoveValueView}>
                <CounterRemoveValueView account={account} counter={counter} onRemove={closeRemoveValueView} />
            </SheetsSide>
        </Card>
    )
}