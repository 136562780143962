import React, {useState} from "react";
import MessageList from "./MessageList";
import {useGetAdminMessageListQuery} from "../../../core/api";
import {useHistory} from "react-router";

export const AdminMessagesPage = () => {
    const history = useHistory()
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [auto, setAuto] = useState(false)
    const [status, setStatus] = useState(-1)

    const { data, error, isLoading } = useGetAdminMessageListQuery({limit, offset, auto, status})

    return (
        <MessageList isFetch={isLoading}
                     data={data?.items || []}
                     total={data?.total || 0}
                     error={error}
                     history={history}
                     onLoad={(limit: number, offset: number, auto: boolean, status: number) => {
                         setLimit(limit);
                         setOffset(offset);
                         setAuto(auto);
                         setStatus(status);
                     }} />
    )
}