import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import React, {FC} from "react";
import {UserCard} from "../../redux/service/serviceTypes";
import {useRemoveCardMutation} from "../../core/api";

interface IProps {
    card: UserCard;
    onRemove: () => void;
}

export const CardRemoveView: FC<IProps> = ({card, onRemove}) => {
    const [removeCardMutation, {error: removeError}] = useRemoveCardMutation();

    const remove = async () => {
        const result = await removeCardMutation({
            idCard: card.id
        });

        // @ts-ignore
        if (!result.error) {
            onRemove();
        }
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Удалить группу
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <Alert severity="warning">
                    Вы действительно хотите группу <span style={{fontWeight: 700}}>{card.name}</span>?
                </Alert>

                {removeError ? (
                    <Alert severity="error">
                        {removeError.message ? removeError.message : 'Ошибка удаления группы'}
                    </Alert>
                ) : null}

                <Button variant="contained" color={'error'} onClick={remove}>
                    Удалить
                </Button>
            </Stack>
        </Box>
    )
}