import React, {FC} from "react";
import {Counter, CounterAccount} from "../../redux/counter/counterTypes";
import {Typography} from "@mui/material";

interface IProps {
    account: CounterAccount;
    counter: Counter;
}

export const CounterInfoBlock: FC<IProps> = ({account, counter}) => {
    return (
        <>
            <Typography variant="body2">
                <span style={{fontWeight: 700}}>Услуга: </span>{counter.service}
            </Typography>
            <Typography variant="body2">
                <span style={{fontWeight: 700}}>Псевдоним: </span>{counter.name}
            </Typography>
            <Typography variant="body2">
                <span style={{fontWeight: 700}}>№ прибора учёта: </span>{counter.code}
            </Typography>

            {counter.type && (
                <Typography variant="body2">
                    <span style={{fontWeight: 700}}>Тип: </span>{counter.type}
                </Typography>
            )}
        </>
    )
}