import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styles from '../../admin/messages/AdminChatItems.module.scss'
import {Card, CardContent, Grid, Typography} from "@mui/material";

class ChatItems extends React.Component {

    componentDidMount() {
        this.scrollBottom()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollBottom()
    }

    scrollBottom = () => {
        const anchor = document.getElementById('message-list-container');

        if (anchor) {
            anchor.scrollTo({ top: anchor.scrollHeight });
        }
    }

    render() {
        return (
            <Grid id={"message-list-container"} container spacing={1} style={{maxHeight: "500px", minHeight: "300px", overflowY: "scroll"}}>
                {this.props.data.map(this.renderItem)}
            </Grid>
        );
    }

    renderItem = (row) => {
        const isInMessage = row.sender === (this.props.isAdmin ? 0 : 1);
        
        return (
            <Fragment key={row.id}>
                {!isInMessage && <Grid item xs={1} />}
                <Grid item xs={11}>
                    <Card className={isInMessage ? styles.cardIn : styles.cardOut}>
                        <CardContent className={styles.content}>
                            {this.printUnread(isInMessage, row)}
                            <Typography className={styles.title} color="textSecondary" gutterBottom>
                                {row.dateAdd}
                                {this.printSender(row)}
                            </Typography>
                            <Typography variant="body2">
                                {row.message}
                                {/*{row.message.split(/\n/).map((s, index) => <p key={index}>{s}</p>)}*/}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {isInMessage && <Grid item xs={1} />}
            </Fragment>
        )
    };

    printUnread(isInMessage, row) {
        const {isAdmin} = this.props;

        // Если это входящее сообщение И
        if (isInMessage && (
            // Это админ и сообщение более, чем последнее прочитанное в разрезе пользователя
            (isAdmin && row.id > this.props.idLastReadMessage)
            // Или это пользователь и нет метки прочтения текущего сообщения
            || (!isAdmin && !row.dateRead)
        )) {
            return <div className={styles.unread} />
        }

        return null;
    }

    printSender(row) {
        const {isAdmin} = this.props;

        if (isAdmin) {
            if (row.senderName || row.idSender) {
                return <span className={styles.sender}>({row.senderName || row.idSender})</span>
            }
            if (row.auto) {
                return <span className={styles.sender}>(Автоматическое сообщение)</span>
            }

            return ` (${row.source === 1 ? 'Сайт' : 'Мобильное приложение'})`
        }

        return null;
    }
}

ChatItems.defaultProps = {
    isAdmin: false,
    idLastReadMessage: 0
};

ChatItems.propTypes = {
    isAdmin: PropTypes.bool,
    isFetch: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    idLastReadMessage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
};

export default ChatItems;