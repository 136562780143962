import React from 'react';
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import {history, store} from './core/store';
import './firebase'
import './index.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import {createRoot} from 'react-dom/client';
import {createTheme, StyledEngineProvider} from '@mui/material/styles';
import {ThemeProvider} from "@mui/styles";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers";
import 'moment/locale/ru'

const container = document.getElementById('root')!;
const root = createRoot(container);

const theme = createTheme({
    palette: {
        mode: 'light'
        // mode: 'dark'
    },
});

root.render(
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <ConnectedRouter history={history}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'ru'}>
                        <App/>
                    </LocalizationProvider>
                </ConnectedRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
