import * as React from "react";
import {DrawerMenuItemList} from "./DrawerMenuItemList";
import Divider from "@mui/material/Divider";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GroupIcon from "@mui/icons-material/Group";
import {useAppSelector} from "../../../core/hooks";
import {selectIsAdmin, selectIsSignedIn} from "../../../redux/user/userSlice";
import {FC} from "react";

export interface IProps {
    onClick?: () => void;
}

export const DrawerMenuAdminSection: FC<IProps> = ({onClick}) => {
    const isAdmin = useAppSelector(selectIsAdmin)
    const isSignedIn = useAppSelector(selectIsSignedIn)

    return isSignedIn && isAdmin ? (
        <>
            <Divider />
            <DrawerMenuItemList header={'Администрирование'} items={[
                {
                    text: 'Сообщения',
                    path: '/admin/messages',
                    icon: <FeedbackIcon/>,
                    onClick: onClick
                },
                {
                    text: 'Список платежей',
                    path: '/admin/payments',
                    icon: <AccountBalanceWalletIcon/>,
                    onClick: onClick
                },
                {
                    text: 'Пользователи',
                    path: '/admin/users',
                    icon: <GroupIcon/>,
                    onClick: onClick
                },
            ]}/>
        </>
    ) : null
}