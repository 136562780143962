import {FC} from "react";

interface IProps {
    idCard: number;
}

export const AdminUserPaymentInfo: FC<IProps> = ({idCard}) => {

    return (
        <>
            AdminUserPaymentInfo: {idCard}
        </>
    )
}