import React from "react";
import {Alert, Box, Button, Link, Stack} from "@mui/material";

export const ConfirmEmailFailPage = () => (
    <Box sx={{display: 'flex'}}>
        <Stack sx={{width: '100%', padding: 4}}
               spacing={2}
               direction="column"
               alignItems="center"
               justifyContent="space-between">

            <Alert severity="error" style={{marginBottom: "10px"}}>
                Ошибка подтверждения отправки электронных квитанций на E-Mail
            </Alert>

            <Link href={`/`} underline="none">
                <Button variant="contained" color="primary" size="small">
                    Закрыть
                </Button>
            </Link>
        </Stack>
    </Box>
)