import React, {useLayoutEffect} from 'react';
import {Route, Switch} from 'react-router'
import {Redirect} from "react-router-dom";
import {SignInScreen} from "./containers/auth/SignInScreen";
import Page404 from "./containers/Page404";
import {AdminUsersPage} from "./containers/admin/users/AdminUsersPage";
import {PaymentsPage} from "./containers/payments/PaymentsPage";
import {PaymentSuccessPage} from "./containers/payments/PaymentSuccessPage";
import {PaymentFailPage} from "./containers/payments/PaymentFailPage";
import {PaymentFinishPage} from "./containers/payments/PaymentFinishPage";
import {AdminPaymentsPage} from "./containers/admin/payments/AdminPaymentsPage";
import PaymentInfoPage from "./containers/admin/payments/info/PaymentInfoPage";
import {AdminMessagesPage} from "./containers/admin/messages/AdminMessagesPage";
import {InfoContactPage} from "./containers/info/InfoContactPage";
import {InfoPrivacyPage} from "./containers/info/InfoPrivacyPage";
import {InfoTermsPage} from "./containers/info/InfoTermsPage";
import {InfoShoppingPage} from "./containers/info/InfoShoppingPage";
import {DevicePage} from "./containers/device/DevicePage";
import {FeedbackPage} from "./containers/feedback/FeedbackPage";
import {ProfilePage} from "./containers/profile/ProfilePage";
import {CardPage} from "./containers/card/CardPage";
import {AdminChat} from "./containers/admin/messages/AdminChat";
import axios from "./client";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {BaseLayoutRoute} from "./containers/layout/BaseLayoutRoute";
import {useAppDispatch, useAppSelector} from "./core/hooks";
import {
    cleanUserInfo,
    selectUserChecked,
    selectIsAdmin,
    selectIsSignedIn,
    setUserChecked
} from "./redux/user/userSlice";
import {LoadingPanel} from "./containers/LoadingPanel";
import {actionRefreshUserInfo} from "./core/api";
import {CardDetailsPage} from "./containers/card/CardDetailsPage";
import {PaymentWebSuccessPage} from "./containers/payments/PaymentWebSuccessPage";
import {PaymentWebFailPage} from "./containers/payments/PaymentWebFailPage";
import {InfoMobilePage} from "./containers/info/InfoMobilePage";
import {AdminUserInfoPage} from "./containers/admin/users/info/AdminUserInfoPage";
import {ConfirmEmailSuccessPage} from "./containers/confirm/email/ConfirmEmailSuccessPage";
import {ConfirmEmailFailPage} from "./containers/confirm/email/ConfirmEmailFailPage";
import {SandboxPage} from "./containers/SandboxPage";
import {VkIdCallbackAuth} from "./containers/auth/VkIdCallbackAuth";

export const App = () => {
    const dispatch = useAppDispatch();

    const isAdmin = useAppSelector(selectIsAdmin)
    const isSignedIn = useAppSelector(selectIsSignedIn)
    const userChecked = useAppSelector(selectUserChecked)

    useLayoutEffect(() => {
        const authType = localStorage.getItem('AuthType');

        if (authType === 'JWT') {
            const authToken = localStorage.getItem('AuthToken');
            axios.setJWT(authToken);

            dispatch(actionRefreshUserInfo);
            dispatch(setUserChecked(true));
        } else {
            return firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                        user.getIdToken(false).then((idToken) => {
                            axios.setFirebaseToken(idToken);

                            dispatch(actionRefreshUserInfo);
                            dispatch(setUserChecked(true));
                        }).catch((error) => {
                            dispatch(setUserChecked(true));
                            console.log('Ошибка получения токена');
                        });
                    } else {
                        if (userChecked) {
                            dispatch(cleanUserInfo());
                        }

                        dispatch(setUserChecked(true));
                    }
                }
            );
        }
    }, [userChecked])

    if (!userChecked) {
        return <LoadingPanel />
    }

    return (
        <Switch>
            {/* Payment callbacks section for mobile */}
            <Route exact path="/payment/sberbank/success">
                <PaymentSuccessPage/>
            </Route>
            <Route exact path="/payment/sberbank/fail">
                <PaymentFailPage/>
            </Route>
            <Route exact path="/payment/sberbank/finish">
                <PaymentFinishPage/>
            </Route>

            {/* Payment callbacks section for web */}

            <Route exact path="/payment/sberbank/success/web">
                <PaymentWebSuccessPage/>
            </Route>
            <Route exact path="/payment/sberbank/fail/web">
                <PaymentWebFailPage/>
            </Route>

            {/* User section */}
            {/*{this.props.isSignedIn && <BaseLayoutRoute path="/" component={MainPage} />}*/}
            {isSignedIn && <BaseLayoutRoute path="/cards" exact component={CardPage} />}
            {isSignedIn && <BaseLayoutRoute path="/cards/:id" component={CardDetailsPage} />}
            {isSignedIn && <BaseLayoutRoute path="/device" component={DevicePage} />}
            {isSignedIn && <BaseLayoutRoute path="/profile" component={ProfilePage} />}
            {isSignedIn && <BaseLayoutRoute path="/feedback" component={FeedbackPage} />}
            {isSignedIn && <BaseLayoutRoute path="/payments" component={PaymentsPage} />}
            {isSignedIn && (
                <Route path="/login" exact>
                    <Redirect to={"/"} />
                </Route>
            )}
            {isSignedIn && (
                <Route path="/" exact>
                    <Redirect to={isAdmin ? "/admin/messages" : "/cards"} />
                </Route>
            )}

            {/* Admin section */}
            {/*{this.props.isAdmin && <BaseLayoutRoute path="/admin" exact component={AdminMainPage} />}*/}
            {isAdmin && <BaseLayoutRoute path="/admin/users" exact component={AdminUsersPage} />}
            {isAdmin && <BaseLayoutRoute path="/admin/users/:id" component={AdminUserInfoPage} />}
            {isAdmin && <BaseLayoutRoute path="/admin/payments" component={AdminPaymentsPage} />}
            {isAdmin && <BaseLayoutRoute path="/admin/payment/:id" component={PaymentInfoPage} />}
            {isAdmin && <BaseLayoutRoute path="/admin/messages" exact component={AdminMessagesPage} />}
            {isAdmin && <BaseLayoutRoute path="/admin/messages/:id" component={AdminChat} />}
            {isAdmin && (
                <Route path="/admin" exact>
                    <Redirect to="/admin/messages" />
                </Route>
            )}
            {isAdmin && (
                <Route path="/admin/payment" exact>
                    <Redirect to="/admin/payments" />
                </Route>
            )}

            {/* Public section */}
            <BaseLayoutRoute path="/info/contact" component={InfoContactPage} />
            <BaseLayoutRoute path="/info/privacy" component={InfoPrivacyPage} />
            <BaseLayoutRoute path="/info/terms" component={InfoTermsPage} />
            <BaseLayoutRoute path="/info/pay_method" component={InfoShoppingPage} />
            <BaseLayoutRoute path="/info/mobile" component={InfoMobilePage} />
            <BaseLayoutRoute path="/login" component={SignInScreen} />
            <BaseLayoutRoute path="/sandbox" component={SandboxPage} />
            <BaseLayoutRoute path="/auth/vk/callback" component={VkIdCallbackAuth} />

            <Route exact path="/confirm/email/success">
                <ConfirmEmailSuccessPage/>
            </Route>

            <Route exact path="/confirm/email/fail">
                <ConfirmEmailFailPage/>
            </Route>

            <Route path="/info" exact>
                <Redirect to="/" />
            </Route>

            {/* Route for 404 page */}
            <BaseLayoutRoute component={isSignedIn ? Page404 : SignInScreen}/>
        </Switch>
    );
}