import React, {FC, useState} from "react";
import {Counter, CounterAccount} from "../../redux/counter/counterTypes";
import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import {CounterInfoBlock} from "./CounterInfoBlock";
import {useRemoveCounterValueMutation} from "../../core/api";
import {CustomTextField} from "../../components/CustomTextField";

interface IProps {
    account?: CounterAccount;
    counter?: Counter;
    onRemove: () => void;
}

export const CounterRemoveValueView: FC<IProps> = ({account, counter, onRemove}) => {
    const [success, setSuccess] = useState(false);
    const [removeCounterValueMutation, {error: saveError}] = useRemoveCounterValueMutation();

    const removeCounterValue = async () => {
        const result = await removeCounterValueMutation({
            idSchema: account?.idSchema,
            idGlobalAccount: account?.idGlobalAccount,
            idCompany: account?.idCompany,
            idAccount: account?.idAccount,
            idReceipt: account?.idReceipt,
            idCounter: counter?.id,
            transit: counter?.transit,
        });

        // @ts-ignore
        if (!result.error) {
            setSuccess(true);
            setTimeout(onRemove, 3000);
        }
    }

    const consume = () => {
        if (counter && counter.curValue && counter.prevValue) {
            const curValue = parseInt(counter.curValue);
            const prevValue = parseInt(counter.prevValue);

            if (curValue > prevValue) {
                return curValue - prevValue;
            }
        }

        return 0;
    }

    return account && counter && counter.curValue ? (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Удаление текущих показаний
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <CounterInfoBlock account={account} counter={counter} />

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <CustomTextField
                    label="Последнее показание"
                    value={parseInt(counter.prevValue).toString()} />

                <CustomTextField
                    label="Новое показание"
                    value={parseInt(counter.curValue).toString()} />

                <Alert severity="info" style={{fontWeight: 700}}>
                    Расход: {consume()}
                </Alert>

                {saveError ? (
                    <Alert severity="error">
                        {saveError.message ? saveError.message : 'Ошибка сохранения данных'}
                    </Alert>
                ) : null}

                {success ? (
                    <Alert severity="success">
                        Показания успешно удалены
                    </Alert>
                ) : (
                    <Button variant="contained"
                            color="warning"
                            onClick={removeCounterValue}>
                        Удалить показания
                    </Button>
                )}
            </Stack>
        </Box>
    ) : null
}