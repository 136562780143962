import {makeStyles} from "@mui/styles";
import {useScrollTrigger, Zoom} from "@mui/material";
import React, {FC} from "react";

const useStyles = makeStyles(theme => ({
    zoom: {
        position: 'fixed',
        // @ts-ignore
        bottom: theme.spacing(2),
        // @ts-ignore
        right: theme.spacing(2),
    },
}));

interface IProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: any;
    selector: string;
}

export const ScrollTop: FC<IProps> = ({children, window, selector }) => {
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: any) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#' + selector);

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.zoom}>
                {children}
            </div>
        </Zoom>
    );
}