import React, {FC} from "react";
import {Box, Button} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {formatPositiveRUB} from "../../core/utils";

interface IProps {
    amount: number;
    text?: string;
    onClick: () => void;
}

export const PayButton: FC<IProps> = ({amount, text, onClick}) => {

    return amount > 0.00 ? (
        <Box style={{textAlign: "center", marginTop: 30, marginBottom: 20}}>
            <Button variant="contained"
                    color={'error'}
                    startIcon={<ShoppingCartIcon />}
                    style={{fontWeight: 700}}
                    onClick={onClick}>

                <div>
                    Оплатить {formatPositiveRUB(amount)}
                    <div style={{fontSize: '0.675rem'}}>
                        {text}
                    </div>
                </div>
            </Button>
        </Box>
    ) : null;
}