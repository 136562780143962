import * as React from "react";
import {DrawerMenuUserCard} from "./DrawerMenuUserCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {DrawerMenuItemList} from "./DrawerMenuItemList";
import {useAppSelector} from "../../../core/hooks";
import {selectIsSignedIn, selectUserInfo} from "../../../redux/user/userSlice";
import {FC} from "react";

export interface IProps {
    onClick?: () => void;
}

export const DrawerMenuHeaderSection: FC<IProps> = ({onClick}) => {
    const user = useAppSelector(selectUserInfo)
    const isSignedIn = useAppSelector(selectIsSignedIn)

    return isSignedIn ? (
        <DrawerMenuUserCard user={user}/>
    ) : (
        <DrawerMenuItemList header={'Пользователь'} items={[
            {
                text: 'Авторизация',
                path: '/login',
                icon: <AccountCircleIcon/>,
                onClick: onClick
            },
        ]}/>
    )
}