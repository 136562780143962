import React, {FC} from "react";
import {ServiceView} from "./ServiceView";
import {Box, Theme} from "@mui/material";
import {SxProps} from "@mui/system";
import {Service} from "../../../redux/service/serviceTypes";

export interface IProps {
    services: Service[];
    sx?: SxProps<Theme>;
    onEditService: (service: Service) => void;
}

export const ServiceListView: FC<IProps> = ({services, onEditService, sx}) => {
    return (
        <Box sx={sx} style={{paddingBottom: 20, paddingTop: 20}}>
            {services?.map(service => <ServiceView key={service.id} service={service} onEditService={onEditService} />)}
        </Box>
    )
}