import {
    Alert,
    Box,
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import React, {FC, useState} from "react";
import {useConnectAccountMutation, useGetCompanyListQuery} from "../../../core/api";
import {LoadingPanel} from "../../LoadingPanel";
import {Company} from "../../../redux/card/cardTypes";
import {CustomTextField} from "../../../components/CustomTextField";

interface IProps {
    onAppend: () => void;
}

export const ConnectAccountView: FC<IProps> = ({onAppend}) => {

    const [connectAccountMutation, {error: connectError}] = useConnectAccountMutation();

    const [companyIndex, setCompanyIndex] = useState<string>('');
    const [company, setCompany] = useState<Company>();
    const [account, setAccount] = useState<string>('');

    const { data, error, isLoading } = useGetCompanyListQuery();

    const handleChangeCompanyIndex = (event: SelectChangeEvent) => {
        setCompanyIndex(event.target.value);

        if (data) {
            setCompany(data.cfrCompanyList[parseInt(event.target.value)]);
        }
    };

    const connect = async () => {
        if (company) {
            const result = await connectAccountMutation({
                idSchema: company.idSchema,
                idCompany: company.idCompany,
                account
            });

            // @ts-ignore
            if (!result.error) {
                onAppend();
            }
        }
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Подключение ЛС
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>

                <FormControl>
                    <InputLabel id="company-label">Компания</InputLabel>
                    <Select
                        labelId="company-label"
                        id="company"
                        value={companyIndex}
                        onChange={handleChangeCompanyIndex}
                        label="Компания"
                    >
                        {data?.cfrCompanyList.map(((company, index) => (
                            <MenuItem key={index} value={index}>
                                <Stack>
                                    <Typography fontWeight={700} variant="body2">
                                        {company.cityName}
                                    </Typography>
                                    <Typography variant="body2">
                                        {company.companyName}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        )))}
                    </Select>
                </FormControl>

                {isLoading && <LoadingPanel/>}

                {error && (
                    // @ts-ignore
                    <Alert severity="error">{error}</Alert>)
                }

                <CustomTextField label={'Лицевой счёт'} value={account} onChange={setAccount} />

                {connectError ? (
                    <Alert severity="error">
                        {connectError.message ? connectError.message : 'Ошибка подключения'}
                    </Alert>
                ) : null}

                <Button variant="contained"
                        color="success"
                        onClick={connect}
                        disabled={account.length === 0 || !company}
                >
                    Подключить
                </Button>
            </Stack>
        </Box>
    )
}