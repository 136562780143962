export const printStatus = (status: number) => {
    switch (status) {
        case -1: return 'все';
        case 0: return 'Новый';
        case 1: return 'Сумма удержана';
        case 2: return 'Оплата проведена';
        case 3: return 'Авторизация отменена';
        case 4: return 'Возврат';
        case 6: return 'Авторизация отклонена';
        case 999: return 'Ошибка';
        default:
            return status?.toString();
    }
}

export const printSource = (source: number) => {
    switch (source) {
        case -1: return 'все';
        case 0: return 'Мобильное приложение';
        case 1: return 'Сайт';
        default:
            return source?.toString();
    }
}