import {Button, Card, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {SheetsSide} from "../../../components/SheetsSide";
import {ConnectAccountView} from "./ConnectAccountView";

export const CardConnectByAccount = () => {
    const [showAppendView, setShowAppendView] = useState(false);

    const openAppendValueView = () => setShowAppendView(true);
    const closeAppendValueView = () => setShowAppendView(false);

    return (
        <Card>
            <Stack spacing={1.5}
                   sx={{ paddingLeft: 3, paddingRight: 3, py: 2, bgcolor: 'background.default' }}
            >
                <Typography>
                    Вы можете подключить лицевой счет. Для этого нужно выбрать поставщика из списка доступных
                    поставщиков и указать номер лицевого счета.
                </Typography>
                <Button variant="contained" color={'success'} onClick={openAppendValueView}>Подключить ЛС</Button>
            </Stack>

            <SheetsSide open={showAppendView} onClose={closeAppendValueView}>
                <ConnectAccountView onAppend={closeAppendValueView} />
            </SheetsSide>
        </Card>
    )
}