import React, {FC} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {randomId} from "../core/utils";

interface ISelectItem {
    value: number;
    description: string;
}

interface IProps {
    label: string;
    value: number;
    disabled?: boolean;
    items: ISelectItem[];
    onChange: (value: number) => void;
}

const CustomSelectField: FC<IProps> = ({label, value, disabled, items, onChange}) => {
    const id = `select-${randomId()}`;
    const labelId = `select-label-${randomId()}`;

    const handleChange = (event: any) => onChange(parseInt(event.target.value));

    return (
        <FormControl size="small" style={{minWidth: 150, textAlign: 'left'}} disabled={disabled}>
            <InputLabel id={labelId}>
                {label}
            </InputLabel>
            <Select
                id={id}
                labelId={labelId}
                value={value}
                onChange={handleChange}
                label={label}
            >
                {items.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                        {item.description}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default CustomSelectField;