import {Button, Card, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {SheetsSide} from "../../../components/SheetsSide";
import {ConnectGidView} from "./ConnectGidView";
import {RequestPinView} from "./RequestPinView";

export const CardConnectByGid = () => {
    const [showAppendView, setShowAppendView] = useState(false);
    const [showRequestPinView, setShowRequestPinView] = useState(false);

    const openAppendValueView = () => setShowAppendView(true);
    const closeAppendValueView = () => setShowAppendView(false);

    const openRequestPinView = () => {
        closeAppendValueView();
        setShowRequestPinView(true);
    }
    const closeRequestPinView = () => setShowRequestPinView(false);

    return (
        <Card>
            <Stack
                spacing={1.5}
                sx={{ paddingLeft: 3, paddingRight: 3, py: 2, bgcolor: 'background.default' }}
            >
                <Typography>
                    Вы можете оплачивать коммунальные услуги по единой квитанции. Для этого необходимо подключить ГИД,
                    который указан на квитанции.
                </Typography>
                <Button variant="contained" color={'success'} onClick={openAppendValueView}>Подключить ГИД</Button>
            </Stack>

            <SheetsSide open={showAppendView} onClose={closeAppendValueView}>
                <ConnectGidView onAppend={closeAppendValueView} onRequestPin={openRequestPinView} />
            </SheetsSide>

            <SheetsSide open={showRequestPinView} onClose={closeRequestPinView}>
                <RequestPinView onRequest={closeRequestPinView} />
            </SheetsSide>
        </Card>
    )
}