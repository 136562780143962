import React from "react";
import styles from './InfoPrivacyPage.module.scss'
import {Typography} from "@mui/material";

export const InfoPrivacyPage = () => (
    <div className={styles.content}>

        <Typography variant="h5">
            Политика конфиденциальности ООО «Единый платежный агент»
        </Typography>

        <Typography variant="h6" marginTop={2}>
            1. Термины и определения
        </Typography>

        <Typography variant="body2">
            Авторизация – идентификация Пользователя с помощью его логина и пароля при предоставлении доступа к Личному
            кабинету.
        </Typography>
        <Typography variant="body2">
            Акцепт – в соответствии со статьей 438 Гражданского кодекса РФ, безусловным принятием (Акцептом) условий
            настоящей Политики конфиденциальности считается выбор пункта, подтверждающего согласие с условиями Политики
            конфиденциальности при первом обращении Пользователя в Личный кабинет. После совершения Акцепта Политики
            конфиденциальности, Пользователь считается принявшим безоговорочно и в полном объеме условия настоящей
            Политики конфиденциальности.
        </Typography>
        <Typography variant="body2">
            Банк-эквайер – кредитная организация, обеспечивающая переводы денежных средств в пользу поставщиков услуг с
            использованием банковских карт Пользователя, посредством Личного кабинета.
        </Typography>
        <Typography variant="body2">
            Банк-эмитент – кредитная организация, осуществляющая выпуск банковских карт, предназначенных для совершения
            держателем карт операций с денежными средствами, находящимися у эмитента, и расчетное обслуживание счетов
            держателей карт.
        </Typography>
        <Typography variant="body2">
            Интерфейс Личного кабинета – совокупность экранных форм, появляющихся на экране Устройства Пользователя
            после входа в Личный кабинет и позволяющих работать с Личным кабинетом: создавать, отправлять и получать
            электронные документы и сообщения, а также просматривать информацию, размещенную в Личном кабинете.
        </Typography>
        <Typography variant="body2">
            Логин – регистрационное имя, используемое Пользователем в качестве идентификатора для входа в Личный
            кабинет, для регистрации.
        </Typography>
        <Typography variant="body2">
            Пароль – секретная последовательность символов, используемая Пользователем в качестве идентификатора для
            входа в Личный кабинет.
        </Typography>
        <Typography variant="body2">
            Пользователь – физическое лицо, обладающее полной дееспособностью в соответствии с законодательством РФ,
            использующее Личный кабинет на Устройстве и акцептовавшее Публичную оферту по предоставлению права
            использования Личного кабинета.
        </Typography>
        <Typography variant="body2">
            ГИД – глобальный идентификатор (глобальный идентификационный номер) Пользователя. Личный кабинет –
            веб-ресурс (веб-сайт) «Одно окно. ЖКХ», предоставляющий доступ
        </Typography>
        <Typography variant="body2">
            Пользователю к информации по его Лицевому счету (ГИДу), а также доступ к Услугам.
        </Typography>
        <Typography variant="body2">
            Профиль – раздел Личного кабинета, доступный Пользователю после прохождения процедуры Регистрации,
            позволяющий Пользователю управлять своей учетной записью и осуществлять действия, направленные на
            использование функциональных возможностей Личного кабинета.
        </Typography>
        <Typography variant="body2">
            Услуги – услуги по предоставлению доступа Пользователю к Личному кабинету, просмотру информации,
            содержащейся в Личном кабинете, а также оплаты в пользу поставщиков услуг с использованием банковских карт
            посредством Личного кабинета.
        </Typography>
        <Typography variant="body2">
            Устройство – техническое средство, предоставляющее доступ к Личному кабинету и имеющее техническую
            возможность доступа к сети Интернет, посредством которого Пользователь регистрируется в Личном кабинете.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            2. Общие положения
        </Typography>

        <Typography variant="body2">
            Настоящая Политика конфиденциальности (далее — Политика) применяется к информации,
            которая была/может быть получена ООО «Единый платежный агент» в результате использования Личного кабинета
            Пользователем.
        </Typography>
        <Typography variant="body2">
            Используя Личный кабинет, Пользователь:
        </Typography>
        <Typography variant="body2">
            • соглашается с условиями Политики в полном объеме без каких-либо изъятий и
            ограничений со стороны Пользователя;
        </Typography>
        <Typography variant="body2">
            • свободно, своей волей и в своем интересе дает свое конкретное, информированное и
            сознательное согласие (разрешает) ООО «Единый платежный агент» обрабатывать с использованием средств
            автоматизации информацию Пользователя в целях, предусмотренных Политикой, в том числе, на передачу
            информации третьим лицам в случаях, перечисленных в Политике.
        </Typography>
        <Typography variant="body2">
            Если Пользователь не согласен с условиями Политики, ему следует незамедлительно
            прекратить любое использование Личного кабинета.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            3. Понятие и цели использования информации Пользователей
        </Typography>

        <Typography variant="body2">
            Информация Пользователей собирается ООО «Единый платежный агент» в целях:
        </Typography>
        <Typography variant="body2">
            • осуществления технического управления;
        </Typography>
        <Typography variant="body2">
            • проведения анализа и улучшения работы Личного кабинета;
        </Typography>
        <Typography variant="body2">
            • предоставления Пользователю доступа к информации, размещенной для
        </Typography>
        <Typography variant="body2">Пользователя в Личном кабинете (в том числе, об оплате услуг);
        </Typography>
        <Typography variant="body2">
            • предоставления информации, введенной Пользователем в Личном кабинете, в целях
            осуществления операций по оплате с использованием Личного кабинета;
        </Typography>
        <Typography variant="body2">
            • отправки на номер телефона и/или адрес электронной почты Пользователя
            информационных и/или рекламных сообщений об услугах и мероприятиях,
            оказываемых/проводимых ООО «Единый платежный агент» и/или партнерами (контрагентами) ООО «Единый платежный
            агент», а также PUSH-уведомлений (короткое текстовое уведомление, передаваемое на Устройство Пользователя с
            использованием технологий Apple Push Notification Service (для операционной системы iOS) либо Google Cloud
            Messaging (для операционной системы Android OS);
        </Typography>
        <Typography variant="body2">
            • отправки на электронный адрес Пользователя информации (чека/квитанции) в
            соответствии с требованиями законодательства РФ о применении контрольно-кассовой техники.
        </Typography>
        <Typography variant="body2">
            В Политике под информацией Пользователя понимается:
        </Typography>
        <Typography variant="body2">
            • авторизационные данные, которые Пользователь самостоятельно предоставляет ООО
            «Единый платежный агент» при получении доступа к Личному кабинету (номер телефона используемый, в том числе
            в качестве логина для входа в Личный кабинет, пароль и пр.);
        </Typography>
        <Typography variant="body2">
            • технические данные, автоматически передаваемые Личным кабинетом в процессе его
            использования с помощью установленного на устройстве Пользователя программного обеспечения (в том числе, но
            не ограничиваясь: версия операционной системы, сведения об устройстве, с которого осуществляется доступ);
        </Typography>
        <Typography variant="body2">
            • иная информация, которую Пользователь самостоятельно предоставляет ООО «Единый
            платежный агент» в процессе использования Личного кабинета, в том числе в целях отправки рекламных и/или
            информационных сообщений, PUSH-уведомлений, информации (чека/квитанции) в соответствии с требованиями
            законодательства РФ о применении контрольно-кассовой техники или которая размещается в Личном кабинете для
            предоставления Пользователю (в том числе, но не ограничиваясь: номер телефона, адрес электронной почты,
            номер лицевого счета, показания счетчиков).
        </Typography>
        <Typography variant="body2">
            При использовании информации Пользователя ООО «Единый платежный агент»
            руководствуется Политикой, другими внутренними документами ООО «Единый платежный агент», а также применимым
            действующим законодательством РФ.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            4. Обеспечение конфиденциальности информации Пользователей
        </Typography>

        <Typography variant="body2">
            ООО «Единый платежный агент» принимает необходимые организационные и технические
            меры для защиты информации Пользователя от неправомерного или случайного доступа к ней, уничтожения,
            изменения, блокирования, а также от иных неправомерных действий. В частности, к мерам относится: ограничение
            доступа третьих лиц, в том числе к системам, в которых могут обрабатываться данные; внутренняя проверка
            процессов обработки данных и мер безопасности; использование защищенных протоколов при передаче по каналам
            связи, при необходимости.
        </Typography>
        <Typography variant="body2">
            Обработка информации Пользователя в ООО «Единый платежный агент» осуществляется на основе принципов:
        </Typography>
        <Typography variant="body2">
            • Законности и справедливости основы обработки информации Пользователя, законности
            целей и способов обработки информации;
        </Typography>
        <Typography variant="body2">
            • Соответствия целей обработки информации Пользователя целям, заранее определенным и
            заявленным ООО «Единый платежный агент» при сборе информации Пользователя;
        </Typography>
        <Typography variant="body2">
            • Соответствия способов обработки информации Пользователя способам, заранее
            определенным и заявленным ООО «Единый платежный агент» при сборе информации Пользователя;
        </Typography>
        <Typography variant="body2">
            • Соответствия содержания и объема обрабатываемой информации Пользователя содержанию
            и объему, заранее определенным и заявленным ООО «Единый платежный агент» при сборе информации Пользователя;
        </Typography>
        <Typography variant="body2">
            • Обеспечения точности, достаточности и актуальности информации Пользователя по отношению к целям их обработки;
        </Typography>
        <Typography variant="body2">
            • Недопустимости избыточности обрабатываемой информации Пользователя по отношению к заявленным целям их обработки;
        </Typography>
        <Typography variant="body2">
            • Недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих информацию Пользователя;
        </Typography>
        <Typography variant="body2">
            • Обеспечения безопасности информации Пользователя.
        </Typography>
        <Typography variant="body2">
            При определении состава обрабатываемой информации Пользователя ООО «Единый платежный
            агент» руководствуется минимально необходимым составом информации для достижения целей такой обработки.
        </Typography>
        <Typography variant="body2">
            Хранение информации Пользователя осуществляется в форме, позволяющей определить
            субъекта, предоставившего информацию ООО «Единый платежный агент», в течение периода времени, не
            превышающего период, необходимый для достижения цели обработки информации, если срок хранения информации
            Пользователя не установлен федеральным законом, договором, стороной которого является Пользователь,
            предоставивший информацию ООО «Единый платежный агент».
        </Typography>
        <Typography variant="body2">
            Пользователю настоятельно рекомендуется исключать доступ третьих лиц к логину и
            паролю, используемых для входа в Личный кабинет, а также в отношении другой информации Пользователя, которая
            может быть использована третьими лицами в корыстных/противозаконных целях (например, номер лицевого счета
            или ГИД).
        </Typography>
        <Typography variant="body2">
            ООО «Единый платежный агент» не несет ответственность в случае получения третьими
            лицами доступа к информации Пользователя (в том числе логину, паролю или номеру лицевого счета) со стороны
            или по вине Пользователя, а также, если такое получение (раскрытие) привело к негативным последствиям для Пользователя.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            5. Предоставление информации Пользователей третьим лицам
        </Typography>

        <Typography variant="body2">
            ООО «Единый платежный агент» вправе предоставлять информацию Пользователей третьим
            лицам для достижения/ обеспечения целей, указанных в Политике при соблюдении данными лицами условий
            Политики. ООО «Единый платежный агент» вправе передавать информацию Пользователей третьим лицам также в
            случаях, не связанных с достижением/обеспечением целей, указанных в Политике, а именно:
        </Typography>
        <Typography variant="body2">
            • Пользователь выразил дополнительное согласие на такие действия;
        </Typography>
        <Typography variant="body2">
            • В рамках соблюдения действующего законодательства РФ, судебных решений или
            исполнения законных требований государственных органов;
        </Typography>
        <Typography variant="body2">
            • Для устранения технических сбоев или проблем безопасности;
        </Typography>
        <Typography variant="body2">
            • При оказании услуг по безопасности ООО «Единый платежный агент» третьими
            лицами, установка, настройка и техническое обслуживание средств защиты, в т.ч. криптографических средств
            защиты (если необходимо), тестирование на уязвимости/анализ защищенности и др.
        </Typography>
        <Typography variant="body2">
            • Для защиты прав и законных интересов ООО «Единый платежный агент»/Пользователя в
            рамках, допускаемых действующим законодательством РФ.
        </Typography>

        <Typography variant="h6" marginTop={2}>
            6. Заключительные положения
        </Typography>

        <Typography variant="body2">
            Отношения между ООО «Единый платежный агент» и Пользователем, возникающие в связи с
            применением Политики, регулируются настоящими условиями Политики, а также действующим законодательством РФ.
        </Typography>
        <Typography variant="body2">
            Действующая редакция Политики размещена в соответствующих репозиториях.
        </Typography>
        <Typography variant="body2">
            Политика может быть изменена или прекращена ООО «Единый платежный агент» в
            одностороннем порядке без предварительного уведомления Пользователя и без выплаты какой-либо компенсации в
            связи с этим. Новая редакция Политики вступает в силу с момента ее размещения ООО «Единый платежный агент»,
            если иное не предусмотрено новой редакцией Политики.
        </Typography>

    </div>
)