import React, {FC, useLayoutEffect} from "react";
import CustomSelectField from "../../components/CustomSelectField";
import {Account} from "../../redux/service/serviceTypes";

interface IProps {
    selectAccountId: number;
    disabled?: boolean;
    accounts: Account[];
    onSelect: (accountId: number) => void
}
const ReportAccountSelect: FC<IProps> = ({selectAccountId, accounts, onSelect, disabled}) => {

    useLayoutEffect(() => {
        if (accounts.length > 0) {
            onSelect(accounts[0].id)
        }
    }, [accounts])

    return (
        <CustomSelectField
            label={'ЛС'}
            value={selectAccountId}
            items={accounts.map(((account) => ({
                    value: account.id, description: account.code}
            )))}
            disabled={disabled}
            onChange={onSelect} />
    )
}

export default ReportAccountSelect;