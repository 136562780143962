import firebase from 'firebase/compat/app';

// Configure Firebase.
const config = {
    apiKey: "AIzaSyA6NKjz5X578UGcWr5K4OvNKyVOe_qdhac",
    authDomain: "lk-odnookno-info.firebaseapp.com",
    databaseURL: "https://lk-odnookno-info.firebaseio.com",
    projectId: "lk-odnookno-info",
    storageBucket: "lk-odnookno-info.appspot.com",
    messagingSenderId: "987683610311",
    appId: "1:987683610311:web:ffb832fc1341c8dd4fa8e2",
    measurementId: "G-07K34JB3K6"
};

firebase.initializeApp(config);