import React, {FC} from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {Button} from "@mui/material";
import client from "../../../client";
import {isBlank} from "../../../core/utils";

interface IProps {
    schema: number;
    source: number;
    status: number;
    dateBegin: string | null;
    dateEnd: string | null;
}

const PaymentExportForm: FC<IProps> = ({schema, source, status, dateBegin, dateEnd}) => {
    const onSubmit = () => {
        if (isBlank(dateBegin) || isBlank(dateEnd)) {
            alert('Необходимо указать дату начала и окончания для экспорта данных!');
            return;
        }

        client.client({
            url: '/api/admin/payment/list/export',
            method: 'POST',
            data: client.stringify({
                schema,
                source,
                status,
                dateBegin,
                dateEnd
            }),
            responseType: 'blob',
        }).then((response) => {
            const header = response.headers['content-disposition'];
            const data = header.match(/attachment; filename="(.*)"/);
            const filename = data ? data[1] : 'export.csv';

            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    return (
        <Button variant="outlined" startIcon={<SaveAltIcon />} onClick={onSubmit}>
            Экспорт
        </Button>
    )
}

export default PaymentExportForm;