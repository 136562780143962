import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {
    Alert, IconButton,
    LinearProgress,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead, TablePagination,
    TableRow
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import {ContactPage} from "@mui/icons-material";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

class UserList extends React.Component {
    state = {
        page: 0,
        rowsPerPage: 15
    };

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        const {rowsPerPage, page} = this.state;
        this.props.onLoad(rowsPerPage, page * rowsPerPage);
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage}, this.refresh);
    };

    handleChangeRowsPerPage = event => {
        let rowsPerPage = parseInt(event.target.value, 10);
        this.setState({page: 0, rowsPerPage: rowsPerPage}, this.refresh);
    };

    render() {
        return (
            <TableContainer component={Paper}>
                <Table className={useStyles.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ИД</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Телефон</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.isFetch ? (
                            <TableRow>
                                <TableCell colSpan={5} align={'center'}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <Fragment>
                                {this.props.error && (
                                    <TableCell colSpan={5} align={'center'}>
                                        <Alert severity="error">{this.props.error}</Alert>
                                    </TableCell>
                                )}

                                {this.props.data.map(row => (
                                    <TableRow hover key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.phone}</TableCell>
                                        <TableCell>
                                            <Link to={`/admin/users/${row.id}`}>
                                                <IconButton aria-label="Показать детали" color="inherit">
                                                    <ContactPage />
                                                </IconButton>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 30, 50, { label: 'Все', value: this.props.total }]}
                                colSpan={4}
                                count={this.props.total}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Записей на странице' },
                                    native: true,
                                }}
                                labelRowsPerPage={'Записей на странице'}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }
}

UserList.propTypes = {
    isFetch: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    onLoad: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired
};

export default UserList;