import React, {FC} from "react";
import {Stack, Typography} from "@mui/material";
import {AccountView} from "./AccountView";
import {Company} from "../../../redux/service/serviceTypes";

export interface IProps {
    company: Company;
}

export const CompanyView: FC<IProps> = ({company}) => (
    <Stack>
        <Typography variant="body2" style={{paddingLeft: 24, paddingRight: 24}}>
            <span style={{fontWeight: 700}}>Компания: </span>{company.name}
        </Typography>

        {company.accounts.map(account => <AccountView key={account.id} account={account}/>)}
    </Stack>
)