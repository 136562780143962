import * as React from "react";
import {FC} from "react";
import {DrawerMenuItemList} from "./DrawerMenuItemList";
import Divider from "@mui/material/Divider";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HomeIcon from "@mui/icons-material/Home";
import RemoveFromQueueIcon from "@mui/icons-material/RemoveFromQueue";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import firebase from "firebase/compat/app";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {cleanUserInfo, selectIsAdmin, selectIsSignedIn} from "../../../redux/user/userSlice";

export interface IProps {
    onClick?: () => void;
}

export const DrawerMenuUserSection: FC<IProps> = ({onClick}) => {
    const history = useHistory();
    const isAdmin = useAppSelector(selectIsAdmin)
    const isSignedIn = useAppSelector(selectIsSignedIn)
    const dispatch = useAppDispatch();

    return isSignedIn ? (
        <>
            <Divider />
            <DrawerMenuItemList header={isAdmin && 'Пользователь'} items={[
                {
                    text: 'Группы',
                    path: '/cards',
                    icon: <HomeIcon/>,
                    onClick: onClick
                },
                {
                    text: 'Приборы учета',
                    path: '/device',
                    icon: <RemoveFromQueueIcon/>,
                    onClick: onClick
                },
                {
                    text: 'История платежей',
                    path: '/payments',
                    icon: <AccountBalanceWalletIcon/>,
                    onClick: onClick
                },
                {
                    text: 'Обратная связь',
                    path: '/feedback',
                    icon: <FeedbackIcon/>,
                    onClick: onClick
                },
            ]}/>
            <Divider />
            <DrawerMenuItemList header={'Аккаунт'} items={[
                {
                    text: 'Сменить пользователя',
                    path: '#',
                    icon: <ExitToAppOutlinedIcon/>,
                    onClick: () => {
                        const authType = localStorage.getItem('AuthType');
                        localStorage.setItem('AuthType', '')

                        if (authType === 'JWT') {
                            dispatch(cleanUserInfo());
                            history.push('/login');
                        } else {
                            firebase.auth().signOut().then(_ => {
                                history.push('/login');
                            })
                        }

                        onClick && onClick()
                    }
                },
            ]}/>
        </>
    ) : null
}