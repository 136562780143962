import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import {useSendReportF49ExMutation} from "../../core/api";

import {Company} from "../../redux/service/serviceTypes";
import ReportCompanySelect from "./ReportCompanySelect";
import ReportOperationMonthSelect from "./ReportOperationMonthSelect";
import {isBlank} from "../../core/utils";
import {UserEmailField} from "../profile/UserEmailField";

interface IProps {
    idGid: number;
    idSchema: number;
    companies: Company[];
    onSuccess: () => void;
}

export const ReportF49ExView: FC<IProps> = ({idGid, idSchema, companies, onSuccess}) => {

    const [sendReportMutation, {error: sendReportError}] = useSendReportF49ExMutation();

    const [success, setSuccess] = useState(false);
    const [companyId, setCompanyId] = useState<number>(-1);
    const [monthId, setMonthId] = useState<number>(-1);
    const [email, setEmail] = useState<string>('');

    const sendReport = async () => {
        const result = await sendReportMutation({
            idGid: idGid,
            idSchema: idSchema,
            idCompany: companyId,
            idMonth: monthId,
            email: email
        });

        // @ts-ignore
        if (!result.error) {
            setSuccess(true);
            setTimeout(onSuccess, 3000);
        }
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Заказать квитанцию
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <ReportCompanySelect
                    selectCompanyId={companyId}
                    onSelect={setCompanyId}
                    companies={companies} />
                <ReportOperationMonthSelect
                    idSchema={idSchema}
                    idCompany={companyId}
                    selectMonthId={monthId}
                    onSelect={setMonthId} />

                <UserEmailField onChange={setEmail} />

                {sendReportError ? (
                    <Alert severity="error">
                        {sendReportError.message ? sendReportError.message : 'Ошибка заказа квитанции'}
                    </Alert>
                ) : null}

                {
                    success ? (
                        <Alert severity="success">
                            Квитанция успешно заказана
                        </Alert>
                    ) : (
                        <Button variant="contained"
                                color="success"
                                onClick={sendReport}
                                disabled={isBlank(email) || companyId === -1 || monthId === -1}
                        >
                            Заказать
                        </Button>
                    )
                }
            </Stack>
        </Box>
    )
}