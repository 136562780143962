import {FC} from 'react';
import * as React from "react";
import {Link as RouterLink} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {ListSubheader} from "@mui/material";

export type DrawerMenuItem = {
    text: string;
    path: string;
    icon: React.ReactNode;
    onClick?: () => void;
}

export interface IProps {
    header?: string | boolean;
    items: DrawerMenuItem[];
}

export const DrawerMenuItemList: FC<IProps> = ({header, items}) => (
    <List subheader={header && <ListSubheader>{header}</ListSubheader>}>
        {items.map((icon, index) => (
            <ListItem key={index} component={RouterLink as any} to={icon.path} disablePadding>
                <ListItemButton onClick={icon.onClick}>
                    <ListItemIcon>
                        {icon.icon}
                    </ListItemIcon>
                    <ListItemText primary={icon.text} />
                </ListItemButton>
            </ListItem>
        ))}
    </List>
)