import * as React from "react";
import {DrawerMenuItemList} from "./DrawerMenuItemList";
import Divider from "@mui/material/Divider";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DescriptionIcon from "@mui/icons-material/Description";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {FC} from "react";

export interface IProps {
    onClick?: () => void;
}

export const DrawerMenuInfoSection: FC<IProps> = ({onClick}) => (
    <>
        <Divider />
        <DrawerMenuItemList header={'Информация'} items={[
            {
                text: 'Контакты',
                path: '/info/contact',
                icon: <ContactMailIcon/>,
                onClick: onClick
            },
            {
                text: 'Способы оплаты',
                path: '/info/pay_method',
                icon: <ShoppingCartIcon/>,
                onClick: onClick
            },
            {
                text: 'Условия использования',
                path: '/info/terms',
                icon: <DescriptionIcon/>,
                onClick: onClick
            },
            {
                text: 'Политика конфиденциальности',
                path: '/info/privacy',
                icon: <DescriptionIcon/>,
                onClick: onClick
            },
            {
                text: 'Мобильное приложение',
                path: '/info/mobile',
                icon: <PhoneAndroidIcon/>,
                onClick: onClick
            },
        ]}/>
    </>
)