import React, {FC} from "react";
import {Button, Card, CardActions, Divider, Stack, Typography} from "@mui/material";
import {formatPaidCommission, formatPositiveRUB, formatRUB, getCommission} from "../../../core/utils";
import {Service} from "../../../redux/service/serviceTypes";
import {ServiceSwitch} from "./ServiceSwitch";

export interface IProps {
    service: Service;
    onEditService: (service: Service) => void;
}

export const ServiceView: FC<IProps> = ({service, onEditService}) => {
    return (
        <Card style={{marginBottom: 5, borderRadius: 0}}>
            <Stack direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   bgcolor="#f5f5f5"
                   style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 24, paddingRight: 24}}
            >
                <Typography variant="body2" fontWeight={700}>
                    {service.name}
                </Typography>
            </Stack>

            <Divider/>

            <Stack direction="column"
                   justifyContent="space-between"
            >

                <Stack direction="column" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 24, paddingRight: 24}}>

                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" color="text.secondary">
                            Долг<span style={{color: 'red'}}>*</span>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatRUB(service.total)}
                        </Typography>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" color="text.secondary">
                            Начислено<span style={{color: 'red'}}>**</span>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatRUB(service.fullTotal - service.total)}
                        </Typography>
                    </Stack>

                    {/*<Stack direction="row" justifyContent="space-between">*/}
                    {/*    <Typography variant="body2" color="text.secondary">*/}
                    {/*        Сумма*/}
                    {/*    </Typography>*/}
                    {/*    <Typography variant="body2" color="text.secondary">*/}
                    {/*        {formatRUB(service.fullTotal)}*/}
                    {/*    </Typography>*/}
                    {/*</Stack>*/}

                    <Divider style={{marginTop: 10, marginBottom: 10}} />

                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" color="text.secondary">
                            К оплате<span style={{color: 'red'}}>***</span>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatPositiveRUB(service.select ? (service.paid + getCommission(service.paid, service.commissionPercent)) : 0)}
                        </Typography>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" color="text.secondary">
                            в т.ч. комиссия
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatPaidCommission(service.select ? service.paid : 0, service.commissionPercent)}
                        </Typography>
                    </Stack>

                </Stack>
            </Stack>

            <Divider/>

            <Stack direction="row" justifyContent="space-between" alignItems="center" style={{paddingLeft: 10, paddingRight: 10}}>
                <CardActions>
                    <Button size="small" onClick={onEditService.bind(null, service)}>Изменить</Button>
                </CardActions>

                <ServiceSwitch service={service} />
            </Stack>
        </Card>
    )
}