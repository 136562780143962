import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import {useConnectGidMutation} from "../../../core/api";
import {CustomTextField} from "../../../components/CustomTextField";

interface IProps {
    onAppend: () => void;
    onRequestPin: () => void;
}

export const ConnectGidView: FC<IProps> = ({onAppend, onRequestPin}) => {
    const [connectGidMutation, {error: connectError}] = useConnectGidMutation();

    const [gid, setGid] = useState<string>('');
    const [pin, setPin] = useState<string>('');

    const connect = async () => {
        const result = await connectGidMutation({
            gid, pin
        });

        // @ts-ignore
        if (!result.error) {
            onAppend();
        }
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Подключение ГИД
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <CustomTextField label="ГИД" value={gid} onChange={setGid}/>
                <CustomTextField label="ПИН" value={pin} onChange={setPin}/>

                {connectError ? (
                    <Alert severity="error">
                        {connectError.message ? connectError.message : 'Ошибка подключения'}
                    </Alert>
                ) : null}

                <Button variant="contained"
                        color="success"
                        onClick={connect}
                        disabled={gid.length === 0 || pin.length === 0}
                >
                    Подключить
                </Button>
            </Stack>

            <Stack spacing={2}
                   marginTop={6}
                   justifyContent="center"
                   alignItems="center">
                <Typography variant="body2" color="text.secondary">
                    Нет учетной записи?
                </Typography>

                <Button variant="outlined"
                        color="primary"
                        onClick={onRequestPin}
                >
                    Запросить ПИН
                </Button>
            </Stack>
        </Box>
    )
}