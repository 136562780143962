import * as React from 'react';
import {FC} from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import {BaseAppBar} from "./BaseAppBar";
import {AppFooter} from "./AppFooter";
import {AppContainer} from "./AppContainer";
import {RouterBreadcrumbs} from "./RouterBreadcrumbs";
import {useGetUserInfoQuery} from "../../core/api";
import {LoadingPanel} from "../LoadingPanel";

const drawerWidth = 260;

interface IProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    drawer: React.ReactNode;
    children: React.ReactNode;
    mobileOpen: boolean;
    handleDrawerToggle: () => void;
    handleCloseDrawer: () => void;
}

export const BaseLayout: FC<IProps> = ({ window, drawer, children, handleDrawerToggle, handleCloseDrawer, mobileOpen }) => {
    const container = window !== undefined ? () => window().document.body : undefined;

    const { isLoading } = useGetUserInfoQuery();

    if (isLoading) {
        return <LoadingPanel/>
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <BaseAppBar sx={{ display: { xs: 'block', sm: 'none' } }} handleDrawerToggle={handleDrawerToggle} />

            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="menu"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleCloseDrawer}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    minHeight: '100vh',
                    flexGrow: 1, p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` }
                }}
            >
                <Toolbar />

                <AppContainer>
                    <RouterBreadcrumbs />

                    {children}
                </AppContainer>

                <AppFooter/>
            </Box>
        </Box>
    );
}
