import {FC} from "react";

interface IProps {
    idCard: number;
}

export const AdminUserDeviceInfo: FC<IProps> = ({idCard}) => {

    return (
        <>
            AdminUserDeviceInfo: {idCard}
        </>
    )
}