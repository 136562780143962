import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
    Alert,
    Badge,
    FormControl,
    FormControlLabel,
    IconButton,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {Chat} from '@mui/icons-material';
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    formControl: {

    }
});

class MessageList extends React.Component {
    state = {
        auto: false,
        status: -1,
        page: 0,
        rowsPerPage: 10
    };

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        const {rowsPerPage, page, auto, status} = this.state;
        this.props.onLoad(rowsPerPage, page * rowsPerPage, auto, status);
    };

    handleChangePage = (event, page) => {
        this.setState({page: page}, this.refresh);
    };

    handleChangeAuto = (event) => {
        this.setState({page: 0, auto: event.target.checked}, this.refresh);
    };

    handleChangeRowsPerPage = event => {
        let rowsPerPage = parseInt(event.target.value, 10);
        this.setState({page: 0, rowsPerPage: rowsPerPage}, this.refresh);
    };

    handleChangeStatus = event => {
        let status = parseInt(event.target.value);
        this.setState({status: status}, this.refresh);
    };

    status = (status) => {
        switch (parseInt(status)) {
            case 0: return 'Новое';
            case 1: return 'В работе';
            case 2: return 'Обработано';
            default:
                return status;
        }
    };

    render() {
        return (
            <TableContainer component={Paper}>
                <Table className={useStyles.table} size="small" aria-label="Список пользователей">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <FormControlLabel
                                    control={<Switch checked={this.state.auto} onChange={this.handleChangeAuto} />}
                                    label="Запросы ПИН"
                                />
                            </TableCell>
                            <TableCell colSpan={2} align={"right"}>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }} size="small" style={{textAlign: "left"}}>
                                    <Select
                                        labelId="status-select-outlined"
                                        id="demo-select-small"
                                        value={this.state.status}
                                        label="Статус"
                                        onChange={this.handleChangeStatus}
                                    >
                                        <MenuItem value={-1}>Статус: все</MenuItem>
                                        <MenuItem value={0}>Статус: новое</MenuItem>
                                        <MenuItem value={1}>Статус: в работе</MenuItem>
                                        <MenuItem value={2}>Статус: обработано</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell>Сообщение</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.isFetch ? (
                            <TableRow>
                                <TableCell colSpan={4} align={'center'}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <Fragment>
                                {this.props.error && (
                                    <TableCell colSpan={4} align={'center'}>
                                        <Alert severity="error">{this.props.error}</Alert>
                                    </TableCell>
                                )}

                                {this.props.data.map(row => (
                                    <TableRow hover key={row.id}>
                                        <TableCell>{row.dateAdd}</TableCell>
                                        <TableCell>{row.message}</TableCell>
                                        <TableCell>{this.status(row.status)}</TableCell>
                                        <TableCell>
                                            <Link to={`/admin/messages/${row.idLkUser}`}>
                                                <IconButton aria-label="Показать список сообщений"
                                                            color="inherit">
                                                    <Badge badgeContent={row.count} color="secondary">
                                                        <Chat />
                                                    </Badge>
                                                </IconButton>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 30, 50, { label: 'Все', value: this.props.total }]}
                                colSpan={4}
                                count={this.props.total}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Записей на странице' },
                                    native: true,
                                }}
                                labelRowsPerPage={'Записей на странице'}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }
}

MessageList.propTypes = {
    isFetch: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    onLoad: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired
};

export default MessageList;