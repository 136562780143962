import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../core/store';
import {UserInfoResponse, UserState} from "./userTypes";
import {api} from "../../core/api";

const initialUserInfo: UserInfoResponse = {
    id: -1,
    role: 0,
    email: 'email@example.ru'
}

const initialState: UserState = {
    userChecked: false,
    userInfo: initialUserInfo
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserChecked: (state, action: PayloadAction<boolean>) => {
            state.userChecked = action.payload;
        },
        cleanUserInfo: (state) => {
            state.userInfo = initialUserInfo;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            api.endpoints.getUserInfo.matchFulfilled,
            (state, { payload }) => {
                state.userInfo = payload
            }
        )
    }
})

export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectIsSignedIn = (state: RootState) => state.user.userInfo.id > -1;
export const selectIsAdmin = (state: RootState) => state.user.userInfo.role === 1;
export const selectUserChecked = (state: RootState) => state.user.userChecked;

export const {
    setUserChecked,
    cleanUserInfo
} = userSlice.actions;

export default userSlice.reducer;