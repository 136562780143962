import React, {FC} from "react";
import {useGetAdminSchemaListQuery} from "../../../core/api";

interface IProps {
    value: number;
}

const PaymentSchemaDescription: FC<IProps> = ({value}) => {
    const {data} = useGetAdminSchemaListQuery();

    const schema = data?.find(schema => schema.id === value);

    return (
        <>
            {schema ? schema.name : value}
        </>
    )
}

export default PaymentSchemaDescription;