import React, {FC, useEffect} from "react";
import {Fab} from "@mui/material";
import {KeyboardArrowUp} from "@mui/icons-material";
import {ScrollTop} from "./ScrollTop";
import {useLocation} from "react-router-dom";

interface IProps {
    children: any;
}

export const AppContainer: FC<IProps> = ({children}) => {
    const scrollSelector = 'back-to-top-anchor'

    const { pathname } = useLocation();

    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return (
        <>
            <div id={scrollSelector} />

            {children}

            <ScrollTop selector={scrollSelector}>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUp />
                </Fab>
            </ScrollTop>
        </>
    )
}