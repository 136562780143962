import React, {useState} from "react";
import PaymentList from "./PaymentList";
import {useGetAdminPaymentListQuery} from "../../../core/api";

export const AdminPaymentsPage = () => {
    const [limit, setLimit] = useState(15)
    const [offset, setOffset] = useState(0)
    const [status, setStatus] = useState(2)
    const [source, setSource] = useState(0)
    const [schema, setSchema] = useState(0)
    const [dateBegin, setDateBegin] = useState<string | null>(null)
    const [dateEnd, setDateEnd] = useState<string | null>(null)

    const { data, error, isLoading } = useGetAdminPaymentListQuery({limit, offset, source, schema, status, dateBegin, dateEnd})

    return (
        <PaymentList isFetch={isLoading}
                     data={data?.data || []}
                     total={data?.total || 0}
                     error={error}
                     onLoad={(
                         limit: number, offset: number, source: number, schema: number, status: number,
                         dateBegin: string | null, dateEnd: string | null
                     ) => {
                         setLimit(limit);
                         setOffset(offset);
                         setSource(source);
                         setSchema(schema);
                         setStatus(status);
                         setDateBegin(dateBegin);
                         setDateEnd(dateEnd);
                     }}/>
    )
}