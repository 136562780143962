import React, {useEffect} from 'react';
import * as VKID from '@vkid/sdk';
import {OneTapSkin} from '@vkid/sdk';
import {randomId} from "../../core/utils";
import styles from "./VkIdSignIn.module.scss";

// https://id.vk.com/about/business/go/docs/ru/vkid/latest/vk-id/connection/web/onetap
VKID.Config.set({
    app: 51903301, // Идентификатор приложения.
    redirectUrl: `${window.location.protocol}//${window.location.host}/auth/vk/callback`, // Адрес для перехода после авторизации.
    state: randomId() // Произвольная строка состояния приложения.
});

export const VkIdSignIn = () => {

    useEffect(() => {
        // Создание экземпляра кнопки.
        const oneTap = new VKID.OneTap();

        // Получение контейнера из разметки.
        const container = document.getElementById('VkIdSdkOneTap');

        // Проверка наличия кнопки в разметке.
        if (container) {
            // Отрисовка кнопки в контейнере с именем приложения APP_NAME, светлой темой и на русском языке.
            oneTap.render({
                container: container,
                scheme: VKID.Scheme.LIGHT,
                lang: VKID.Languages.RUS,
                styles: {
                    borderRadius: 3,
                    width: 220,
                    height: 48
                },
                skin: OneTapSkin.Primary
            });
        }
    }, [])

    return<div id="VkIdSdkOneTap" className={styles.vkIdButton}></div>
}