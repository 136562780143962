import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import {useSendReportB3Mutation} from "../../core/api";
import {Company} from "../../redux/service/serviceTypes";
import ReportCompanySelect from "./ReportCompanySelect";
import {isBlank} from "../../core/utils";
import CustomDateField from "../../components/CustomDateField";
import moment from "moment";
import {UserEmailField} from "../profile/UserEmailField";

interface IProps {
    idGid: number;
    idSchema: number;
    companies: Company[];
    onSuccess: () => void;
}

export const ReportB3View: FC<IProps> = ({idGid, idSchema, companies, onSuccess}) => {

    const [sendReportMutation, {error: sendReportError}] = useSendReportB3Mutation();

    const [success, setSuccess] = useState(false);
    const [companyId, setCompanyId] = useState<number>(-1);
    const [dateBegin, setDateBegin] = useState<string | null>(moment().format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState<string | null>(moment().format('YYYY-MM-DD'))
    const [email, setEmail] = useState<string>('');

    const sendReport = async () => {
        const result = await sendReportMutation({
            idGid: idGid,
            idSchema: idSchema,
            idCompany: companyId,
            dateBegin: dateBegin || '',
            dateEnd: dateEnd || '',
            email: email
        });

        // @ts-ignore
        if (!result.error) {
            setSuccess(true);
            setTimeout(onSuccess, 3000);
        }
    }

    const validDate = (date: string | null) => {
        const date_regex = /\d{4}-\d{2}-\d{2}/g;
        return date !== null && date.match(date_regex);
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Заказать справку о начислениях
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <ReportCompanySelect
                    selectCompanyId={companyId}
                    onSelect={setCompanyId}
                    companies={companies} />

                <Stack direction="row" spacing={1}>
                    <CustomDateField defaultValue={dateBegin} label={"Начало периода"} onChange={setDateBegin} />
                    <CustomDateField defaultValue={dateEnd} label={"Окончание периода"} onChange={setDateEnd} />
                </Stack>

                <UserEmailField onChange={setEmail} />

                {sendReportError ? (
                    <Alert severity="error">
                        {sendReportError.message ? sendReportError.message : 'Ошибка заказа справки'}
                    </Alert>
                ) : null}

                {
                    success ? (
                        <Alert severity="success">
                            Справка успешно заказана
                        </Alert>
                    ) : (
                        <Button variant="contained"
                                color="success"
                                onClick={sendReport}
                                disabled={isBlank(email) || companyId === -1 || !validDate(dateBegin) || !validDate(dateEnd)}
                        >
                            Заказать
                        </Button>
                    )
                }
            </Stack>
        </Box>
    )
}