import React from "react";

import {Alert, Button, Container} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        // @ts-ignore
        padding: theme.spacing(3)
    }
}));

export const PaymentSuccessPage = () => {
    const classes = useStyles();

    return (
        <Container className={classes.content} maxWidth="md">
            <Alert severity="success" style={{marginBottom: "10px"}}>
                Платеж успешно проведен
            </Alert>
            <a href={`/payment/sberbank/finish`}>
                <Button variant="contained" color="primary" size="large">
                    Закрыть
                </Button>
            </a>
        </Container>
    )
}