import React, {FC} from "react";
import {TextField} from "@mui/material";

interface IProps {
    id?: string;
    label: string;
    type?: string;
    value: string;
    error?: string;
    helper?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
}

export const CustomTextField: FC<IProps> = ({id, label, type, value, error, helper, placeholder, disabled, onChange}) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.target.value);
    }

    return (
        <TextField
            id={id}
            label={label}
            type={type}
            InputLabelProps={{
                shrink: true,
            }}
            value={value}
            onChange={handleChange}
            error={!!error}
            helperText={error || helper}
            placeholder={placeholder}
            disabled={disabled}
        />
    )
}