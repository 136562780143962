import * as React from "react";
import {FC} from "react";
import Toolbar from "@mui/material/Toolbar";
import {BaseAppBar} from "../BaseAppBar";
import {DrawerMenuHeaderSection} from "./DrawerMenuHeaderSection";
import {DrawerMenuAdminSection} from "./DrawerMenuAdminSection";
import {DrawerMenuUserSection} from "./DrawerMenuUserSection";
import {DrawerMenuInfoSection} from "./DrawerMenuInfoSection";
import {Box} from "@mui/material";

export interface IProps {
        handleCloseDrawer: () => void;
}

export const UserDrawer: FC<IProps> = ({handleCloseDrawer}) => (
    <Box>
        <BaseAppBar sx={{ display: { xs: 'none', sm: 'block' } }} />
        <Toolbar sx={{ display: { xs: 'none', sm: 'block' } }}/>

        <DrawerMenuHeaderSection onClick={handleCloseDrawer} />
        <DrawerMenuAdminSection onClick={handleCloseDrawer} />
        <DrawerMenuUserSection onClick={handleCloseDrawer} />
        <DrawerMenuInfoSection onClick={handleCloseDrawer} />
    </Box>
)