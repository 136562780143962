import {Button, CardActions, Stack} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, {FC} from "react";
import {Counter} from "../../redux/counter/counterTypes";

interface IProps {
    counter: Counter;
    onShowHistory: () => void;
    onAppendValue: () => void;
    onRemoveValue: () => void;
}

export const CounterButtonGroup: FC<IProps> = ({counter, onShowHistory, onAppendValue, onRemoveValue}) => {
    return (
        <Stack direction="row"
               sx={{
                   justifyContent: {
                       xs: 'space-between',
                       sm: 'space-between',
                       md: 'start'
                   }
               }}
        >
            <CardActions>
                <Button size="small" startIcon={<HistoryIcon />} onClick={onShowHistory}>История</Button>
            </CardActions>

            {counter.curValue != null ? (
                <CardActions>
                    <Button size="small" startIcon={<DeleteIcon />} onClick={onRemoveValue}>Удалить показания</Button>
                </CardActions>
            ) : (
                <CardActions>
                    <Button size="small" startIcon={<AddCircleOutlineIcon />} onClick={onAppendValue}>Добавить показания</Button>
                </CardActions>
            )}
        </Stack>
    )
}