import React, {FC, useState} from "react";
import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import {formatPositiveRUB, isBlank} from "../../core/utils";
import {UserEmailField} from "../profile/UserEmailField";

interface IProps {
    amount: number;
    onRegister: (email: string) => void;
}

export const RegisterPaymentView: FC<IProps> = ({amount, onRegister}) => {

    const [emailLoaded, setEmailLoaded] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');

    const onChangeEmailHandler = (value: string) => {
        if (!emailLoaded) {
            setEmailLoaded(true)
        }
        setEmail(value)
    }

    const onClickPayment = () => {
        onRegister(email)
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Оплата услуг ЖКХ
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 10}} />

            <Typography variant="body2">
                <span style={{fontWeight: 700}}>Сумма для оплаты: </span> {formatPositiveRUB(amount)}
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 30}} />

            <Stack spacing={3}>
                <UserEmailField
                    label='E-Mail для получения электронного чека'
                    onChange={onChangeEmailHandler} />

                <Button variant="contained"
                        color="success"
                        style={{fontWeight: 700}}
                        onClick={onClickPayment}
                        disabled={isBlank(email)} >
                    Оплатить
                </Button>

                {emailLoaded && isBlank(email) && (
                    <Alert severity="warning" style={{marginBottom: "10px", fontWeight: 700}} >
                        Необходимо указать E-Mail для получения электронного чека
                    </Alert>
                )}
            </Stack>
        </Box>
    )

}