import {Card, CardContent, Typography} from "@mui/material";
import {FC} from "react";
import {LkPayment} from "../../../../redux/payment/paymentTypes";

interface IProps {
    data: LkPayment;
}

const PaymentInfoHeader: FC<IProps> = ({data}) => {
    return (
        <Card>
            <CardContent>
                <div>
                    <Typography>
                        <strong>Пользователь:</strong> {data.idLkUser}
                    </Typography>
                    <Typography>
                        <strong>Номер в БД:</strong> {data.id}
                    </Typography>
                    <Typography>
                        <strong>Номер в ПС:</strong> {data.orderId}
                    </Typography>
                    <Typography>
                        <strong>Дата регистрации:</strong> {data.dateAdd}
                    </Typography>
                    <Typography>
                        <strong>Дата оплаты:</strong> {data.datePayment}
                    </Typography>
                    <Typography>
                        <strong>Сумма:</strong> {data.amount}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}

export default PaymentInfoHeader