import {Alert, Box, Button, Divider, Stack, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import {UserCard} from "../../redux/service/serviceTypes";
import {useRenameCardMutation} from "../../core/api";
import {CustomTextField} from "../../components/CustomTextField";

interface IProps {
    card: UserCard;
    onRename: () => void;
}

export const CardRenameView: FC<IProps> = ({card, onRename}) => {
    const [success, setSuccess] = useState(false);
    const [value, setValue] = useState<string>('');
    const [errorText, setErrorText] = useState<string>('');

    const [renameCardMutation, {error: saveError}] = useRenameCardMutation();

    const handleChange = (newValue: string) => {
        setValue(newValue);

        if (newValue) {
            setErrorText('');
        } else {
            setErrorText('Не указано значение');
        }
    };

    const save = async () => {
        const result = await renameCardMutation({
            idCard: card.id,
            name: value
        });

        // @ts-ignore
        if (!result.error) {
            setSuccess(true);
            setTimeout(onRename, 3000);
        }
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Переименовать группу
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>
                <CustomTextField label={'Текущее название'} value={card.name} />
                <CustomTextField label={'Новое название'} value={value} onChange={handleChange} error={errorText} />

                {saveError ? (
                    <Alert severity="error">
                        {saveError.message ? saveError.message : 'Ошибка сохранения данных'}
                    </Alert>
                ) : null}

                {success ? (
                    <Alert severity="success">
                        Группа успешно переименована
                    </Alert>
                ) : (
                    <Button variant="contained"
                            color='success'
                            onClick={save}
                            disabled={value.length === 0 || errorText.length > 0} >

                        Переименовать
                    </Button>
                )}
            </Stack>
        </Box>
    )
}