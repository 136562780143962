import {DatePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import moment, {Moment} from "moment";
import {FC, useState} from "react";

interface IProps {
    defaultValue: string | null;
    label: string;
    onChange: (value: string | null) => void;
}

const CustomDateField: FC<IProps> = ({defaultValue, label, onChange}) => {
    const [value, setValue] = useState<Moment | null>(
        defaultValue ? moment(defaultValue) : null
    );

    const handleChange = (newValue: Moment | null) => {
        setValue(newValue);
        onChange(newValue ? newValue.format('YYYY-MM-DD') : null);
    };

    return (
        <DatePicker
            label={label}
            value={value}
            onChange={handleChange}
            disableFuture
            renderInput={(params) => <TextField {...params} />}
        />
    )
}

export default CustomDateField;