import React, {FC, useEffect, useState} from "react";
import {CustomTextField} from "../../components/CustomTextField";
import {useGetReportEmailQuery} from "../../core/api";

interface IProps {
    label?: string;
    idSchema: number;
    idCompany: number;
    idAccount: number;
    onChangeEmail: (email: string) => void
    onChangeActivated: (activated: number) => void
    onChangeSending: (sending: boolean) => void
    onChangeCloseReceiptPrinting: (closeReceiptPrinting: boolean) => void
    onChangeLoadSetting: (loadSetting: boolean) => void
}

export const ReportEmailGroup: FC<IProps> = ({
    label, idSchema, idCompany, idAccount, onChangeEmail, onChangeActivated, onChangeSending, onChangeLoadSetting, onChangeCloseReceiptPrinting
}) => {

    const [email, setEmail] = useState<string>('');

    const { data, isLoading, isSuccess } = useGetReportEmailQuery({
        idSchema: idSchema,
        idCompany: idCompany,
        idAccount: idAccount,
    }, {
        skip: (idCompany == -1 || idAccount == -1)
    })

    useEffect(() => {
        onChangeLoadSetting(isLoading);

        if (isSuccess) {
            if (data) {
                onChangeHandler(data.email);
                onChangeActivated(data.activated);
                onChangeSending(data.sending);
                onChangeCloseReceiptPrinting(data.closeReceiptPrinting);
            }
        }
    }, [isSuccess, isLoading])

    const onChangeHandler = (value: string) => {
        setEmail(value);
        onChangeEmail(value);
    }


    return (
        <CustomTextField
            label={label || 'E-Mail'}
            value={email}
            disabled={isLoading}
            onChange={onChangeHandler} />
    )
}