import React, {useState} from "react";
import ReportCompanySelect from "./docs/ReportCompanySelect";
import {Card} from "@mui/material";

export const SandboxPage = () => {
    const [company, setCompany] = useState<number>(-1)

    return (
        <Card style={{padding: 20}}>
            <ReportCompanySelect selectCompanyId={company} onSelect={setCompany} companies={[
                {id: 1, name: 'Компания №1', backgroundBalance: '', accounts: [], type: 'CN'},
                {id: 2, name: 'Компания №2', backgroundBalance: '', accounts: [], type: 'CN'},
                {id: 3, name: 'Компания №3', backgroundBalance: '12', accounts: [], type: 'CN'},
            ]}/>
            <hr/>
            Select Company: {company}
        </Card>
    )
}