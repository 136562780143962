import {Alert, Card, Divider, Stack, Typography} from "@mui/material";
import {CounterButtonGroup} from "./CounterButtonGroup";
import React, {FC} from "react";
import {Counter} from "../../redux/counter/counterTypes";

interface IProps {
    counter: Counter;
    onShowHistory: () => void;
    onAppendValue: () => void;
    onRemoveValue: () => void;
}

export const CounterView: FC<IProps> = ({counter, onShowHistory, onAppendValue, onRemoveValue}) => {

    const consume = () => {
        if (counter.curValue && counter.prevValue) {
            const curValue = parseInt(counter.curValue);
            const prevValue = parseInt(counter.prevValue);

            if (curValue > prevValue) {
                return <span style={{color: 'gray'}}>{`(+${curValue - prevValue})`}</span>
            }
        }

        return null;
    }

    return (
        <Card style={{marginBottom: 5, borderRadius: 0}}>
            <Stack direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   bgcolor="#f5f5f5"
                   style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20}}
            >
                <Typography variant="body2" fontWeight={700}>
                    {counter.service}
                </Typography>
            </Stack>

            <Divider/>

            <div style={{padding: 20}}>
                <Typography variant="body2">
                    <span style={{fontWeight: 700}}>Псевдоним: </span>{counter.name}
                </Typography>
                <Typography variant="body2">
                    <span style={{fontWeight: 700}}>№ прибора учёта: </span>{counter.code}
                </Typography>

                {counter.type && (
                    <Typography variant="body2">
                        <span style={{fontWeight: 700}}>Тип: </span>{counter.type}
                    </Typography>
                )}

                <Typography variant="body2">
                    <span style={{fontWeight: 700}}>Дата последних показаний: </span>{counter.prevDate}
                </Typography>
                <Typography variant="body2">
                    <span style={{fontWeight: 700}}>Последнее показание: </span>{parseInt(counter.prevValue)}
                </Typography>

                {counter.transit > 0 && counter.curValue != null && (
                    <>
                        <Typography variant="body2">
                            <span style={{fontWeight: 700}}>Новое показание: </span> {consume()} {parseInt(counter.curValue)}
                        </Typography>
                        <Alert severity="warning" style={{marginTop: 10, marginBottom: 10}}>
                            Показания будут переданы после оплаты!
                        </Alert>
                    </>
                )}

                {!counter.activePeriod && (
                    <Typography variant="body2">
                        <span style={{fontWeight: 700}}>Период ввода показаний: </span>
                        {`c ${counter.dayBeginPeriod} по ${counter.dayEndPeriod}`}
                    </Typography>
                )}
            </div>

            <Divider/>

            <CounterButtonGroup counter={counter}
                                onShowHistory={onShowHistory}
                                onAppendValue={onAppendValue}
                                onRemoveValue={onRemoveValue} />
        </Card>
    )
}