import {Alert, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Stack, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import {isValidPhone, notBlank, notBlankAny} from "../../../core/utils";
import {useRequestPinMutation} from "../../../core/api";
import {CustomTextField} from "../../../components/CustomTextField";

interface IProps {
    onRequest: () => void;
}

export const RequestPinView: FC<IProps> = ({onRequest}) => {
    const [success, setSuccess] = useState(false);

    const [fio, setFio] = useState<string>('');
    const [fioError, setFioError] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [addressError, setAddressError] = useState<string>('');
    const [gid, setGid] = useState<string>('');
    const [gidError, setGidError] = useState<string>('');
    const [account, setAccount] = useState<string>('');
    const [accountError, setAccountError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [checkedAgree, setCheckedAgree] = React.useState(false);

    const handleChangeFio = (value: string) => {
        setFio(value);

        if (notBlank(value)) {
            setFioError('');
        } else {
            setFioError('Необходимо указать Ф.И.О');
        }
    }
    const handleChangeAddress = (value: string) => {
        setAddress(value);

        if (notBlank(value)) {
            setAddressError('');
        } else {
            setAddressError('Необходимо указать адрес ЛС');
        }
    }
    const handleChangeGid = (value: string) => {
        setGid(value);

        if (notBlank(value)) {
            setGidError('');
        } else {
            setGidError('Необходимо указать ГИД');
        }
    }
    const handleChangeAccount = (value: string) => {
        setAccount(value);

        if (notBlank(value)) {
            setAccountError('');
        } else {
            setAccountError('Необходимо указать Лицевой счет');
        }
    }
    const handleChangeEmail = (value: string) => {
        setEmail(value);

        if (notBlank(value)) {
            setEmailError('');
        } else {
            setEmailError('Необходимо указать E-Mail');
        }
    }
    const handleChangePhone = (value: string) => {
        setPhone(value);

        if (notBlank(value)) {
            if (isValidPhone(value)) {
                setPhoneError('');
            } else {
                setPhoneError('Необходимо указать корректный Телефон');
            }
        } else {
            setPhoneError('Необходимо указать Телефон');
        }
    }

    const handleChangeAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedAgree(event.target.checked);
    };

    const valid = () => {
        return notBlankAny([fio, address, gid, account, email, phone]) && isValidPhone(phone) && checkedAgree;
    }


    const [requestPinMutation, {error: requestError}] = useRequestPinMutation();

    const request = async () => {
        const result = await requestPinMutation({
            fio: fio,
            address: address,
            gid: gid,
            account: account,
            email: email,
            phone: phone,
        });

        // @ts-ignore
        if (!result.error) {
            setSuccess(true);
            setTimeout(onRequest, 3000);
        }
    }

    return (
        <Box>
            <Typography variant="h5" style={{fontWeight: 700}}>
                Запросить ПИН
            </Typography>

            <Divider style={{marginTop: 10, marginBottom: 20}} />

            <Stack spacing={3}>

                <Alert severity='info'>
                    Для регистрации учетной записи по ГИДу необходимо оформить соответствующую заявку.
                    Если данные, указанные в заявке, успешно пройдут автоматическую проверку, то на
                    Ваш электронный ящик сразу будет выслан ПИН-код, иначе заявка будет отработана
                    специалистами технической поддержки в течение 3-х рабочих дней.
                    Выданный ПИН-код является многоразовым, не следует его запрашивать
                    снова для повторного подключения ГИДа.
                </Alert>

                <CustomTextField
                    label='ФИО владельца лицевого счета'
                    value={fio}
                    onChange={handleChangeFio}
                    error={fioError}
                />

                <CustomTextField
                    label='Адрес лицевого счета'
                    value={address}
                    onChange={handleChangeAddress}
                    error={addressError}
                />

                <CustomTextField
                    label='ГИД'
                    value={gid}
                    onChange={handleChangeGid}
                    error={gidError}
                    helper='ГИД указан на квитанции'
                />

                <CustomTextField
                    label='Лицевой счет'
                    value={account}
                    onChange={handleChangeAccount}
                    error={accountError}
                    helper='Лицевой счет указан на квитанции'
                />

                <CustomTextField
                    label='E-Mail'
                    value={email}
                    onChange={handleChangeEmail}
                    error={emailError}
                    helper='Адрес, на который будет отправлен ПИН-код'
                />

                <CustomTextField
                    label="Номер мобильного телефона"
                    value={phone}
                    onChange={handleChangePhone}
                    error={phoneError}
                    helper='Контактный телефон для связи с технической поддержкой'
                />

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox checked={checkedAgree} onChange={handleChangeAgree} />
                        }
                        label="Даю согласие на обработку моих персональных данных" />
                </FormGroup>

                {requestError ? (
                    <Alert severity="error">
                        {requestError.message ? requestError.message : 'Ошибка запроса ПИН'}
                    </Alert>
                ) : null}

                {success ? (
                    <Alert severity="success">
                        Запрос успешно отправлен
                    </Alert>
                ) : (
                    <Button variant="contained"
                            color="success"
                            onClick={request}
                            disabled={!valid()}
                    >
                        Зарегистрироваться
                    </Button>
                )}

            </Stack>
        </Box>
    )
}