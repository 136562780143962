import React from 'react'
import {LoadingPanel} from "../../../LoadingPanel";
import {Alert} from "@mui/material";
import {useGetAdminPaymentInfoQuery} from "../../../../core/api";
import {useParams} from "react-router-dom";
import PaymentInfoHeader from "./PaymentInfoHeader";
import PaymentInfoServiceList from "./PaymentInfoServiceList";

const PaymentInfoPage = () => {
    const params = useParams();
    // @ts-ignore
    const payment = params.id;

    const { data, error, isLoading } = useGetAdminPaymentInfoQuery(payment)

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    if (!data) return null;

    return (
        <>
            <PaymentInfoHeader data={data.lkPayment} />
            <PaymentInfoServiceList data={data.lkPaymentItems} />
        </>
    );
}

export default PaymentInfoPage;