import React from "react";
import {useParams} from "react-router-dom";
import {useGetAdminUserInfoQuery} from "../../../../core/api";
import {LoadingPanel} from "../../../LoadingPanel";
import {Alert, Box, Card, Tab, Tabs, Typography} from "@mui/material";
import {AdminUserCardList} from "./AdminUserCardList";
import {AdminUserDeviceList} from "./AdminUserDeviceList";
import {AdminUserPaymentList} from "./AdminUserPaymentList";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AdminUserInfoPage = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const params = useParams();
    // @ts-ignore
    const userId = params.id;

    const { data, error, isLoading } = useGetAdminUserInfoQuery(userId)

    if (isLoading) return <LoadingPanel/>;
    if (error) {
        // @ts-ignore
        return <Alert severity="error">{error}</Alert>;
    }

    const formatRole = (role: number) => {
        switch (role) {
            case 0: return "Пользователь";
            case 1: return "Администратор";
            default: return role;
        }
    }

    return (
        <>
            <Card style={{padding: "15px"}}>
                {data?.user.name && <Typography fontWeight={700}>{data?.user.name}</Typography>}
                <div>Роль в системе: {formatRole(data?.user.role || 0)}</div>
                {data?.user.email && <div>E-Mail: {data.user.email}</div>}
                {data?.user.phone && <div>Телефон: {data.user.phone}</div>}
                <div>Дата создания: {data?.user.dateAdd}</div>
                {data?.user.androidAppVersion && <div>Версия мобильного приложения: {data.user.androidAppVersion}</div>}
            </Card>

            {data?.oauthList.map(oAuth => (
                <Card key={oAuth.id} style={{padding: "15px", marginTop: 20}}>
                    {oAuth.provider && <div>Провайдер авторизации: {oAuth.provider}</div>}
                    {oAuth.name && <div>Name: {oAuth.name}</div>}
                    {oAuth.email && <div>E-Mail: {oAuth.email}</div>}
                    {oAuth.phone && <div>Телефон: {oAuth.phone}</div>}
                    {oAuth.dateAdd && <div>Дата подключения: {oAuth.dateAdd}</div>}
                </Card>
            ))}

            <Card style={{marginTop: 20}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'  }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Услуги" {...a11yProps(0)} />
                        <Tab label="Приборы учета" {...a11yProps(1)} />
                        <Tab label="История платежей" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                    {data?.user && <AdminUserCardList idUser={data.user.id} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {data?.user && <AdminUserDeviceList idUser={data.user.id} />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {data?.user && <AdminUserPaymentList idUser={data.user.id} />}
                </TabPanel>
            </Card>
        </>
    )
}